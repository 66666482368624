import { DISPLAYSIZECHANGE, LOGINSTATE, LOADING, LANGUAGECODE, PAGEISLOADED } from "../constants/mainConst";

export const displaySizeChange_Store = (params) => {
	return (dispatch) =>
		dispatch({ type: DISPLAYSIZECHANGE, payload: params, });
}

export const loginState_Store = (params) => {
	return (dispatch) =>
		dispatch({ type: LOGINSTATE, payload: params, });
}

export const loading_Store = (params) => {
	return (dispatch) =>
		dispatch({ type: LOADING, payload: params, });
}

export const languageCode_Store = (params) => {
	return (dispatch) =>
		dispatch({ type: LANGUAGECODE, payload: params, });
}

export const pageIsloaded_Store = (params) => {
	return (dispatch) => 
		dispatch({ type: PAGEISLOADED, payload: params });
}