import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import { Grid, Typography, Divider, Chip, IconButton, Menu, MenuItem, Button, CircularProgress, Box } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';

import { getCustomerStripeInformation, deletePaymentMethod, getSubscriptionInfo, getDefaultPaymentMethod, setDefaultPaymentMethod, cancelSubscription } from '../../../../axios/ApiProvider';
import CreditCard from './creditCard';

const Payment = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const [stripeInfo, setStripeInfo] = useState({});
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [defaultMethod, setDefaultMethod] = useState({})
  const [openAddPaymentDialog, setOpenAddPaymentDialog] = useState(false);
  const [loadingInitialData, setLoadingInitialData] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedMethod, setSelectedMethod] = useState(null);

  const handleClick = (event, method) => {
    setAnchorEl(event.currentTarget);
    setSelectedMethod(method)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCustomerInfo = async () => {
    const resp = await getCustomerStripeInformation();
    const subResp = await getSubscriptionInfo();
    const defaultMethodResp = await getDefaultPaymentMethod();
    setStripeInfo(resp.data);
    setSubscriptionInfo(subResp.data)
    setDefaultMethod(defaultMethodResp.data)
    setLoadingInitialData(false)
  }

  useEffect(() => {
    getCustomerInfo()
  }, [])

  const handleOpenDialog = () => {
    setOpenAddPaymentDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenAddPaymentDialog(false)
  }

  const handleDeletePayment = async () => {
    handleClose()
    if(selectedMethod === null) return;
    await deletePaymentMethod(selectedMethod.id);
    const resp = await getCustomerStripeInformation();
    setStripeInfo(resp.data);
  }

  const handleSetDefaultPaymentMethod = async () => {
    handleClose()
    await setDefaultPaymentMethod(selectedMethod.id);
    const defaultMethodResp = await getDefaultPaymentMethod();
    setDefaultMethod(defaultMethodResp.data)
  }

  const handleCancelSubscription = async () => {
    if(!subscriptionInfo.subscription_id) return;
    await cancelSubscription(subscriptionInfo.subscription_id);
    const subResp = await getSubscriptionInfo();
    setSubscriptionInfo(subResp.data)
  }


  return (
    <>
      {loadingInitialData && <Box height="500px" display="flex" flexDirection="column" justifyContent="center"><CircularProgress sx={{margin: "auto"}} size="60px" /></Box>}
      {!loadingInitialData && 
      <Grid container spacing={2} sx={{
        p: 4
      }}>
        <Grid item xs={12}>
          <Typography variant='h6'>{languageStrs.current_plan}</Typography>
          <Divider sx={{
            my: 2
          }} />
          <Typography variant='h5'>{subscriptionInfo?.subscription_name}</Typography>
          <Typography variant='h5'>{subscriptionInfo?.currency?.toUpperCase()} {subscriptionInfo?.price} per {subscriptionInfo?.interval}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button color='error' variant='outlined' onClick={handleCancelSubscription}>{languageStrs.cancel_subscription}</Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>{languageStrs.payment_method}</Typography>
          <Divider sx={{
            my: 2
          }} />
          <Grid container spacing={2}>
            {stripeInfo?.payment_methods?.map((method, index) => {
              return (
              <Grid item container xs={12} key={index} spacing={1}>
                <Typography variant='body1' display="inline-block" minWidth="350px" maxWidth="350px">
                  <span style={{
                      textTransform: "capitalize",
                      padding: "0px 8px"
                  }}>{method.card.brand} **** {method.card.last4}</span>
                  <div style={{
                    textAlign: 'left',
                    minWidth: "150px",
                    display: "inline-block"
                  }}>
                    {method.id === defaultMethod?.default_payment_method?.id && <Chip label="default" size='small' />}
                  </div>
                </Typography>
                <Typography variant='body1' display="inline-block">
                  <span style={{
                      textAlign: 'left',
                      textTransform: "capitalize",
                      padding: "0px 8px"
                  }}>{languageStrs.expires} {method.card.exp_month}/{method.card.exp_year}</span>
                  <IconButton onClick={(e) => {
                    handleClick(e, method)
                  }}>
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id={`basic-menu-${index}`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleSetDefaultPaymentMethod}>{languageStrs.make_defaults}</MenuItem>
                    <MenuItem onClick={handleDeletePayment}>{languageStrs.delete}</MenuItem>
                  </Menu>  
                </Typography>
              </Grid>)
            })}
            <Grid item xs={12}>
              <Button startIcon={<AddIcon />} onClick={handleOpenDialog}>{languageStrs.add_payment_method}</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>{languageStrs.billing_information}</Typography>
          <Divider sx={{
            my: 2
          }} />
          <Typography variant='body1' display="inline-block" minWidth="350px">Email</Typography>
          <Typography variant='body1' display="inline-block">{stripeInfo.customer}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>{languageStrs.invoice_history}</Typography>
          <Divider sx={{
            my: 2
          }} />
          <Grid container spacing={2}>
            {stripeInfo?.invoices?.map((invoice, index) => {
                return (
                <Grid item container xs={12} key={index} spacing={1} direction="row">
                  <Typography variant='body1' display="inline-block" minWidth="350px" maxWidth="350px">
                    <span style={{
                        padding: "0px 8px"
                      }}>
                        {new Date(invoice.created * 1000).toDateString()}
                        <a href={invoice.hosted_invoice_url} target='_blank' rel="noreferrer"><LaunchIcon /></a>
                    </span>
                  </Typography>
                  <Typography variant='body1' display="inline-block" minWidth="350px" maxWidth="350px">
                    <div style={{
                        minWidth: "150px",
                        display: "inline-block",
                        textAlign: "center"
                      }}>
                        {invoice.currency.toUpperCase()}{invoice.amount_due / 100}
                    </div>  
                  </Typography>
                  <Typography variant='body1' display="inline-block">
                    <div style={{
                        minWidth: "150px",
                        display: "inline-block"
                      }}>
                        { !invoice.paid && <Chip label="Open" color='primary' size='small' />}
                    </div>
                    <span style={{
                        padding: "0px 8px"
                    }}>videoo.io</span>  
                  </Typography>
                  
                </Grid>)
            })}
          </Grid>
        </Grid>
      </Grid>}
      <CreditCard 
        openAddPaymentDialog={openAddPaymentDialog} 
        handleCloseDialog={handleCloseDialog} 
        paymentMethodAdded={async () => {
            handleCloseDialog();
            const resp = await getCustomerStripeInformation();
            setStripeInfo(resp.data);
          }
        } 
      />
    </>
  )
}

export default Payment