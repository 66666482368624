export const DISPLAYSIZECHANGE = 'DISPLAYSIZECHANGE';
export const LOGINSTATE = 'LOGINSTATE';
export const LOADING = 'LOADING';
export const LANGUAGECODE = 'LANGUAGECODE';
export const PAGEISLOADED = 'PAGEISLOADED';

export const mainConst = {
	DisplaySizeChange: 0,
	loginState: false,
	loading: false,
	languageCode: '',
	pageIsLoaded: false,
};

