import React, { useState } from 'react'
import { useSelector } from "react-redux";
import { Box, Menu, MenuItem } from "@mui/material"
import ContentCutIcon from '@mui/icons-material/ContentCut'
import MoreVertIcon from "@mui/icons-material/MoreVert"

import { duplicateProject, deleteProject } from '../../../../axios/ApiProvider'
import { CalcuDifferentDay } from '../../dashboard.config';
import emtpyThumb from '../../../../assets/image/emptyThumb.jpg'

const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL

const GridItem = ({ itemData, onDelete }) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDuplicate = async () => {
    const result = await duplicateProject(itemData.uuid)
    if (result.state !== 'success') {
      return window.toastr.error(result.data.message)
    }

    const data = result.data
    const duplicateUUID = data['Duplicated Project uuid']
    window.location.href = `${EDIT_FRONTEND_URL}/${duplicateUUID}/`
  }

  
  const handleClickDeleteProject = async () => {
    const result = await deleteProject(itemData.uuid)
    handleClose()
    onDelete()
    if (result.state !== 'success') {
      return window.toastr.error(result.data.message)
    }
  }

  return (
    <Box className="projects-item">
      <a href={`${EDIT_FRONTEND_URL}/${itemData.uuid}`} className="projectTitle-ThumbnailWrapper">
        <Box className="projectTitle-ThumbnailActions-section">
          <Box className="ThumbnailAction__ThumbnailActionWrapper">
            <ContentCutIcon /> {languageStrs.edit}
          </Box>
        </Box>

        <Box className="projectTitle-ThumbnailBackgroundsContainer">
          {itemData?.project_thumbnail && (
            <img alt='project-thumbnail'
              src={itemData.project_thumbnail}
              className="projectTitle-ThumbnailImage-section"
            />
          )}

          {!itemData?.project_thumbnail && (
            <img src={emtpyThumb}
              alt='project-thumbnail'
              className="projectTitle-ThumbnailImage-section"
            />
          )}

          <Box className="projectTitle-ThumbnailImageBackgroundOverlay" />

          {itemData?.project_thumbnail && (
            <img alt='project-thumbnail'
              src={itemData.project_thumbnail}
              className="projectTitle-ThumbnailImageBackground"
            />
          )}

          {!itemData?.project_thumbnail && (
            <img src={emtpyThumb}
              alt='project-thumbnail'
              className="projectTitle-ThumbnailImageBackground"
            />
          )}
        </Box>
      </a>

      <Box className="projectsTitle-TileInfoWrapper">
        <h1 className="TileInfoWrapper-header">{itemData.title}</h1>
        <div className="TileInfoWrapper-TileInfoTime">{CalcuDifferentDay(itemData.updated_at)}</div>
        <Box className="TileInfoWrapper-TileInfoActions">
          <Box className="TileInfoActions-btn" onClick={handleClickMore}>
            <MoreVertIcon />
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClickDuplicate}>{languageStrs.duplicate_project}</MenuItem>
            <MenuItem onClick={handleClickDeleteProject}>{languageStrs.delete_project}</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  )
}

export default GridItem
