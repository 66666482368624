import { Box, Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from "react-router-dom";

import './aigenerated-summary.scss';
import { createNewProject } from '../uploadHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

import { GetProjectLink } from "../../../axios/ApiProvider";

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

export const how_to_1 = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File.png';
export const how_to_2 = BASE_STATIC_STORAGE_URL + '/images/features/Auto Subtitles.png';
export const how_to_3 = BASE_STATIC_STORAGE_URL + '/images/features/Export Resize Video.png';

const VideooEditor_AISummary_Welcome = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-Welcome.png'
const VideooEditor_AISummary_Step1 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-1.png'
const VideooEditor_AISummary_Step2 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-2.png'
const VideooEditor_AISummary_Step3 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-3.png'
const VideooEditor_AISummary_Step4 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-4.png'
const VideooEditor_AISummary_Step5 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-5.png'
const VideooEditor_AISummary_Step6 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-6.png'
const VideooEditor_AISummary_Step7 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-7.png'
const VideooEditor_AISummary_Step8 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-8.png'
const VideooEditor_AISummary_Step9 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-9.png'
const VideooEditor_AISummary_Step10 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-10.png'
const VideooEditor_AISummary_Step11 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-11.png'
const VideooEditor_AISummary_Step12 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-12.png'
const VideooEditor_AISummary_Step13 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-13.png'
const VideooEditor_AISummary_Step14 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-14.png'
const VideooEditor_AISummary_Step15 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-15.png'
const VideooEditor_AISummary_Step16 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-16.png'
const VideooEditor_AISummary_Step17 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-17.png'
const VideooEditor_AISummary_Step18 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AISummary-18.png'

const VideooEditor_AISummary_Action1 = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File.png';
const VideooEditor_AISummary_Action2 = BASE_STATIC_STORAGE_URL + '/images/features/Auto Subtitles.png';
const VideooEditor_AISummary_Action3 = BASE_STATIC_STORAGE_URL + '/images/features/Export Resize Video.png';

const VideooEditor_AddTransitions_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Transition%20to%20Video.png'
const VideooEditor_AddMusic_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Music%20to%20Video%202.png'
const VideooEditor_AISummary_Text = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Text%20to%20Video.png'
const VideooEditor_AddStock_Image = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Stock.png'


export const AIGeneratedVideoSummary = () => {
    const handleCreateNewProject = async () => {
        let result = await GetProjectLink();
        if (!result || result.state == 'error') window.toastr.error(result.data.message);
        else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
    }

    return (
        <Box className="T_containner">
          <Box className="Header"><Header /></Box>

          <Box className="content-containner">
            <Box className="section-15" sx={{marginTop: '4rem'}}> 
                <Box className="div-1232">
                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={400}>
                        <Grid item xs={12} md={6}>
                            <Card className="p-6">
                                <Box className="div-1232">
                                    <Typography variant="h3" gutterBottom fontWeight={'bold'}>
                                    AI Video Summary Generator
                                    </Typography>
                                </Box>
                                <Box className="div-1232" sx={{marginBottom: '1rem'}}>
                                    <Typography variant="body1" gutterBottom >
                                    Let's say, you have a 60-minutes length video and you need only some particular topics from the content, yet feel so lazy to edit the video.
                                    </Typography>
                                    <Typography variant="body1" gutterBottom >
                                    Videoo.io offers an AI-powered Video Summary Generator that automatically creates a concise and engaging summary of your video content. This feature is perfect for condensing lengthy videos into shorter. With the Video Summary Generator, you can quickly create compelling video summaries that highlight key points and messages from your videos.
                                    </Typography>
                                </Box>
                                <Box className="div-1232" onClick={handleCreateNewProject}>
                                    <Button variant="contained" className="create-now" color="success">Create Now</Button>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} height={'100%'}>
                            <Card className="p-6">
                                {/*<img src={Illustration} className="mx-auto h-full " />*/}
                                <div style={{ width: '100%', paddingTop: '56.25%', position: 'relative' }}>
                                <iframe
                                    src="https://www.youtube.com/embed/E94G6G46Pqo"
                                    title="Videoo.io, Online Cloud Based Video Editor"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                ></iframe>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box className='section-15 pt-12'>
              <Box className='div-1232'>
                <Box className='breadcrumbs'>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Videoo</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>AI-Generated</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>AI Video Summarization</Box>
                  </a>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" sx={{paddingY: '3rem'}}>
              <Box className="div-1232">
                <Box className="mx-auto text-center" sx={{width: '80%'}}>
                  <Typography variant="h3" gutterBottom fontWeight={600} color={'#4D4D4D'}>Create AI Summary Videos from your Content</Typography>
                </Box>
                <Box sx={{textAlign: 'center', marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >Just select a topic and Videoo.io will summarize your video</Typography>
                </Box>
                <Box className="container">
                  <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                    <Grid item md={6} xs={12} >
                      <img width={'100%'} src={VideooEditor_AISummary_Welcome} />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <Box sx={{paddingBottom: '0.5rem'}}>
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Meet Videoo.io's AI Powered Video Summarization Tool</Typography>
                      </Box>
                      <Box sx={{paddingBottom: '1rem'}}>
                        <Typography variant="body1" gutterBottom>
                        With the marvels of Large Language Models (LLMs) and Natural Language Processing (NLP), Videoo.io can generate a summary of your video content. The AI-powered Video Summary Generator analyse your video content by using speech-to-text technology to transcribe the audio content into conversations.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        Then user provides either; a topic, a sentence, a request or a set of questions to generate a summary of the video content. Videoo.io understands this request and generates the most optimized version of the video covering all the requested topics and subjects.
                        </Typography>
                      </Box>
                      <Box className="div-1232" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="create-now" color="success">Create Now</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box className="section-9 directory std-hero-section" >
              <Box className="div-1232" >
                <Box className="text-center">
                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>How to Generate Video to Video Summary, a step-by-step Guide</Typography>
                </Box>
                <Box className="text-center" sx={{marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >Follow these steps to generate an AI generated video summary from your video content</Typography>
                </Box>

                <Box className="section-9 directory std-hero-section" >
                    <Box className="div-1232">
                        <Box className="container">
                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step1} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 1 - Signup and Login to Videoo.io</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        - Go to Videoo.io in your browser. Signup & Login to Videoo.io
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - When you are logged in, use the "Create Now" button to open dashboard. In the popup menu, create a blank project by using the "Create Project" button.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../signup" component={Link} variant="contained" className="py-10 px-33" color="success">Sign up to Videoo.io</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step2} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 2 - Adding Your Video File</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body1" gutterBottom>
                                        - You can add multiple text to your video timeline. Each text can be customized with different font, opacity, size, color, and position.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Once you have added text to your video, you can also adjust the duration of the text overlay to control when it appears and disappears in your video.
                                        </Typography>
                                    </Box>
                                    <Box className="div-1232" onClick={handleCreateNewProject}>
                                        <Button variant="contained" className="create-now" color="success">Create a Blank Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step3} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 3 - Your new Editing Project</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                         - Now you will be able to see the newly created project in your online video editor. Now to add new video to your project, you can go to menus on the left side of the UI.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step4} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 4 - Selecting Media and Upload Video</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                         - To add a video to your project, click on the "Upload" button in the left menu. This will open a file browser where you can select the video file you want to add to your project.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                         - Make sure to select the video file that you want to generate a summary from. After selecting your file, you may need to wait for the video to upload to the platform prior to initiate the AI summary generation.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/templates" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Template Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step5} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 5 - Go to AI Summary menu</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Now, as your file has been uploaded succesfully, you can go to the "AI Summary" menu in the left side of the UI.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - You may see options such as AI Reels or AI Silence Remover and AI Summary Editbox. On top of the editbox it says : "How do you want to summarize your content?"
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - This means that if you have an overall understanding of the video you have just uploaded and know what you exactly want to summarize, you can type your request in the editbox.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step6} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 6 - Select the Language</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Now, you need to select the language of the video content. This is important for the AI to understand the content and generate a summary of the video.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - You can select the language from a choice of 20+ languages, including English, Spanish, French, German, Italian, Portuguese, Dutch, Turkish and more.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - Our Speech to Text Transription extracting tool is powered by <a href="https://assemblyai.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>AssemblyAI</strong></a>.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step7} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 7 - Providing context for the AI Summary</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Either you may leave the "How do you want to summarize your content?" editbox empty, or you can provide a context for the AI to understand the content better.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - You may use instructions such as: "Summarize the content about Pyramids in Ancient Egypt." or "I want to know the key points about the new iPhone 13." depending on the video content.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step8} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 8 - Best strategies for Summary directives</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Providing accurate and clear instructions to the AI is crucial for generating a summary that meets your expectations.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - You may also say : "I want a detailed long summary." or "I want a short summary." to instruct the AI about the length of the summary.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - You can also instruct the AI to generate a summary that focuses on specific topics or keywords. For example, you can say: "I want a summary that focuses on the key points about the some specific topic."
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step9} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 9 - Final checks before generating AI Summary</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Also, you may leave the editbox empty and let the AI to generate a summary based on the content of the video.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - In the example here, a detailed instruction is provided to the AI to generate a summary about the "Ruler and Pyramids in Ancient Egypt".
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step10} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 10 - Triggering AI Summary</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body2" gutterBottom>
                                        - When you are ready to generate the AI Summary, click the "Create AI Summary" button. The AI will analyze the video content and generate a summary based on your instructions.
                                      </Typography>
                                      <Typography variant="body2" gutterBottom>
                                        - First, you will see "Analysing Transcript" message on the UI.
                                      </Typography>
                                      <Typography variant="body2" gutterBottom>
                                        - Following that the transcript will be generrated and you will see "Creating AI Summary" message on the UI.
                                      </Typography>
                                      <Typography variant="body2" gutterBottom>
                                        - Finally, you will see "Opening new AI Project" message on the UI. This means that the AI Summary is ready to be viewed and edited.
                                      </Typography>
                                      <Typography variant="body2" gutterBottom>
                                        - Videoo.io will automatically create a new Video Editing project with the AI Summary generated while keeping the original project untouched.
                                      </Typography>

                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step11} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 11 - Adding Subtitles to AI Summary Project</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Once the AI Summary is generated, you can view the summary in the timeline.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - You may do some changes on the timeline video elements by using the timeline editor, such as trimming, cutting, spliting or duplicating the video elements.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - You may also add Subtitles to the AI Summary project by clicking the "Subtitles" button on the left side of the UI.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step12} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 12 - Adding a second translated subtitle</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Once you have added the subtitles in original language, you may also add a second translated subtitle to the video.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - Make sure you have positioned the secondary subtitles correctly on the video player window by selecting the location in the Subtitles menu.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - You can customize the appearance of the subtitles by changing the font, size, color, and background of the text.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step13} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 13 - Positioning the Subtitles on Video (Optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body2" gutterBottom>
                                        - You can always change the location of subtitles by selecting "Edit Subtitles" and then Style Helper menu. Just pressing "Save" button on Style menu will apply the changes.
                                      </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step14} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 14 - Positioning the Secondary Translated Subtitles (Optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - If you have added a translated secondary subtitles, you may also change the location of the secondary subtitles by selecting "Edit Subtitles" and then Style Helper menu. Just pressing "Save" button on Style menu will apply the changes.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step15} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 15 - Finals checks before Exporting</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Before exporting your video with subtitles, make sure to review the video and subtitles to ensure that everything is correct.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - You can also add addintional text, images, music or transitions to your video to make it more engaging and professional. You can also add Stock videos, images or audio to your video to enhance the quality of your content from our wide choice of Stock Library from <a href="https://pixabay.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Pixabay</strong></a>, <a href="https://www.pexels.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Pexels</strong></a>, <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a>, and <a href="https://stock.adobe.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Adobe Stock</strong></a>.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step16} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 16 - Export Menu Options</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Once you have make sure that you are all happy with the preview of your AI Summary project, you can export the final project in various settings. VIDEOO offers a range of export options to suit your needs, including resolutions up to 4K and various file encoding options including export quality and frame per second (fps).
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - When you are ready, just press the "Export" button on the top right of the UI. This will initiate the export process where UI will redirect you to the export view.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step17} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 17 - Exporting AI Summary Video</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - In the export view, you may wait on this screen or you can close the browser and come back later to watch or download the exported video.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - Videoo.io is a cloud/browser based online editing platform and the export operation is happening on the cloud servers, you can always come back to the export view and download the video to your device.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AISummary_Step18} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 18 - Exported Output Video</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Once the export process is completed, you can download the video to your device or share it on social media platforms.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - If you still want to make minor changes, you can press "Re-edit Video" and go back to the editor to make changes on the video content as many times as you want.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - You can use the buttons on the right side of the UI to share it on social media platforms, or copy the link to share with others.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
              </Box>
            </Box>
            <Box className="section-9  std-hero-section">
              <Box className="div-1232">
                <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} >Easy-to Use AI Video to Video Summary Features for Everyone</Typography>
                <Box className="container py-15" sx={{marginTop: '2rem'}}>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Video to AI Video Summary
                        </Typography>
                      </Box>
                      <Box className="mb-5">
                      </Box>
                        <Box className="py-10">
                          <Typography variant="body1" gutterBottom>
                            Videoo.io offers an AI-powered Video Summary Generator that automatically creates concise summaries of your videos. This feature simplifies the process of condensing lengthy content into shorter, engaging clips that capture the essence of your video.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Good Quality Summarization:</strong> The AI technology identifies key points, themes, and messages within your long (or short) video, providing you with a summarized version without the need for manual editing.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Engaging Content:</strong> Quickly create compelling video summaries that retain viewer interest by highlighting the most important aspects of your content.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Time-Saving:</strong> Save time and effort that would otherwise be spent on labor-intensive video editing tasks.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Customizable:</strong> Tailor the summary to emphasize specific topics or sections within your original video.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Integration:</strong> Seamlessly integrate the summarized video into your projects, presentations, or social media posts.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            With Videoo.io’s AI Video Summary Generator, transforming lengthy videos into concise, impactful summaries has never been easier. This tool ensures your content remains engaging and informative, while significantly reducing the time and effort typically required for video editing.
                          </Typography>
                        </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create Video Summary</Button>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        AI Auto Silence Remover
                        </Typography>
                      </Box>
                      <Box className="mb-5">
                        <Box className="py-10">
                          <Typography variant="body1" gutterBottom>
                            VIDEOO’s AI Auto Silence Remover feature revolutionizes video editing by automatically eliminating silent periods from your videos. Here’s how it streamlines the process:
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong> Quality Editing:</strong> Using advanced AI algorithms, the Auto Silence Remover identifies and removes silent segments, significantly reducing editing time.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Enhanced Engagement:</strong> Maintain viewer interest by ensuring your videos are consistently dynamic and engaging, without prolonged periods of silence.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Professional Quality:</strong> Achieve polished results that enhance the overall quality and flow of your video content.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Silence Trimmed Videos :</strong> Adjust settings to fine-tune the removal of silence according to your preferences, ensuring optimal results.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Time-Saving:</strong> Save valuable time that would otherwise be spent manually cutting out silent sections.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <strong>Seamless Integration:</strong> Integrate the edited video directly into your projects or share it across platforms effortlessly.
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            VIDEOO’s AI Auto Silence Remover makes video editing efficient and accessible, allowing you to deliver high-quality content that captivates your audience from start to finish. Experience the benefits of AI Silence Remover and make your videos more engaging and professional.
                          </Typography>
                        </Box>
                        <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                          <Button variant="contained" className="py-10 px-33" color="success">Remove Silence Automatically</Button>
                        </Box>
                      </Box>
                    </Grid>

                    <Box className="container">
                      <Grid container spacing={5}>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AISummary_Action1}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Your Video</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Upload your video to the platform and select the language of the video content. Go to AI Summary Menu on the left.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AISummary_Action2}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Initiate AI Summary</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Click AI Summary and AI will analyze the video and generate transcript and AI Summary based on your instructions.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AISummary_Action3}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Export, Download & Share</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Once the AI Summary is generated, you can export the video with subtitles and share it on social media platforms.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Box className="py-10">
                        <img width={'100%'} src={VideooEditor_AISummary_Step13} />
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box className="section-9 directory" >
                <Box className="div-1232 px-18">
                    <Box>
                    <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Other Video Tools</Typography>
                    </Box>
                    <Box className="text-center">
                    <Typography className="text-center" variant="body1" gutterBottom color={'#4D4D4D'}>There are plenty of other features that are avaliable with Videoo.io</Typography>
                    </Box>
                </Box>
                <Box >
                    <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddMusic_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Music to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-music-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AISummary_Text} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Text to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-text-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddStock_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Free Stock Content to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-free-stock-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid>
                            <img width={'100%'} src={VideooEditor_AddTransitions_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Transitions to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-transition-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box className="section-9 directory" sx={{ paddingY: '3rem' }}>
              <Box className="div-1232 px-18">
                <Box className="text-center">
                  <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                    Frequently Asked Questions
                  </Typography>
                </Box>
                <Box className="text-center mx-auto" sx={{ marginBottom: '3rem', width: '60%' }}>
                  <Typography variant="body1" gutterBottom>
                    Welcome to our FAQ section on AI Video to Video Summary by Videoo.io! Here, we address common questions about our platform's capabilities in summarizing videos using AI. Whether you're exploring automated video summarization or looking for advanced editing features, you'll find valuable insights here.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Q1: How does AI Video to Video Summary by Videoo.io work?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    AI Video to Video Summary uses advanced algorithms to analyze and condense videos into shorter summaries. It identifies key moments and generates concise clips that capture the essence of the original video.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Q2: Can I customize the length and content of the video summaries?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, you can adjust the length and content of the summaries generated by AI Video to Video Summary. This allows you to focus on specific segments or themes within your original video.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Q3: What video formats does AI Video to Video Summary support?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    AI Video to Video Summary supports a wide range of video formats, ensuring compatibility with various types of footage you may want to summarize.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Q4: How accurate is the summarization process?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    The AI-driven summarization process is highly accurate, utilizing machine learning techniques to identify significant moments and create meaningful summaries.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Q5: Can I integrate AI-generated summaries with other editing features?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, you can seamlessly integrate AI-generated video summaries with other editing features offered by Videoo.io, enhancing your ability to create compelling video content.
                  </Typography>
                </Box>

              </Box>
            </Box>
           <Box className="section-9 directory std-hero-section" >
              <Box className="section-15 py-12 mx-auto">
                <Box className="div-1232">
                  <Box className="mx-auto">
                    <Typography sx={{textAlign: 'center'}} variant="h3" gutterBottom fontWeight={'bolder'}>Make Your Videos Easily</Typography>
                  </Box>
                  <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                    <Button variant="contained" className="py-10 px-33" color="success">Create Now <ArrowRightAltIcon /></Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="footer-containner"><Footer /></Box>
        </Box>
        
    )
}