import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box, Menu } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { languageStrs_Store } from '../../../store/actions/editAction';

import { Menu_options, Pricing_options } from './../menu.config';
import { CircleFlag } from 'react-circle-flags'

import { editorLangEnStrs } from '../../../lang/editor_string_en';
import { editorLangDeStrs } from '../../../lang/editor_string_de';
import { editorLangFrStrs } from '../../../lang/editor_string_fr';
import { editorLangItStrs } from '../../../lang/editor_string_it';
import { editorLangPtStrs } from '../../../lang/editor_string_pt';
import { editorLangTrStrs } from '../../../lang/editor_string_tr';
import { editorLangEsStrs } from '../../../lang/editor_string_es';

export const MobileMenu = ({ pagename }) => {
	const [MenuItemElement, setMenuItemElement] = useState(null);
	const open = Boolean(MenuItemElement);

	const ItemListOpen = (event) => setMenuItemElement(event.currentTarget);
	const ItemListClose = (title) => setMenuItemElement(null);

	return (
		<>
			<Box className="mobileMenuIcon">
				<MenuIcon onClick={ItemListOpen} />
			</Box>

			<Menu
				className='mobile_menu_item_list'
				anchorEl={MenuItemElement}
				open={open}
				onClose={ItemListClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.3))',
						mt: 2,
						left: '0px !important',
						maxWidth: '100% !important',
						backgroundColor: 'unset',
						'& .MuiList-root': {
							padding: '0px',
						}
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<Box className='mobile_itemlist_containner'>
					<MobileLangBtns />
					{Menu_options.map((items, key) => (
						<MobileItems key={key}
							MenuCloseFunc={ItemListClose}
							items={items}
						/>
					))}
					{pagename !== 'pricing'&& (
						<Box className='Mobile_menu_item'>
							<Box className='parent_item2'>
								<Link to='/pricing' className='mobileitem2'>
									Pricing
								</Link>
							</Box>
						</Box>
					)}
					
					{pagename === 'pricing' && (
						Pricing_options.map((item, key) => (
							<Box className='Mobile_menu_item' key={key}>
								<Box className='parent_item2'>
									<Link to={item.link} className='mobileitem2'>
										{item.title}
									</Link>
								</Box>
							</Box>
						))
					)}

				</Box>
			</Menu>
		</>
	)
}

const MobileLangBtns = () => {
	const dispatch = useDispatch();
	const [childState, SetchildState] = useState(false);

	const menuItemEnterFunc = () => SetchildState(true);
	const menuItemLeaveFunc = () => SetchildState(false);
	const MobileClick = () => SetchildState(!childState);

	const languageItems = [
		{
			langName: 'English',
			component: <CircleFlag className='mr-2' countryCode='us' height={25} width={25} /> 
		},
		{
			langName: 'Türkçe',
			component: <CircleFlag className="mr-2" countryCode="tr" height={25} width={25} />
		},
		{
			langName: 'Français',
			component: <CircleFlag className="mr-2" countryCode="fr" height={25} width={25} /> 
		},
		{
			langName: 'Deutsch',
			component: <CircleFlag className="mr-2" countryCode="de" height={25} width={25} /> 
		},
		{
			langName: 'Espanol',
			component: <CircleFlag className="mr-2" countryCode="es" height={25} width={25} /> 
		},
		{
			langName: 'Italiano',
			component: <CircleFlag className="mr-2" countryCode="it" height={25} width={25} />
		},
		{
			langName: 'Portugues',
			component: <CircleFlag className="mr-2" countryCode="pt" height={25} width={25} />
		}
	];

	const chooseLang = (lgngItem) => {
		SetchildState(false);
		switch(lgngItem.langName){
			case 'English':
				dispatch(languageStrs_Store(editorLangEnStrs));
				break;
			case 'Deutsch':
				dispatch(languageStrs_Store(editorLangDeStrs));
				break;
			case 'Türkçe':
				dispatch(languageStrs_Store(editorLangTrStrs));
				break;
			case 'Espanol':
				dispatch(languageStrs_Store(editorLangEsStrs));
				break;
			case 'Italiano':
				dispatch(languageStrs_Store(editorLangItStrs));
				break;
			case 'Français':
				dispatch(languageStrs_Store(editorLangFrStrs));
				break;
			case 'Portugues':
				dispatch(languageStrs_Store(editorLangPtStrs));
				break;
			default:
			  dispatch(languageStrs_Store(editorLangEnStrs));
			  break;
		}
	}

	return (
		<Box className="Mobile_menu_item">
			<Box className="parent_item" onClick={MobileClick}>
				<label className='d-flex'> Language</label>
				{childState ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			</Box>
			<Box className="children_items" style={{ display: childState ? 'block' : 'none' }}>
				{languageItems.map((child_item, j) => { return (<Box className="d-flex items-center px-6 py-4" onClick={() => {chooseLang(child_item)}}>{child_item.component}<span key={j}>{child_item.langName}</span></Box>) })}
			</Box>
		</Box>
	)
}

const MobileItems = ({ items, MenuCloseFunc }) => {
	const [childState, SetchildState] = useState(false);

	const menuItemEnterFunc = () => SetchildState(true);
	const menuItemLeaveFunc = () => SetchildState(false);
	const MobileClick = () => SetchildState(!childState);
	// if (/Android|webOS|iPhone|iPad/i.test(navigator.userAgent)) { SetchildState(!childState); }

	return (
		<Box className="Mobile_menu_item" onMouseEnter={menuItemEnterFunc} onMouseLeave={menuItemLeaveFunc}>
			<Box className="parent_item" onClick={MobileClick}>
				<label>{items.header}</label>
				{childState ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			</Box>

			<Box className="children_items" style={{ display: childState ? 'block' : 'none' }}>
				<Box className="children_item">
					<Box className="children_item_header">
						<Link to={items.link}>
							<label>{items.header}</label>
						</Link>
					</Box>
				</Box>
				{items.items.map((child_item, j) => { return (<ChildreItems child_item={child_item} key={j} />) })}
				{/* {items.items.map((child_item, j) => { return (<span>{child_item.header}</span>) })} */}
			</Box>
		</Box>
	)
}

const ChildreItems = ({ child_item }) => {
	return (
		<Box className="children_item">
			{/* {child_item.header ? <Box className="children_item_header">{child_item.header}</Box> : ''} */}
			<Box className='children_item_header'>
				<Link to={child_item.link}>
					<label>{child_item.title}</label>
				</Link>
			</Box>

			{/* <Box className="children_item_body">
				{
					child_item.child.map((child, index) => {
						return (
							<Box key={index} className='child_body_item'>
								<Link to={child.link}>
									<label>{child.title}</label>
								</Link>
							</Box>
						)
					})
				}
			</Box> */}
		</Box>
	)
}