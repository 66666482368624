import React from 'react'
import { Link } from 'react-router-dom'
import './videooblog.scss';
import { Layouts } from '../../../component/layouts/layouts';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const blogImg   = BASE_STATIC_STORAGE_URL + '/images/blog15.jpg';
export const blog1Img  = BASE_STATIC_STORAGE_URL + '/images/blog1.jpg'
export const blog2Img  = BASE_STATIC_STORAGE_URL + '/images/blog2.jpg'
export const blog3Img  = BASE_STATIC_STORAGE_URL + '/images/blog3.jpg'
export const blog4Img  = BASE_STATIC_STORAGE_URL + '/images/blog4.jpg'
export const blog5Img  = BASE_STATIC_STORAGE_URL + '/images/blog5.jpg'
export const blog6Img  = BASE_STATIC_STORAGE_URL + '/images/blog6.jpg'
export const blog7Img  = BASE_STATIC_STORAGE_URL + '/images/blog7.jpg'
export const blog8Img  = BASE_STATIC_STORAGE_URL + '/images/blog8.jpg'
export const blog9Img  = BASE_STATIC_STORAGE_URL + '/images/blog9.jpg'
export const blog10Img = BASE_STATIC_STORAGE_URL + '/images/blog10.jpg'
export const blog11Img = BASE_STATIC_STORAGE_URL + '/images/blog11.jpg'
export const blog12Img = BASE_STATIC_STORAGE_URL + '/images/blog12.jpg'
export const blog13Img = BASE_STATIC_STORAGE_URL + '/images/blog13.jpg'
export const blog14Img = BASE_STATIC_STORAGE_URL + '/images/blog14.jpg'
export const blog15Img = BASE_STATIC_STORAGE_URL + '/images/blog.png'

const blogsData = [
  {
    img: blogImg,
    link: '/videooblog/top-online-video-editors-features-pros-cons-pricing',
    title: 'Top Online Video Editors: Features, Pros and Cons, and Pricing',
    description: 'This blog post provides a comprehensive review of various online video editors, discussing their features, pricing, and pros and cons to help content creators make an informed choice.',
  }, {
    img: blog1Img,
    title: 'How did we develop Videoo.io, the cloud based video editor',
    link: '/videooblog/how-did-we-develop-videoo-io-cloud-based-video-editor',
    description: 'This article explains the increasing popularity of cloud-based video editors and the various technologies and tools used in developing such editors, including programming languages, web frameworks, cloud storage, media processing, and DevOps tools. It emphasizes the importance of selecting the right tools to create a powerful and user-friendly video editor like Videoo.io.',
  }, {
    img: blog2Img,
    title: 'Cloud-Based Online Video Editors vs Desktop Video Editors',
    link: '/videooblog/cloud-based-vs-consumer-device-video-editors',
    description: 'A comparison about cloud-based online video editors and consumer device video editors, highlighting their pros and cons in terms of accessibility, performance, features, and collaboration tools. It provides useful insights to help readers decide which option is best for their video editing needs',
  }, {
    img: blog3Img,
    title: 'Creating Engaging Social Media Content with Videoo.io: A Guide',
    link: '/videooblog/creating-engaging-social-media-content-guide-videoo-io',
    description: 'This article provides a comprehensive guide on how to create engaging social media content using Videoo.io, a cloud-based video editor. It covers important steps such as defining your objective, planning your content, creating your video with Videoo.io, optimizing your video for social media, and promoting your video to reach your target audience.',
  }, {
    img: blog4Img,
    title: 'The Relationship between Online Cloud-Based Video Editors and Cloud Storage: A Convenient and Efficient Solution for Video Editing and Storage',
    link: '/videooblog/online-cloud-video-editors-and-cloud-storage-solution',
    description: 'This article explores the relationship between cloud-based video editors and cloud storage, highlighting the convenience and efficiency of using these tools together. It discusses the advantages of cloud-based video editors and cloud storage solutions, as well as the factors to consider when choosing a cloud storage solution for your video projects.',
  }, {
    img: blog5Img,
    title: 'Engaging Different Demographics on Social Media: A Guide to Using Videoo.io Online Video Editor for Tailored Content Creation',
    link: '/videooblog/engaging-different-demographics-on-social-media-guide-videoo-io',
    description: 'In this article, we explore how to engage different demographics on social media using Videoo.io, an online video editor. Understanding your audience is key to creating tailored content that resonates with them, and Videoo.io offers a range of features that make it easy to create professional-looking videos for different demographics.',
  }, {
    img: blog6Img,
    title: 'How to Add Auto Subtitles to Your Videos with Videoo.io Cloud-Based Video Editor',
    link: '/videooblog/how-to-add-auto-subtitles-videoo-io-video-editor',
    description: 'This article explores the benefits of adding auto subtitles to your videos, and how you can do it using Videoo.io cloud-based video editor. Auto subtitles not only make your content more inclusive and accessible, but can also improve your search rankings and drive more traffic to your website',
  }, {
    img: blog7Img,
    title: 'How to Add Auto-Translated Subtitles to Your Videos Using Videoo.io Cloud-Based Video Editor',
    link: '/videooblog/auto-translated-subtitles-videos-videoo-io-editor',
    description: 'Learn how to add auto-translated subtitles to your videos using Videoo.io cloud-based video editor. This guide explains the benefits of auto-translated subtitles, how to add them to your videos in multiple languages, and how they can help improve your SEO and reach a wider audience',
  }, {
    img: blog8Img,
    title: 'Cloud-Based Video Editors and Their Impact on Social Media',
    link: '/videooblog/cloud-based-video-editors-impact-on-social-media',
    description: 'Cloud-based video editors have revolutionized content creation on social media platforms by offering accessible and affordable solutions for creating high-quality videos. With the increasing demand for video content, these editors are expected to continue evolving and shaping the future of content creation on social media.',
  }, {
    img: blog9Img,
    title: 'Cloud-Based Video Editors and Their Impact on Search Engine Optimization (SEO)',
    link: '/videooblog/cloud-based-video-editors-impact-seo',
    description: 'Cloud-based video editors have an impact on SEO by offering features that improve video content ranking on search engines, including dwell time, engagement, and backlinks. By optimizing video titles and descriptions, adding closed captions, and creating engaging content, businesses can improve their online visibility and attract more traffic to their website',
  }, {
    img: blog10Img,
    title: 'Using Cloud-Based Video Editors to Increase Social Media Followers',
    link: '/videooblog/using-cloud-video-editors-increase-social-media-followers',
    description: 'This article discusses how cloud-based video editors can be used to increase social media followers for businesses of all sizes. With the ability to create high-quality videos, optimize for each platform, add captions and subtitles, and promote on multiple platforms, businesses can attract a wider audience and increase their online presence.',
  }, {
    img: blog11Img,
    title: 'Using Videoo.io to Increase Social Media Followers',
    link: '/videooblog/using-videoo-io-increase-social-media-followers',
    description: 'This article explores how businesses can use Videoo.io, an online video editor, to create high-quality videos that will increase their social media followers. By following tips such as starting with a plan, using high-quality video footage, keeping videos short, optimizing for each platform, adding captions and subtitles, and promoting on multiple platforms, businesses can create engaging videos that will attract and retain followers',
  }, {
    img: blog12Img,
    title: 'Videoo.io: A Cloud-Based Online Video Editor that Works with Every Browser and Operating System',
    link: '/videooblog/videoo-io-cloud-based-video-editor-every-browser-os',
    description: 'Videoo.io is a cloud-based online video editor that supports multiple platforms and offers high-quality video output. With easy-to-use interface, collaboration features, and security measures, it is an ideal tool for businesses and content creators to create professional-looking videos that work on any platform',
  }, {
    img: blog13Img,
    title: 'Videoo.io: The Versatile Cloud-Based Online Video Editor for Multiple Platforms',
    link: '/videooblog/videoo-io-versatile-cloud-based-editor-multiple-platforms',
    description: 'Videoo.io is a cloud-based online video editor that supports multiple platforms, including iOS, Microsoft, Android, and Linux. Its user-friendly interface, high-quality video output, and collaboration and security features make it an ideal tool for businesses and content creators to create professional-looking videos that work on any platform.',
  }, {
    img: blog14Img,
    title: 'Videoo.io - The Online Cloud-Based Video Editor: Our Journey and Development Process',
    link: '/videooblog/videoo-io-online-cloud-video-editor-development-journey',
    description: 'Videoo.io is a cloud-based online video editor that is accessible, easy-to-use, and secure. In this article, we explored the development process of Videoo.io and the features that make it stand out from other online video editors. Here is a step-by-step summary of what we covered:',
  }, {
    img: blog15Img,
    title: 'Top 20 Cloud Based Online Video Editors',
    link: '/videooblog/top-20-cloud-based-online-video-editors',
    description: 'Cloud-based online video editors have become a must-have tool for creating high-quality videos that engage and promote brands. Here are 20 different online cloud-based video editors, including Videoo.io, Animoto, InVideo, Magisto, Adobe Premiere Rush, WeVideo, Kapwing, Clideo, FlexClip, Lumen5, Wave.video, Powtoon, Biteable, Viddyoze, Filmora, Kizoa, OpenShot, VSDC Free Video Editor, veed.io, and Magix Movie Edit Pro. Each of these cloud-based online video editors offers its own unique features, pricing plans, and capabilities, making it important to consider your needs, budget, and skill level when choosing the right one for you',
  },
]

export const VideooBlog = () => {
  return (
    <Layouts>
      <div className='blog-wrapper'>
        <div className='px-10 text-center py-20 md:py-30 lg:py-40'>
          <h1 className='text-30 md:text-45 lg:text-55 font-bold leading-8'>
            Videoo Blog Guide
          </h1>
        </div>

        <div className='blog-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl2:grid-cols-4 gap-10 md:gap-20 mm:gap-30'>
          {blogsData.map((blogData, key) => (
            <Link to={blogData.link} key={key}
              className='blog-item flex flex-col gap-20 p-15 rounded-md'
            >
              <img alt='blog'
                src={blogData.img}
                className='w-full'
              />

              <div className='flex flex-col gap-10 py-5'>
                <div className='text-20 md:text-25 font-semibold leading-8'>
                  {blogData.title}
                </div>

                <div className='text-12 md:text-15'>
                  {blogData.description}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layouts>
  )
}