import { Box } from '@mui/system';

import './rotate-video.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import { createNewProject } from './../uploadHelper';
import { Footer } from '../../../component/home-footer/footer';
import { Header } from '../../../component/home-header/header';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Rotate Video.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Rotate Video.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Rotate Video.png';

export const RotateVideo = () => {
  return (
    <Box className="T_containner">
    <Box className="Header"><Header /></Box>

    <Box className="content-containner">
    <>
      <Box className='section-15'>
        <Box className='div-1232 _1300'>
          <Box className='breadcrumbs'>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Videoo</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Tools</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <Box className='breadcrumb-text-block'>Rotate Video</Box>
          </Box>
        </Box>
      </Box>

      <Box className='section-9 directory std-hero-section'>
        <Box className='div-1232'>
          <Box className='hero-grid'>
            <Box className='hero-grid-text'>
              <Box className='hero-header-title'>Video Rotator</Box>
              <Box className='hero-content-title'>Free online video editing software - create videos online</Box>

              <Box className='choose-btn'>
                <Box onClick={() => {
                  createNewProject("feature-rotate-video")
                }} className='choose-btn-text'>Create Project</Box>
                <Box className='choose-btn-img'>
                  <img src={UploadImg} alt='uploadImg' />
                </Box>
              </Box>
            </Box>
            <Box className='hero-img'>
              <iframe
                    src={`https://www.youtube.com/embed/MXDZZ_9TO74`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
                />
            </Box>
          </Box>

          {/* <Box className="logos-grid">
              <Box className="usedByLogos-item">
                  <img src={instagramLogo} alt="instagram logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={youtubeLogo} alt="youtube logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={facebookLogo} alt="facebook logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={twitterLogo} alt="twitter logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={tiktokLogo} alt="tiktok logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={whatsappLogo} alt="whatsapp logo" width={100} />
              </Box>
              {{usedLogs.map((logo, index) => (
                  <Box className="usedByLogos-item" key={index}>{logo}</Box>
              ))}}
          </Box> */}

        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232'>
          <Box className='div-block-67'>
            <h2 className="heading-19">Rotate Video Online, Free!</h2>
            <Box className='text-section-block'>
              <ul className='text-section-ul'>
                <li className='py-10'>
                  Have you ever recorded a video in the wrong orientation? Don't worry, it's a common mistake that can be easily fixed with Videoo.io, a cloud-based online video editor.

                  Videoo.io is a user-friendly online video editor that allows you to rotate your videos quickly and easily. You don't need expensive software or hardware to rotate your video, and you can access the video editor from anywhere, anytime.

                  Rotating a video using Videoo.io is a simple process that can be completed in just a few steps.                 
                </li>

                <li className='py-10'>
                  1. First, upload your video to the video editor. Once your video is uploaded, click on the "Rotate" button to access the rotation options.
                </li>
                <li className='py-10'>
                  2. You can rotate your video clockwise or counterclockwise, or flip it horizontally or vertically with just a few clicks. You can also adjust the angle of rotation to your desired orientation and preview the changes before saving.
                </li>
                <li className='py-10'>
                  3. Rotating your video using Videoo.io not only fixes orientation mistakes, but it can also enhance the overall quality of your video. You can add effects, transitions, text overlays, and other elements to make your video more engaging and professional-looking.
                </li>
                <li className='py-10'>
                  4. With Videoo.io, you can rotate your video quickly and easily, even on the go or in a time crunch. And, by optimizing your video orientation, you can improve the user experience on your website or social media channels, which can also benefit your search engine optimization (SEO) efforts.
                </li>
                <li className='py-10'>
                  In conclusion, rotating a video using Videoo.io is a simple and effective way to enhance your video quality and improve your online presence. With its easy-to-use interface and additional features, Videoo.io is a great tool for anyone looking to create high-quality, professional-looking videos.
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232 wide _3'>
          <Box className='div-block-70'>
            <Box className='div-block-69'>
              <h2 className="heading-20">How to Rotate a Video</h2>
            </Box>

            <Box className='div-block-68'>
              <Box className='how-to-div-item'>
                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Your File</h3>
                <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Rotate Video</h3>
                <p className="how-to-content">Simply click on the video’s image and drag the corners to adjust. Or click ‘Settings’ and choose a preset canvas size (like ‘YouTube’, or ‘Instagram Stories’) to rotate automatically</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Share!</h3>
                <p className="how-to-content">That’s it. You’re ready to share your video with your friends and followers. Hit ‘Export’ and your video will start to download</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
    </Box>

    <Box className="footer-containner"><Footer /></Box>
  </Box >

  )
}