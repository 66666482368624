import './blogs/videooblog/videooblog.scss'
import { Layouts } from '../component/layouts/layouts'

export const Terms = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Terms of Service
          </div>

          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              Welcome to Videoo.io! These Terms of Service govern your use of our online cloud-based video editor. By accessing or using Videoo.io, you agree to comply with these terms and conditions.

              <br /><br />

              <span className='font-semibold'>Use of Service:</span> Videoo.io provides a platform for users to create and edit videos online. You may use Videoo.io for personal or commercial purposes, provided that you comply with these Terms of Service and applicable laws.

              <br /><br />

              <span className='font-semibold'>User Responsibilities:</span> You are responsible for maintaining the confidentiality of your account and for all activities that occur under your account. You agree to use Videoo.io in a manner that is lawful and does not infringe upon the rights of others. You are solely responsible for the content you create and upload using Videoo.io.

              <br /><br />

              <span className='font-semibold'>Intellectual Property:</span> The content and features available on Videoo.io, including but not limited to text, graphics, logos, and software, are the property of Videoo.io and its licensors. You may not reproduce, distribute, modify, or create derivative works of the content without prior written consent.

              <br /><br />

              <span className='font-semibold'>Third-Party Content:</span> Videoo.io may include links or access to third-party content, products, or services. We do not endorse or assume any responsibility for the accuracy or legality of such content. Your interactions with third-party websites or services are at your own risk.

              <br /><br />

              <span className='font-semibold'>Limitation of Liability:</span> In no event shall Videoo.io or its affiliates be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use Videoo.io.

              <br /><br />

              <span className='font-semibold'>Modification or Termination:</span> Videoo.io reserves the right to modify, suspend, or terminate the service at any time without prior notice. We may also terminate or suspend your access to Videoo.io if we believe you have violated these Terms of Service.

              <br /><br />

              <span className='font-semibold'>Governing Law:</span> These Terms of Service shall be governed by and construed in accordance with the laws of The United Kingdom of Great Britain and Northern Ireland. Any legal action or proceeding arising out of or in connection with these terms shall be brought exclusively in the courts of The United Kingdom of Great Britain and Northern Ireland.

              <br /><br />

              <span className='font-semibold'>Changes to Terms of Service:</span> We may update these Terms of Service from time to time. Any changes will be posted on this page, and the revised terms will become effective immediately. By continuing to use Videoo.io, you agree to be bound by the updated Terms of Service.

              <br /><br />

              <span className='font-semibold'>Contact Us:</span> If you have any questions or concerns about these Terms of Service, please contact us at info@videoo.io.

            </div>

          </div

          >
        </div>
      </div>
    </Layouts>
  )
}