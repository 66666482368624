import { TOOLKEY, SUBTITLESTATEKEY, ELEMENTSTATEKEY, DRAWSTATEKEY, MENUBARREF, CAPTUREDIALOG, LANGUAGESTRS } from './../constants/editConst';

export const videoEditStore = (state = {}, action) => {
  switch (action.type) {
    case MENUBARREF: {
      return {
        ...state, menubarRef: action.payload
      }
    }

    case TOOLKEY: {
      return {
        ...state, selectedTool: action.payload
      }
    }

    case SUBTITLESTATEKEY: {
      return {
        ...state,
        SubtitlesState: action.payload
      };
    }

    case ELEMENTSTATEKEY: {
      return {
        ...state,
        ElementState: action.payload
      };
    }

    case DRAWSTATEKEY: {
      return {
        ...state,
        DrawState: action.payload
      };
    }

    case CAPTUREDIALOG: {
      return {
        ...state,
        captureDialog: {
          ...state.captureDialog,
          ...action.payload
        }
      }
    }

    case LANGUAGESTRS: {
      return {
        ...state,
        languageStrs: action.payload
      }
    }

    default: {
      return { ...state };
    }
  }
};