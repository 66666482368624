import { VIDEOELEMENTS, MOVEABLEDIVARRAY, SELECTEDITEMID, DATALOADED, WEBGLSIZE, IMAGEELEMENTS, SELECTEDGROUPID, AUDIOELEMENTS, SWITCHVIDEO } from "../constants/webglinfo";


export const webglStore = (state = {}, action) => {
  switch (action.type) {
    case VIDEOELEMENTS: {
      return { ...state, videoElements: action.payload }
    }

    case AUDIOELEMENTS: {
      return { ...state, audioElements: action.payload }
    }

    case IMAGEELEMENTS: {
      return { ...state, imageElements: action.payload }
    }

    case MOVEABLEDIVARRAY: {
      return { ...state, moveableDivArray: action.payload }
    }

    case SELECTEDITEMID: {
      return { ...state, selectedItemID: action.payload }
    }

    case DATALOADED: {
      return { ...state, dataLoaded: action.payload }
    }

    case WEBGLSIZE: {
      return { ...state, webGlSize: action.payload }
    }

    case SELECTEDGROUPID: {
      return { ...state, selectedGroupID: action.payload }
    }

    case SWITCHVIDEO: {
      return { ...state, switchVideo: action.payload}
    }

    default: {
      return { ...state };
    }
  }
};

