import React from 'react'
import { Link } from 'react-router-dom'
import './videoohowto.scss';
import { Layouts } from '../../../component/layouts/layouts';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const blogImg   = BASE_STATIC_STORAGE_URL + '/images/blog15.jpg';
export const blog1Img  = BASE_STATIC_STORAGE_URL + '/images/blog1.jpg'
export const blog2Img  = BASE_STATIC_STORAGE_URL + '/images/blog2.jpg'
export const blog3Img  = BASE_STATIC_STORAGE_URL + '/images/blog3.jpg'
export const blog4Img  = BASE_STATIC_STORAGE_URL + '/images/blog4.jpg'
export const blog5Img  = BASE_STATIC_STORAGE_URL + '/images/blog5.jpg'
export const blog6Img  = BASE_STATIC_STORAGE_URL + '/images/blog6.jpg'
export const blog7Img  = BASE_STATIC_STORAGE_URL + '/images/blog7.jpg'
export const blog8Img  = BASE_STATIC_STORAGE_URL + '/images/blog8.jpg'
export const blog9Img  = BASE_STATIC_STORAGE_URL + '/images/blog9.jpg'
export const blog10Img = BASE_STATIC_STORAGE_URL + '/images/blog10.jpg'
export const blog11Img = BASE_STATIC_STORAGE_URL + '/images/blog11.jpg'
export const blog12Img = BASE_STATIC_STORAGE_URL + '/images/blog12.jpg'
export const blog13Img = BASE_STATIC_STORAGE_URL + '/images/blog13.jpg'
export const blog14Img = BASE_STATIC_STORAGE_URL + '/images/blog14.jpg'
export const blog15Img = BASE_STATIC_STORAGE_URL + '/images/blog.png'

const blogsData = [
  {
    img: blogImg,
    link: '/videoohowto/step-by-step-guide-to-using-ai-based-auto-subtitles-on-videooio',
    title: 'Step-by-Step Guide to Using AI-Based Auto Subtitles on Videoo.io',
    description: 'This blog post is a step-by-step guide on how to utilize the AI-based auto subtitles feature on Videoo.io, along with a detailed look at its diverse capabilities.',
  }, {
    img: blog1Img,
    title: 'More how to content will be here about Videoo.io',
    link: '/videoohowto/step-by-step-guide-to-using-ai-based-auto-subtitles-on-videooio',
    description: 'Coming soon ...',
  },
]

export const VideooHowto = () => {
  return (
    <Layouts>
      <div className='blog-wrapper'>
        <div className='px-10 text-center py-20 md:py-30 lg:py-40'>
          <span className='text-30 md:text-45 lg:text-55 font-bold leading-8'>
          Videoo How To
          </span>
        </div>

        <div className='blog-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl2:grid-cols-2 gap-10 md:gap-20 mm:gap-30'>
          {blogsData.map((blogData, key) => (
            <Link to={blogData.link} key={key}
              className='blog-item flex flex-col gap-20 p-15 rounded-md'
            >
              <img alt='blog'
                src={blogData.img}
                className='w-full'
              />

              <div className='flex flex-col gap-10 py-5'>
                <div className='text-20 md:text-25 font-semibold leading-8'>
                  {blogData.title}
                </div>

                <div className='text-12 md:text-15'>
                  {blogData.description}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layouts>
  )
}