export const TOOLKEY = 'TOOLKEY';
export const SUBTITLESTATEKEY = 'SUBTITLESTATEKEY';
export const ELEMENTSTATEKEY = 'ELEMENTSTATEKEY';
export const DRAWSTATEKEY = 'DRAWSTATEKEY';
export const MENUBARREF = 'MENUBARREF';
export const CAPTUREDIALOG = 'CAPTUREDIALOG';
export const LANGUAGESTRS = 'LANGUAGESTRS';

export const videoEditData = {
  menubarRef: null,
  selectedTool: 0,
  SubtitlesState: 0,
  ElementState: 0,
  DrawState: false,
  captureDialog: {
    webcam: false,
    screen: false,
    webcamScreen: false
  },
  languageStrs: {},
};