import { Box } from '@mui/system';

import './audio-to-text.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';

import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import { createNewProject } from './../SubtitlesTranscriptionHelper';
import { Footer } from '../../../component/home-footer/footer';
import { Header } from '../../../component/home-header/header';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Audio to Text.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/add_more_files.jpg';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Audio to Text.png';

export const AudioToText = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
      <Box className='section-15'>
        <Box className='div-1232 _1300'>
          <Box className='breadcrumbs'>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Videoo</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Tools</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <Box className='breadcrumb-text-block'>Audio to Text</Box>
          </Box>
        </Box>
      </Box>

      <Box className='section-9 directory std-hero-section'>
        <Box className='div-1232'>
          <Box className='hero-grid'>
            <Box className='hero-grid-text'>
              <Box className='hero-header-title'>Transcribe Audio to Text</Box>
              <Box className='hero-content-title'>Free online video editing software - create videos online</Box>

              <Box onClick={createNewProject} className='choose-btn'>
                <Box className='choose-btn-text'>Choose Video</Box>
                <Box className='choose-btn-img'>
                  <img src={UploadImg} alt='uploadImg' />
                </Box>
              </Box>
            </Box>
            <Box className='hero-img'>
              <iframe
                  src={`https://www.youtube.com/embed/Q4sYrUTlJDQ`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                  style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
              />
            </Box>
          </Box>

          {/* <Box className="logos-grid">
              <Box className="usedByLogos-item">
                  <img src={instagramLogo} alt="instagram logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={youtubeLogo} alt="youtube logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={facebookLogo} alt="facebook logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={twitterLogo} alt="twitter logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={tiktokLogo} alt="tiktok logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={whatsappLogo} alt="whatsapp logo" width={100} />
              </Box>
              {{usedLogs.map((logo, index) => (
                  <Box className="usedByLogos-item" key={index}>{logo}</Box>
              ))}}
          </Box> */}

        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232'>
          <Box className='div-block-67'>
            <h2 className="heading-19">Add Audio to Text Online, Free!</h2>
            <Box className='text-section-block'>
              <ul className='text-section-ul'>
                <li className='py-10'>
                  A Comprehensive Guide to Cloud-Based Video Editing and Audio-to-Text
                </li>
                <li className='py-10'>
                  As technology continues to evolve, the way we create and edit videos is also changing. Gone are the days where you needed expensive editing software and powerful hardware to edit your videos. With the rise of cloud-based video editors, editing your videos is now easier, faster, and more affordable than ever before.
                  One such cloud-based video editor is Videoo.io, which not only allows you to edit your videos but also includes an audio-to-text transcription feature. In this article, we will explore the benefits of cloud-based video editing and how to use Videoo.io to edit your videos and transcribe your audio.
                </li>
                <li className='py-10'>
                  Cloud-based video editing software provides a range of benefits over traditional desktop editing software. Some of the benefits include:

                  Accessibility: You can access your video editing software from anywhere in the world, as long as you have an internet connection.

                  Collaboration: Cloud-based video editors allow you to work on your videos with others in real-time, regardless of location.

                  Cost-effective: Cloud-based video editors are often more affordable than traditional desktop software, as you only pay for what you need and don't have to worry about hardware requirements.

                  Automatic updates: Cloud-based video editors are automatically updated, so you don't have to worry about purchasing the latest version of the software.

                  Using Videoo.io for Cloud-Based Video Editing and Audio-to-Text Transcription
                </li>
                <li className='py-10'>
                  Step 1: Sign Up for Videoo.io
                  To get started with Videoo.io, you will need to sign up for an account. Head to the Videoo.io website and click on the "Sign Up" button. Follow the on-screen instructions to create your account.
                </li>
                <li className='py-10'>
                  Step 2: Upload Your Video
                  Once you have created your account, you can upload your video to the platform. Click on the "Upload Video" button and select the video file from your computer. You can also import videos from your Google Drive, Dropbox, or OneDrive account.
                </li>
                <li className='py-10'>
                  Step 3: Edit Your Video
                  Videoo.io provides a range of video editing tools to help you create a professional-looking video. These tools include trimming, splitting, merging, and adding text or music. Simply select the tool you want to use and make the necessary changes to your video.
                </li>
                <li className='py-10'>
                  Step 4: Transcribe Your Audio
                  Videoo.io also includes an audio-to-text transcription feature, which allows you to transcribe your video's audio automatically. To use this feature, click on the "Transcribe Audio" button, and the software will do the rest.
                </li>
                <li className='py-10'>
                  Step 5: Download and Share Your Video
                  Once you have finished editing and transcribing your video, you can download it to your computer or share it directly from the platform. Videoo.io supports a range of video file formats, including MP4, AVI, and MOV.
                </li>
                <li className='py-10'>
                  Conclusion
                  Cloud-based video editors like Videoo.io have revolutionized the way we create and edit videos. They provide a range of benefits over traditional desktop software, including accessibility, collaboration, and cost-effectiveness. By following these step-by-step instructions, you can use Videoo.io to edit your videos and transcribe your audio, making the video creation process quicker, easier, and more efficient.
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232 wide _3'>
          <Box className='div-block-70'>
            <Box className='div-block-69'>
              <h2 className="heading-20">How to Add Audio to Text for a Video</h2>
            </Box>

            <Box className='div-block-68'>
              <Box className='how-to-div-item'>
                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Your File</h3>
                <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Audio to Text</h3>
                <p className="how-to-content">Simply click on the video’s image and drag the corners to adjust. Or click ‘Settings’ and choose a preset canvas size (like ‘YouTube’, or ‘Instagram Stories’) to Add Audio to Text automatically</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Share!</h3>
                <p className="how-to-content">That’s it. You’re ready to share your video with your friends and followers. Hit ‘Export’ and your video will start to download</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
   
  )
}