import { Box } from '@mui/material';
import './layouts.scss'

import { Header } from '../home-header/header';
import { Footer } from '../home-footer/footer';

export const Layouts = ({ children }) => {
  return (
    <Box className="T_containner">
      <Box className="Header">
        <Header />
      </Box>

      <Box className="content-containner">
        {children}
      </Box>

      <Box className="footer-containner">
        <Footer />
      </Box>
    </Box>
  )
}