import React, { useCallback, useState, useEffect } from "react";
import { NavLink, Link, useSearchParams } from 'react-router-dom';
import { Box } from "@mui/material";
import Swal from 'sweetalert2';
import './signUp.scss';
import Logo from '../../assets/image/Logo.png';
import { GoogleIcon } from "../../Icons";
import { useLocation } from 'react-router-dom';

import { requestSignUpCode, verifyLoginCode } from "../../axios/ApiProvider"; // Make sure these functions are exported from ApiProvider.js
const BASE_FRONTEND_URL        = process.env.REACT_APP_BASE_FRONTEND_URL;
const BASE_FRONTEND_EDITOR_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL;
const BASE_BACKEND_URL         = process.env.REACT_APP_BASE_BACKEND_URL;
const BASE_GOOGLE_CLIENT_ID    = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof (url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11458714849/ngVxCJDNmIMZEOG5-Ncq',
    'value': 1.0,
    'currency': 'USD',
    'transaction_id': '',
    'event_callback': callback
  });
  return false;
}

export const SignUp = () => {
  const location = useLocation();
  const [countdown, setCountdown] = useState(0);
  const queryParams = new URLSearchParams(location.search);
  const projectUUID = queryParams.get('project_uuid');

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const [accountInfo, setAccountInfo] = useState({
    email: email || "",
    name: "",
    surname: "",
    code: ""
  });

  const [codeSent, setCodeSent] = useState(false);

  const GoogleSignUpBtn = useCallback(() => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = 'api/v1/oauth/google';

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');
    const params = {
      response_type: 'code',
      client_id: BASE_GOOGLE_CLIENT_ID,
      redirect_uri: `${BASE_BACKEND_URL}/${redirectUri}`,
      prompt: 'select_account',
      access_type: 'offline',
      scope
    };

    gtag_report_conversion();

    const urlParams = new URLSearchParams(params).toString();
    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const requestCode = async () => {
    let username = accountInfo.email || "";
    let name = accountInfo.name || "";
    let surname = accountInfo.surname || "";
    const alphanumeric = /^[\p{L}\p{N}]*$/u;

    if (!validateEmail(username)) {
      window.toastr.error("Email is not acceptable");
      return;
    }

    if (!name || !name.match(alphanumeric)) {
      window.toastr.error("Check your name, it should be alphanumeric");
      return;
    }

    if (!surname || !surname.match(alphanumeric)) {
      window.toastr.error("Check your surname, it should be alphanumeric");
      return;
    }

    try {
      const result = await requestSignUpCode({ email: username, name, surname, username });

      console.log("result:", JSON.stringify(result));

      if (result.state=="success") {
        setCodeSent(true);
        setCountdown(300); // Start countdown from 120 seconds
        window.toastr.success("Verification code sent to your email.");
      } else {
        Swal.fire({
          title: "Sign up failed",
          html: result.data.code || "Failed to send verification code.",
          showConfirmButton: true,
        }).then((result) => {
          console.log(result);
        })
        //window.toastr.error(result.data.code || "Failed to send verification code.");
      }
    } catch (error) {
      window.toastr.error("An error occurred. Please try again.");
    }
  };

  const verifyCode = async () => {
    try {
      let result = await verifyLoginCode(accountInfo.email, accountInfo.code);
      if (result.state === 'success') {
        window.toastr.success("Signup successful and you are now logged in.");
        // Redirect or perform other actions on successful verification
        if (projectUUID) {
          window.location = `${BASE_FRONTEND_EDITOR_URL}/${projectUUID}`;
        } else {
          console.log("response: ", BASE_FRONTEND_URL);
          window.location = `${BASE_FRONTEND_URL}/dashboard/templates`;
        }
      } else {
        Swal.fire({
          title: "Sign up failed",
          html: result.data.code || "Invalid or expired code.",
          showConfirmButton: true,
        }).then((result) => {
          console.log(result);
        })
        //window.toastr.error(result.message || "Invalid or expired code.");
      }
    } catch (error) {
      window.toastr.error("An error occurred during verification. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    setAccountInfo({
      ...accountInfo,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    let timer;
    if (codeSent && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setCodeSent(false); // Optionally reset codeSent if countdown finishes
    }
    return () => clearInterval(timer);
  }, [codeSent, countdown]);

  return (
    <Box className="signUp-containner">
      <Box className="signup-left">
        <Box className='signup-left-containner'>
          <Box className='signup-left-content'>
            <Box className='signup-content-header'>
              <Link to={'..'}>
                <img src={Logo} alt='logo' />
              </Link>

              <Box className='signup-header-content'>
                Create your account
              </Box>

              <Box className='signup-header-footer'>
                Create videos with a single click
              </Box>
            </Box>

            <Box className='signup-content-body'>

              {projectUUID ? null : (
                <Box className='googleBtn' onClick={GoogleSignUpBtn}>
                  <GoogleIcon /> Continue with Google
                </Box>
              )}
              {projectUUID ? (
                <Box className='signup-content-or'></Box>
              ) : (
                <Box className='signup-content-or'>OR</Box>
              )}

              <Box className='signup-content-input'>
                <input readOnly={codeSent} placeholder="Enter your email address here" data-testid="@email-input" type="email" name="email" className='email-input' id='email_input' onChange={handleInputChange} value={accountInfo.email} />
                <label htmlFor='email' className='email-input-label'>
                  Email Address
                </label>
              </Box>

              <Box className='signup-content-input'>
                <input readOnly={codeSent} placeholder="Enter your name here" data-testid="@name-input" type="text" name="name" className='name-input' id='name_input' onChange={handleInputChange} value={accountInfo.name} />
                <label htmlFor='name' className='name-input-label'>
                  Name
                </label>
              </Box>

              <Box className='signup-content-input'>
                <input readOnly={codeSent} placeholder="Enter your surname here" data-testid="@surname-input" type="text" name="surname" className='surname-input' id='surname_input' onChange={handleInputChange} value={accountInfo.surname} />
                <label htmlFor='surname' className='surname-input-label'>
                  Surname
                </label>
              </Box>

              {codeSent && (
                <Box className='signup-content-input'>
                  <input
                    placeholder="Enter the 6-digit code"
                    data-testid="@code-input"
                    type="text"
                    name="code"
                    className='email-input'
                    id='code_input'
                    value={accountInfo.code}
                    onChange={handleInputChange}
                  />
                  <label htmlFor='code' className='email-input-label'>
                    Verification Code
                  </label>
                </Box>
              )}

              {codeSent && (
                <Box className='signup-countdown'>
                  Resend code in: {Math.floor(countdown / 60)}:{String(countdown % 60).padStart(2, '0')}
                </Box>
              )}

              {!codeSent ? (
                <Box className='signup-Btn' onClick={requestCode}>
                  Send Code
                </Box>
              ) : (
                <Box className='signup-Btn' onClick={verifyCode}>
                  Verify Code
                </Box>
              )}

              <p className='signup-btn-footer-text'>
                {codeSent
                  ? "Enter the code sent to your email to complete signup."
                  : "We'll email you a 6-digit code to verify your account."}
              </p>
            </Box>
          </Box>

          <Box className='signup-left-footer'>
            <Box className='signup-content-footer'>
              <Box className='signup-content-footer-text'>
                By proceeding, you agree to our
                <Box>
                  <Link to=''>Terms</Link> and <Link to=''>Privacy Policy</Link>
                </Box>
              </Box>

              <Box className='signup-content-footer-footer'>
                Already have an account? <NavLink to={`/signin/?project_uuid=${projectUUID || ''}`}>Sign in</NavLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="signup-right"></Box>
    </Box>
  )
}
