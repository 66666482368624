import { Box } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";

import './subtitlestranscription.scss';
import { Header } from "../../component/home-header/header";
import { Footer } from "../../component/home-footer/footer";
import { AddSubtitles } from "./add-subtitles/add-subtitles";
import { AudioToText } from "./audio-to-text/audio-to-text";
import { AutoSubtitles } from "./auto-subtitles/auto-subtitles";
import { TranscribeVideo } from "./transcribe-video/transcribe-video";
import { TranslateVideo } from "./translate-video/translate-video";
import { NotMatch } from "../notMatch";

export const SubtitlesTranscription = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
        <Routes>
          <Route path="/add-subtitles-to-video" element={<AddSubtitles />} />
          <Route path="/audio-to-text" element={<AudioToText />} />
          <Route path="/subtitle-generator" element={<AutoSubtitles />} />
          <Route path="/transcribe-video-to-text" element={<TranscribeVideo />} />
          <Route path="/video-translator" element={<TranslateVideo />} />
          <Route path="/" element={<Navigate replace to="/subtitlestranscription/add-subtitles" />} />
        </Routes>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
  )
}