import { Box, Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from "react-router-dom";

import './add-text-to-video.scss';
import { createNewProject } from './../VideoEditorHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

import { GetProjectLink } from "../../../axios/ApiProvider";

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

export const how_to_1 = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File.png';
export const how_to_2 = BASE_STATIC_STORAGE_URL + '/images/features/Join-Merge Video.png';
export const how_to_3 = BASE_STATIC_STORAGE_URL + '/images/features/Export Loop Video.png';

const VideooEditor_AddText_Welcome = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-1-Welcome.png'
const VideooEditor_AddText_Step1 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-2-AddTextMenu.png'
const VideooEditor_AddText_Step2 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-3-AddMultipleText.png'
const VideooEditor_AddText_Step3 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-4-ChangeFont.png'
const VideooEditor_AddText_Step4 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-5-ChangeFontBackgroundColor.png'
const VideooEditor_AddText_Step5 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-6-ChangeFontColor.png'
const VideooEditor_AddText_Step6 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-7-AddingSubtitles.png'
const VideooEditor_AddText_Step7 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-8-SubtitlesPositioning.png'
const VideooEditor_AddText_Step8 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-9-CretingSubtitles.png'
const VideooEditor_AddText_Step9 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-10-CreateSubtitleTranslations.png'
const VideooEditor_AddText_Step10 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-11-TranslationsLanguageSelection.png'
const VideooEditor_AddText_Step11 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-12-SubtitlesEditor.png'
const VideooEditor_AddText_Step12 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-13-SubtitlesGenerated.png'
const VideooEditor_AddText_Step13 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-14-DualSubtitles.png'
const VideooEditor_AddText_Step14 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-15-ExportVideoWithSingleSubtitles.png'
const VideooEditor_AddText_Step15 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddText-15-ExportVideoWithSingleSubtitles.png'

const VideooEditor_AddText_Action1 = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Your%20File%20Text.png'
const VideooEditor_AddText_Action2 = BASE_STATIC_STORAGE_URL + '/images/features/Translate%20Video.png'
const VideooEditor_AddText_Action3 = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Text.png'

const VideooEditor_AddTransitions_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Transition%20to%20Video.png'
const VideooEditor_AddMusic_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Music%20to%20Video%202.png'
const VideooEditor_AddText_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Text%20to%20Video.png'
const VideooEditor_AddStock_Image = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Stock.png'


export const AddTextToVideo = () => {
    const handleCreateNewProject = async () => {
        let result = await GetProjectLink();
        if (!result || result.state == 'error') window.toastr.error(result.data.message);
        else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
    }

    return (
        <Box className="T_containner">
          <Box className="Header"><Header /></Box>

          <Box className="content-containner">
            <Box className="section-15" sx={{marginTop: '4rem'}}> 
                <Box className="div-1232">
                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={400}>
                        <Grid item xs={12} md={6}>
                            <Card className="p-6">
                                <Box className="div-1232">
                                    <Typography variant="h3" gutterBottom fontWeight={'bold'}>
                                    Add Text to Your <br />Videos Online
                                    </Typography>
                                </Box>
                                <Box className="div-1232" sx={{marginBottom: '1rem'}}>
                                    <Typography variant="body1" gutterBottom >
                                    Enhance your videos with text overlays, captions, and subtitles using VIDEOO's intuitive online video editor. Add text to your videos to create engaging content that captivates your audience and conveys your message effectively. With VIDEOO, you can customize the font, size, color, and position of your text to match your video's style and branding. Whether you're creating social media content, promotional videos, or educational tutorials, adding text to your videos has never been easier.
                                    </Typography>
                                </Box>
                                <Box className="div-1232" onClick={handleCreateNewProject}>
                                    <Button variant="contained" className="create-now" color="success">Create Now</Button>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} height={'100%'}>
                            <Card className="p-6">
                                {/*<img src={Illustration} className="mx-auto h-full " />*/}
                                <div style={{ width: '100%', paddingTop: '56.25%', position: 'relative' }}>
                                <iframe
                                    src="https://www.youtube.com/embed/hKcQsxQsdqM"
                                    title="Videoo.io, Online Cloud Based Video Editor"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                ></iframe>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box className='section-15 pt-12'>
              <Box className='div-1232'>
                <Box className='breadcrumbs'>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Videoo</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Video Editor</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Add Image to Video</Box>
                  </a>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" sx={{paddingY: '3rem'}}>
              <Box className="div-1232">
                <Box className="mx-auto text-center" sx={{width: '80%'}}>
                  <Typography variant="h3" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Text To Your Video with Drag-and-Drop editing</Typography>
                </Box>
                <Box sx={{textAlign: 'center', marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >Easily add text with a single click</Typography>
                </Box>
                <Box className="container">
                  <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                    <Grid item md={6} xs={12} >
                      <img width={'100%'} src={VideooEditor_AddText_Welcome} />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <Box sx={{paddingBottom: '0.5rem'}}>
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>With VIDEOO's intuitive drag-and-drop editing feature, adding images to your videos has never been easier.</Typography>
                      </Box>
                      <Box sx={{paddingBottom: '1rem'}}>
                        <Typography variant="body1" gutterBottom>
                        With VIDEOO's intuitive drag-and-drop editing feature, adding text to your videos has never been easier. Simply drag your text using your preferred desktop or mobile browser and drop it into your video timeline. Our platform supports seamless addition of text, allowing you to customize fonts, sizes, colors, and opacity to enhance your video content effortlessly. Perfect for creating engaging and visually vivid videos, VIDEOO ensures a smooth and user-friendly editing experience directly in your browser. You don't need to install any additional software on your device, which also guarantees your software is always up-to-date.
                        </Typography>
                      </Box>
                      <Box sx={{paddingTop: '1rem'}}>
                        <Button variant="contained" className="py-10 px-33" color="success">Learn More</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box className="section-9 directory std-hero-section" >
              <Box className="div-1232" >
                <Box className="text-center">
                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>How to Add a Text or Subtitles to a Video, a step-by-step Guide</Typography>
                </Box>
                <Box className="text-center" sx={{marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >You can use Videoo.io to enhance your creativity by adding custom, Auto Subtitles or AI-generated Subtitle Translations to your video projects.</Typography>
                </Box>

                <Box className="section-9 directory std-hero-section" >
                    <Box className="div-1232">
                        <Box className="container">
                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step1} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 1 - Welcome to Videoo.io</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        - Signup & Login to Videoo.io, and create either a blank or template project.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Go to Text Section on left side menu. Click on Add Text to start adding text to your video.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step2} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 2 - Adding Multiple Text on Timeline</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body1" gutterBottom>
                                        - You can add multiple text to your video timeline. Each text can be customized with different font, opacity, size, color, and position.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Once you have added text to your video, you can also adjust the duration of the text overlay to control when it appears and disappears in your video.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../signup" component={Link} variant="contained" className="py-10 px-33" color="success">Sign up to Videoo.io</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step3} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 3 - Changing Font Type</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                         - You can change the font of a text element by selecting the text and choosing a different font from the font menu. VIDEOO offers a wide range of fonts to choose from, allowing you to customize the style of your text to match your video's theme or branding.
                                        </Typography>
                                    </Box>
                                    <Box className="div-1232" onClick={handleCreateNewProject}>
                                        <Button variant="contained" className="create-now" color="success">Create a Blank Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step4} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 4 - Changing Text Background Color</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                         - To change the background color of a text element, select the text and click on the color swatch next to the font menu. This will open a color picker where you can choose a new background color for the text.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                         - Just below the color, there is a slider which will alter the opacity of the background canvas of the text. You can adjust the opacity to make the text more visible or blend it with the video.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/templates" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Template Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step5} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 5 - Changing Font Color</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - To change the color of a text element, select the text and click on the color swatch next to the font menu. This will open a color picker where you can choose a new color for the text.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - VIDEOO offers a wide range of colors to choose from, allowing you to customize the style of your text to match your video's theme or branding.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - You can also adjust the opacity of the text to make it more visible or blend it with the video.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/stocks" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Stock Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step6} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 6 - Adding Auto Subtitles</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - VIDEOO offers an automatic subtitle generation feature that can transcribe your video's audio content into text. This feature is perfect for creating subtitles for your videos quickly and efficiently. To add auto subtitles to your video, follow these steps:
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - Upload your video to the VIDEOO editor. The platform will automatically transcribe the audio content into text subtitles. You can then edit the subtitles to correct any errors or make adjustments as needed.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - <a href="https://assemblyai.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>AssemblyAI:</strong></a> and <a href="https://speechmatics.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Speechmatics:</strong></a> are intergarted to Videoo.io to detect the subtitles using AI.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - Videoo.io supports 25 languages for subtitle translations. You can select the language of your choice to generate subtitles for your video. You can also add translated subtitles in 25+ languages to your video to reach a global audience.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step7} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 7 - Selecting Subtitles Position on Video</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Before generating subtitles or even once you have added subtitles to your video, you can adjust their position on the screen to ensure they are visible and do not obstruct any important visual elements. VIDEOO offers a range of positioning options to customize the appearance of your subtitles.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step8} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 8 - Generating Auto Subtitles</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Once your Auto Subtitles setup including langugage selection, target language selection and subtitles position on video, you can generate the subtitles by clicking on the "Generate Subtitles" button.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - The platform will automatically transcribe the audio content into text subtitles. 
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - Just wait until the process is completed.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step9} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 9 - Generating Translated Subtitles</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - To enable Translated Subtitles mode, you need to unselect "Disable Translate" checkbox in Translations Menu. Then select origin language on the menu.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step10} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 10 - Selecting Auto-Translations Target Language</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body2" gutterBottom>
                                        - Make sure that you have also selected target language to translate the subtitles. Also make sure that you are happy with font type, size and allignment. You can also select the position of the translated subtitle in advance.
                                      </Typography>
                                      <Typography variant="body2" gutterBottom>
                                        - VIDEOO supports 25 languages for subtitle translations. You can select the target language of your choice to generate subtitles for your video.
                                      </Typography>
                                      <Typography variant="body2" gutterBottom>
                                        - If you are happy with your setup you can click "Auto Translations" button, and wait until procedure is completed.
                                      </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step11} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 11 - Subtitles Editor</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - After the subtitles are generated, you can make corrections for the subtitles easily.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - Just click on and Subtitles Item on the timeline, and then click "Edit Subtitles" on mini helper menu.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - Now, you can see all subtitles in this row in a single screen. 
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - Make necessary minor corrections such as punctionatios and context corrections. You can easily add, duplicate or split any subtitle text element using relevant icons on subtitles editor.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - To re-assign new text properties for all subtitles, you can select "Style" button. Then change font type, size and subtitles positioning and click "Save" button to apply your changes.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - Voila ! All your changes are now applied to all subtitles, preview them to make sure that everything is correct.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddText_Step12} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 12 - Export Text and Subtitles</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Once you have added text or subtitles to your video, you can export the final project in various formats. VIDEOO offers a range of export options to suit your needs, including resolutions up to 4K and various file formats.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - You can export your video with text or subtitles directly to your device or share it on social media platforms. VIDEOO ensures that your final video is high-quality and ready to be shared with your audience.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
              </Box>
            </Box>
            <Box className="section-9  std-hero-section">
              <Box className="div-1232">
                <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} >Easy-to Use Add Text to Video Features</Typography>
                <Box className="container py-15" sx={{marginTop: '2rem'}}>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Text Editor
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body1" gutterBottom>
                          VIDEOO’s Text Editor provides an intuitive and user-friendly interface for adding and customizing text in your videos. Here’s how it simplifies the process:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Seamless Integration:</strong> The Text Editor is seamlessly integrated into the VIDEOO platform, allowing you to add text elements directly onto your video canvas. Simply click on the text tool to start creating.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Customization Options:</strong> Customize your text with a variety of fonts, sizes, and colors. You can also adjust the background color and opacity of the text box to ensure it stands out or blends seamlessly with your video.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Drag-and-Drop Functionality:</strong> Easily position your text anywhere on the video canvas with drag-and-drop functionality. This makes it simple to place text exactly where you want it.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Trim and Sync:</strong> Use the timeline to trim and sync text elements with your video content. Adjust the duration and start time to ensure your text appears and disappears at the perfect moment.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Multiple Text Elements:</strong> Add multiple text elements to your video. Each text box can have its own unique properties, allowing for complex and dynamic text overlays.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Real-Time Preview:</strong> Preview your changes in real-time to see how the text integrates with your video. This helps you make quick adjustments and ensures the final product meets your expectations.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          With VIDEOO’s Text Editor, adding text to your videos is a straightforward and efficient process. The tool’s comprehensive customization options and user-friendly interface empower you to create engaging and professional video content with ease.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create Video with Text Editor</Button>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Auto Subtitles
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body1" gutterBottom>
                          VIDEOO’s Auto Subtitles feature makes it incredibly easy to add accurate and professional subtitles to your videos. Here’s how it simplifies the process:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Automatic Generation:</strong> The Auto Subtitles feature uses advanced AI technology to automatically generate subtitles for your videos. This saves you the time and effort of manually transcribing your video content.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Support for Multiple Languages:</strong> Generate subtitles in over 50 languages, making your content accessible to a global audience. This is particularly useful for creators who want to reach a broader audience without language barriers.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Customizable Appearance:</strong> Customize the look of your subtitles by changing the font, color, background, and opacity. This allows you to match the subtitles with your video's style and branding.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Easy Positioning:</strong> Change the location of subtitles on the screen to ensure they do not obstruct important visuals. You can move subtitles to different parts of the screen as needed.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Dual Subtitles:</strong> Add dual subtitles to cater to bilingual audiences. This feature allows you to display subtitles in two different languages simultaneously, enhancing accessibility.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Trim and Sync:</strong> Use the timeline to trim and synchronize subtitles with your video content. Adjust the duration and start time to ensure subtitles appear and disappear at the right moments.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          With VIDEOO’s Auto Subtitles feature, adding and customizing subtitles in your videos is effortless. The tool’s AI-powered capabilities and user-friendly interface help you create engaging, accessible, and professional content with minimal effort.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create Video with Subtitles</Button>
                      </Box>
                    </Grid>

                    <Box className="container">
                      <Grid container spacing={5}>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AddText_Action1}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Your Content</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Select your video and upload it. You can drag & drop, or choose the file from your computer or mobile device. Or you can start a new project based on a Stock Content or Template Project that suits your needs.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AddText_Action2}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Text to Video</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Add text to your video to provide context, information, or branding. Customize the font, size, color, and position of the text to match your video's style. Consider adding Auto-Subtitles and Auto Translations, even Dual Subtitles !
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AddText_Action3}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Export, Download & Share</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Once you've added text to your video, you can export the final project in various formats. VIDEOO offers a range of export options to suit your needs, including resolutions up to 4K and various file formats.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                      </Grid>
                  </Box>

                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                            Auto Translations
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body1" gutterBottom>
                          VIDEOO’s Auto Translations feature makes it effortless to translate your video subtitles into multiple languages, enhancing your content’s accessibility and reach. Here’s how it works:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>AI-Powered Translations:</strong> Leverage advanced AI technology to automatically translate your subtitles into over 50 languages. This saves you the time and effort of manual translation and ensures accuracy.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Global Reach:</strong> With the ability to add translations in multiple languages, your videos can cater to a global audience. This feature is perfect for creators looking to expand their reach and engage viewers from different linguistic backgrounds.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Seamless Integration:</strong> The Auto Translations feature is seamlessly integrated into the VIDEOO platform. Simply generate your original subtitles and use the translation tool to create translated versions quickly.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Customizable Subtitles:</strong> Customize the translated subtitles to match your video’s style. You can adjust the font, color, background, and opacity to ensure consistency and readability.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Accurate Synchronization:</strong> Ensure that your translated subtitles are perfectly synced with your video content. Adjust the timing on the timeline to match the spoken words accurately.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          With VIDEOO’s Auto Translations feature, adding multilingual subtitles to your videos is simple and efficient. This powerful tool helps you make your content accessible to a wider audience, ensuring that language is no barrier to your message.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create Video with Translations</Button>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                            Dual Subtitles
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body1" gutterBottom>
                          VIDEOO’s Dual Translations feature enables you to display subtitles in two different languages simultaneously, making your videos more accessible to bilingual audiences. Here’s how it enhances your video content:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Enhanced Accessibility:</strong> By providing subtitles in two languages at once, you cater to a more diverse audience, ensuring viewers who speak different languages can understand and enjoy your content.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>AI-Powered Translations:</strong> Utilize advanced AI technology to automatically generate and translate subtitles into multiple languages. This streamlines the process and guarantees accuracy.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Customizable Display:</strong> Customize the appearance of each set of subtitles. You can adjust the font, color, background, and opacity to ensure both subtitles are clear and distinguishable from each other.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Flexible Positioning:</strong> Position the dual subtitles anywhere on the screen to avoid overlapping and ensure they do not obstruct important visual elements. You have full control over the placement to maintain a clean and professional look.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Seamless Integration:</strong> The Dual Translations feature integrates seamlessly into the VIDEOO platform. Add and manage multiple subtitle tracks with ease, ensuring a smooth workflow.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          With VIDEOO’s Dual Translations feature, you can effectively communicate with a wider audience and make your videos accessible to viewers from different linguistic backgrounds. This powerful tool simplifies the process of adding and managing bilingual subtitles.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create with Dual Subtitles</Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Box className="py-10">
                        <img width={'100%'} src={VideooEditor_AddText_Step13} />
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box className="section-9 directory" >
                <Box className="div-1232 px-18">
                    <Box>
                    <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Other Video Tools</Typography>
                    </Box>
                    <Box className="text-center">
                    <Typography className="text-center" variant="body1" gutterBottom color={'#4D4D4D'}>There are plenty of other features that are avaliable with Videoo.io</Typography>
                    </Box>
                </Box>
                <Box >
                    <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddText_Action1} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Music to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-music-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddText_Action2} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Text to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-text-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddText_Action3} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Free Stock Content to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-free-stock-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid>
                            <img width={'100%'} src={VideooEditor_AddTransitions_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Transitions to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-transition-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box className="section-9 directory" sx={{ paddingY: '3rem' }}>
              <Box className="div-1232 px-18">
                <Box className="text-center">
                  <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                    Frequently Asked Questions
                  </Typography>
                </Box>
                <Box className="text-center mx-auto" sx={{ marginBottom: '3rem', width: '60%' }}>
                  <Typography variant="body1" gutterBottom>
                    Welcome to our FAQ section about Adding Text to a Video! Here, we answer the most common questions about using VIDEOO’s text features. Whether you're new to video editing or a seasoned pro, you'll find helpful tips and insights to make the most of our platform.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #1: How can I add multiple text elements to my video?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    To add multiple text elements to your video using VIDEOO, simply use the text tool to create and customize each text box. You can add as many text elements as you need, each with its own unique properties such as font type, color, background color, and opacity.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #2: Can I change the background color and opacity of the text?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO allows you to customize the background color and opacity of your text elements. You can choose from a wide range of colors and adjust the opacity to achieve the desired effect.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #3: How do I change the font type and color of the text?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    In VIDEOO, you can easily change the font type and color of your text elements. Select the text box you want to edit, and use the text properties panel to choose your preferred font and color.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #4: Can I trim text elements on the timeline?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO provides the capability to trim text elements on the timeline. You can adjust the duration and position of each text element to ensure it appears at the right moment in your video.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #5: How can I add auto subtitles to my video?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    VIDEOO offers auto subtitle generation in 50 languages. Simply use the subtitle tool to automatically generate subtitles for your video. You can also edit and customize these subtitles as needed.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #6: Can I add AI-generated translated subtitles?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO allows you to add AI-generated translated subtitles in multiple languages. This feature helps you reach a wider audience by providing subtitles in different languages.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #7: How do I change the location of subtitles on the screen?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    You can easily change the location of subtitles on the screen in VIDEOO. Select the subtitle box and drag it to your desired position on the video canvas.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #8: Is it possible to add dual subtitles to my video?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO supports the addition of dual subtitles. You can add subtitles in two different languages and position them separately on the screen to cater to a bilingual audience.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #9: Can I adjust the FPS (frames per second) for my video text elements?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO allows you to adjust the FPS for your video, ensuring that your text elements sync perfectly with the video content. This is especially useful for achieving smooth transitions and effects.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" >
              <Box className="section-15 py-12 mx-auto">
                <Box className="div-1232">
                  <Box className="mx-auto">
                    <Typography sx={{textAlign: 'center'}} variant="h3" gutterBottom fontWeight={'bolder'}>Make Your Videos Easily</Typography>
                  </Box>
                  <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                    <Button variant="contained" className="py-10 px-33" color="success">Create Now <ArrowRightAltIcon /></Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="footer-containner"><Footer /></Box>
        </Box>
        
    )
}