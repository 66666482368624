import { Box } from '@mui/system';

import './crop-video.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import { createNewProject } from './../uploadHelper';
import { Footer } from '../../../component/home-footer/footer';
import { Header } from '../../../component/home-header/header';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Crop Video.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Crop Video.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Crop.png';

export const CropVideo = () => {
    return (
        <Box className="T_containner">
            <Box className="Header"><Header /></Box>

            <Box className="content-containner">
                <>
                    <Box className='section-15'>
                        <Box className='div-1232 _1300'>
                            <Box className='breadcrumbs'>
                                <a href={'/'}>
                                    <Box className='breadcrumb-text-block'>Vdieoo</Box>
                                </a>
                                <Box className='breadcrumb-arrow'></Box>
                                <a href={'/'}>
                                    <Box className='breadcrumb-text-block'>Tools</Box>
                                </a>
                                <Box className='breadcrumb-arrow'></Box>
                                <Box className='breadcrumb-text-block'>Crop Video</Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box className='section-9 directory std-hero-section'>
                        <Box className='div-1232'>
                            <Box className='hero-grid'>
                                <Box className='hero-grid-text'>
                                    <Box className='hero-header-title'>Crop Video Online</Box>
                                    <Box className='hero-content-title'>Free online video editing software - create videos online</Box>

                                    <Box onClick={() => {
                                        createNewProject("feature-crop-video")
                                    }} className='choose-btn'>
                                        <Box className='choose-btn-text'>Choose Video</Box>
                                        <Box className='choose-btn-img'><img src={UploadImg} alt='uploadImg' /></Box>
                                    </Box>
                                </Box>

                                <Box className='hero-img'>
                                    <iframe
                                        src={`https://www.youtube.com/embed/MXDZZ_9TO74`}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Embedded youtube"
                                        style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
                                    />
                                </Box>
                            </Box>

                            {/* <Box className="logos-grid">
                                <Box className="usedByLogos-item">
                                    <img src={instagramLogo} alt="instagram logo" width={100} />
                                </Box>
                                <Box className="usedByLogos-item">
                                    <img src={youtubeLogo} alt="youtube logo" width={100} />
                                </Box>
                                <Box className="usedByLogos-item">
                                    <img src={facebookLogo} alt="facebook logo" width={100} />
                                </Box>
                                <Box className="usedByLogos-item">
                                    <img src={twitterLogo} alt="twitter logo" width={100} />
                                </Box>
                                <Box className="usedByLogos-item">
                                    <img src={tiktokLogo} alt="tiktok logo" width={100} />
                                </Box>
                                <Box className="usedByLogos-item">
                                    <img src={whatsappLogo} alt="whatsapp logo" width={100} />
                                </Box>
                                {{usedLogs.map((logo, index) => (
                                    <Box className="usedByLogos-item" key={index}>{logo}</Box>
                                ))} }
                            </Box> */}
                        </Box>
                    </Box>

                    <Box className='wf-section'>
                        <Box className='div-1232'>
                            <Box className='div-block-67'>
                                <h2 className="heading-19">Crop Video Online, Free!</h2>

                                <Box className='text-section-block'>
                                    <ul className='text-section-ul'>
                                        <li className='py-10'>
                                            If you have a video that needs to be cropped, you don't need expensive software or hardware to do it.
                                            With Videoo.io, a cloud-based online video editor, you can crop your video quickly and easily.
                                        </li>

                                        <li className='py-10'>
                                            Videoo.io is a user-friendly online video editor that allows you to crop your videos with just a few clicks.
                                            You can access the video editor from anywhere, anytime, and crop your video to your desired dimensions.
                                        </li>

                                        <li className='py-10'>
                                            Cropping a video using Videoo.io is a simple process that can be completed in just a few steps.
                                            First, upload your video to the video editor.
                                            Once your video is uploaded, drag and drop it onto the timeline.
                                            Select the portion of the video you want to keep by dragging the handles of the crop tool.
                                        </li>

                                        <li className='py-10'>
                                            You can also adjust the aspect ratio and size of the crop tool to fit your desired dimensions.
                                            Once you have selected the portion of the video you want to keep, preview your video to ensure that it looks the way you want it to.
                                            If you are happy with the result, save your cropped video to your computer or directly to your social media or website.
                                        </li>

                                        <li className='py-10'>
                                            Cropping your video using Videoo.io can not only help you remove unwanted portions of the video, but it can also enhance the overall quality of your video.
                                            You can also add effects, transitions, text overlays, and other elements to make your video more engaging and professional-looking.
                                        </li>

                                        <li className='py-10'>
                                            In conclusion, cropping a video using Videoo.io is a simple and effective way to improve the quality of your video and optimize it for your website or social media channels.
                                            With its easy-to-use interface and additional features, Videoo.io is a great tool for anyone looking to create high-quality, professional-looking videos.
                                        </li>

                                        <li className='py-10'>
                                            <span>Upload your video:</span> Start by logging in to your Videoo.io account and uploading your video file to the editor.
                                        </li>

                                        <li className='py-10'>
                                            <span>Add the video to the timeline:</span> Once your video is uploaded, drag and drop it onto the timeline. You will see the video appear as a series of clips on the timeline.
                                        </li>

                                        <li className='py-10'>
                                            <span>Select the clip to crop:</span> Click on the clip you want to crop to select it. You will see a blue border appear around the clip to indicate that it is selected.
                                        </li>

                                        <li className='py-10'>
                                            <span>Open the Crop tool:</span> Click on the Crop tool button in the toolbar on the right-hand side of the editor.
                                        </li>

                                        <li className='py-10'>
                                            <span>Adjust the crop tool:</span> Click and drag the handles of the crop tool to select the portion of the video you want to keep. You can also adjust the aspect ratio and size of the crop tool to fit your desired dimensions.
                                        </li>

                                        <li className='py-10'>
                                            <span>Preview the cropped video:</span> Once you have selected the portion of the video you want to keep, preview your video to ensure that it looks the way you want it to. You can use the playback controls below the preview screen to play, pause, or scrub through your video.
                                        </li>

                                        <li className='py-10'>
                                            <span>Save your cropped video:</span> If you are happy with the result, save your cropped video to your computer or directly to your social media or website. You can use the Export button in the top-right corner of the editor to choose your export settings and save your video.
                                        </li>

                                        <li className='py-10'>
                                            In conclusion, cropping a video using Videoo.io is a simple and effective way to improve the quality of your video and optimize it for your website or social media channels. By following these step-by-step instructions, you can crop your video quickly and easily, even on the go or in a time crunch.
                                        </li>
                                    </ul>
                                </Box>

                            </Box>
                        </Box>
                    </Box>

                    <Box className='wf-section'>
                        <Box className='div-1232 wide _3'>
                            <Box className='div-block-70'>
                                <Box className='div-block-69'>
                                    <h2 className="heading-20">How to Crop a Video</h2>
                                </Box>

                                <Box className='div-block-68'>
                                    <Box className='how-to-div-item'>
                                        <img src={how_to_1} alt='howTo1' className="how-to-img" />
                                        <h3 className="heading-21 m-y-0">Add Your File</h3>
                                        <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
                                    </Box>

                                    <Box className='how-to-div-item'>
                                        <img src={how_to_2} alt='howTo2' className="how-to-img" />
                                        <h3 className="heading-21 m-y-0">Crop Video</h3>
                                        <p className="how-to-content">Simply click on the video's image and drag the corners to adjust. Or click 'Settings' and choose a preset canvas size (like 'YouTube', or 'Instagram Stories') to crop automatically</p>
                                    </Box>

                                    <Box className='how-to-div-item'>
                                        <img src={how_to_3} alt='howTo3' className="how-to-img" />
                                        <h3 className="heading-21 m-y-0">Share!</h3>
                                        <p className="how-to-content">That's it. You're ready to share your video with your friends and followers. Hit 'Export' and your video will start to download</p>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            </Box>

            <Box className="footer-containner"><Footer /></Box>
        </Box >
       
    )
}