import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { ArrowIcon } from '../../Icons';
import { FreequentlyQuestionInfo } from './../../pages/Pricing/pricing.config';

export const GridListsItem = ({ item }) => {
	if (item.type === "header")
		return (
			<Box className="compares-gridList-featureTitle-contain">
				<Typography className="compares-gridList-featureTitle">
					{item.title}
				</Typography>

				<Box className="compares-gridList-featureTitle-Icon">
					{item.infoIcon}
				</Box>
			</Box>
		);
	else if (item.type === "icon") return item.item;
	else if (item.type === "title")
		return (
			<Typography className="compares-gridList-itemtitle">
				{item.title}
			</Typography>
		);
	else if (item.type === "headerTitle")
		return (
			<Typography className="compares-grid-list-header">
				{item.title}
			</Typography>
		);
	else if (item.type === "link")
		return (
			<Link to={item.link} className="compares-gridList-link">
				{item.title}
			</Link>
		);
	else return <hr className="compares-gridList-border" />;
};

export const FreequentlyQuestion = () => {
	return (
		<Box className="frequently-question-items">
			{FreequentlyQuestionInfo.map((item, index) => { return <FreequentlyQuestionItem item={item} key={index} />; })}
		</Box>
	);
};

const FreequentlyQuestionItem = ({ item }) => {
	const [openStatus, setopenStatus] = useState(false);
	const questionOpenClick = () => { setopenStatus(!openStatus); };

	return (
		<Box className="frequently-question-item">
			<Box className="frequently-question-item-header" onClick={questionOpenClick}>
				<Typography className="frequently-question-item-headerTitle">
					{item.hader}
				</Typography>

				<ArrowIcon className={openStatus ? `frequently-question-upIcon` : `frequently-question-downIcon`} />
			</Box>

			{openStatus && (
				<Box className="frequently-question-content">
					<Typography className="frequently-question-contentTitle">
						{item.content}

						{item.link.status && (
							<a href={item.link.url} className="frequently-question-contentLink">
								{item.link.title}
							</a>
						)}
					</Typography>
				</Box>
			)}

			<hr className="freequently-question-border" />
		</Box>
	);
};
