export const exportsData = {
  'localhost': {
        "count": 39,
        "next": "http://localhost:8000/user/exports/?page=2",
        "previous": null,
        "results": [
            {
                "uuid": "895ccec8-788e-46da-a71c-6c57b87a63dc",
                "title": "Output-AudioOnly-93209d3a-4792-4f64-b969-900bfcad5569.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2021-03-05T23:24:20.140189Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/895ccec8-788e-46da-a71c-6c57b87a63dc.mp4?Expires=1709845629&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=FSMzp9Ro%2BfGY7PolxGaKfMeP3D4%2FOZFNb63PQ%2Bp25usL7Eqyj0%2BDHljB7RBsI4wzvMQVrE%2B5Q8VJ65b1UlN5AQnADFY58m4cT3SyTpx7dt%2Br52wzHDX75T6Vx16SHdVve%2B%2FIUZxpp3H814xkIreKT1NmnaV73RfgAw%2BZXlAHK8SLMBFJ75GwVFuz01%2FTXVXVZ8zxZtysJ72ks7gZVRDoMIKkZat%2F2qFji2pPd1aphvHtWlkQfXELCYf%2BRhIdjtk%2FvQaWa%2FrMPrVHsSSXqMsrTCyk1S7sDMDlreqZN25zdt2t8xttzMZDQbcwVJR2O1Igs%2BXfpf%2BH62MF0muNVC6FsQ%3D%3D",
                "export_thumbnail": null,
                "project_uuid": "93209d3a-4792-4f64-b969-900bfcad5569"
            },
            {
                "uuid": "b939e19d-f4da-4d41-8929-9e66d3425eb1",
                "title": "Output-AudioOnly-93209d3a-4792-4f64-b969-900bfcad5569.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-05T23:49:20.140189Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/b939e19d-f4da-4d41-8929-9e66d3425eb1.mp4?Expires=1709845629&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=KbYkpAw53tTxl8OgbO63BiXcASP2TYQaLWnfriHxT9ayOv%2FVVZQ56xnvsiBoHe76aKzJ7jMZNtUHKEzWexLsacQ7NjivejIRyxtFLDe4h2w9kAZREA6JkxJDjyQNtwHjjhAJiHYMZF8L8Inkb6a4jvWX4YoqdJW8i9iyjNRHeFvceZo0RL1tlNrpx53cDuz3jJGYKv9KWGQMzhv0NZWPYpREUZoodgNITHR%2FSG4matTjeeozDV7fENKntCbZtj5yt9jI6O0vo%2BVvW77AvIwMabHAY0q5gjGrXGD4DNGWeGPZvEkeUAvHMMrWnl1WCpd%2Ffh0pF2U9nqa5GPRminnZvg%3D%3D",
                "export_thumbnail": null,
                "project_uuid": "93209d3a-4792-4f64-b969-900bfcad5569"
            },
            {
                "uuid": "0c6e470b-d02c-494f-afa1-f68042ec8c16",
                "title": "Output-AudioOnly-71b71b20-b1e3-4972-aef1-2a12456d5a5f.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-05T23:40:20.140189Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/0c6e470b-d02c-494f-afa1-f68042ec8c16.mp4?Expires=1709845630&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=gjUCWe6xGGyW3lbAf5q6gew5%2BFPukFc4BiMCvtN9F1iMuZNt3PuGuv8B2oxQIMKlxZ%2FCeTm9S40TGTenhy6KlcLkI8udTkSgq8thED7svUh4cL5nAP2BU43BMpQ3%2BT6FCPXa2tRxaIGa9y6S1aD7Hct62ekQ5ppsJCWIJPUQs6SPWZilIqwObBvQcKXIg4gaRUx96YKzTQk1rd8%2B%2F4pGyHt0eyeTP8kN%2BFTyKOTvCCeLC1GejM1f9WXWdZQ%2Fm6MHLTDErpN5BS%2FoCe7y0ap8qKoIBbLyG0gznDz5UBap%2BT3mbxCRd%2B%2FKiLLsVtHdW%2BRFFSwFAkyUbI9N2CGutooXEA%3D%3D",
                "export_thumbnail": null,
                "project_uuid": "71b71b20-b1e3-4972-aef1-2a12456d5a5f"
            },
            {
                "uuid": "58cfbe3f-3a31-41e8-a694-85303712dca1",
                "title": "Output-71b71b20-b1e3-4972-aef1-2a12456d5a5f.mp4",
                "state": 4,
                "category": 5,
                "created_at": "2024-03-05T22:51:20.140189Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/58cfbe3f-3a31-41e8-a694-85303712dca1.mp4?Expires=1709845630&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=W%2BTpeHTZOcgakbgVARPHAbX6gKcl%2FRYKrbv5P%2B2K1plsYoZGAmgXUwQHrPop7kQzDMfrwZD4d288XJoZNvFUJXD3H69Y%2FvUI%2BNQE1vcBJ8Qw6fWtq4WqjzqGDILEawXfraYQCxPXOaGJFT3wa3DZBdeyCBPFr721z4wtSPr8l1FHOLHjk62Zq56BKQGLVNy7cCAQGmVKNWZp%2F5gvU0TWH137eqSxo06jXxg5Fgyi8HLhnm7qPdIBPAxhBN7qdON4S%2FOZEBtlgAie7ALWMWak%2BCTw0utHpodKFpSTt84cPDkKpy7w8bon9WEKSUV%2FfqCBySzS2PC%2FGBwgSVglB7DhHA%3D%3D",
                "export_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/b7f29795-00af-42f1-8b24-4e6f495d64ec?Expires=1709845631&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=nsrnMuoErPQQELV%2FAel9IASqRzHyadr7wS1sXLxuuuUWgjibgF0KwWEXp%2F2A84fqUZ7B%2FgtKB1b3NokqU0ODlZULPx0r7hciEbzpT2C2XYV1wbywo2ei47ocsBej0LmDSt1RhBs3qUTczufkxR1KczJElaY8SRMRp5L7Db7kBiMy%2F8%2BjJaNlcKXjBxfNcONdKJvARmCwial0IcYVhgyx6y1kzm9jn4DU8DMb%2Fdn%2FS6Z9BlqWuxN6NzZ8tdNrLMKRlCTGta7r1Vj4BYxMj8dJOVEoPR2MIQDDIt3Eu1P4ca6C0fmFaOAX2t9cuFb%2F6gdR5b0vtKf8PZ9B45qUvLMeHw%3D%3D",
                "project_uuid": "71b71b20-b1e3-4972-aef1-2a12456d5a5f"
            },
            {
                "uuid": "a5b35f87-05ff-4ac2-b6ac-990dd0847c57",
                "title": "Output-AudioOnly-71b71b20-b1e3-4972-aef1-2a12456d5a5f.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-04T15:48:01.182883Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/a5b35f87-05ff-4ac2-b6ac-990dd0847c57.mp4?Expires=1709845631&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=woS5Y%2ByTEj35IxNKeg8Vmji290Q3zXzJYkSEugPJ8qVjeqVI7fibhSv6FavRBrhfcIiJuJlFxu6KX7UFeSa3Z26IYWNst8nji54Uc4hX%2BrQlJ6BlmM1Gg%2Bl%2B1jYWXKfC0xOAJ5ZOWESUybgSDR0UcCvGcNhtXi9X5Uo3RhtGF1EL7aHBpLf1JF7vhhtCJfG%2Bp4ca%2FkkhGZY4GKFDE0PvQlao39dbkDhTzruq%2BDPb%2F0YENb%2B12I%2F5%2BpxrVngYZQaUcUIhZL3tNRVOlDQWofrlUiXEvG615cXKmUBC2%2Bm%2Fq6QyqBoTq5D6fMHKosk1GOh0hnWyIVlhnfC%2B7kYJFIVbeQ%3D%3D",
                "export_thumbnail": null,
                "project_uuid": "71b71b20-b1e3-4972-aef1-2a12456d5a5f"
            },
            {
                "uuid": "036591eb-d780-41cc-a9ad-8376e622127e",
                "title": "Output-AudioOnly-71b71b20-b1e3-4972-aef1-2a12456d5a5f.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-04T15:38:26.998989Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/036591eb-d780-41cc-a9ad-8376e622127e.mp4?Expires=1709845631&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=CfW%2BSASVC55lfh6pTEASmmHF1W35W%2FbQNe7LfYVs7gSA2nbQKs%2F6FY5qpjU6WEU6xJpOrItxHuCispNiA6HjmYsC1Xg7peKs1azx1Jh7TI5FmZUwPhwc3myRooKMGyroxAjbsKiAeLAfnYydMfV1xYId85f5N9j6MEOLfO2AzMnXv1Mkf0YVRLTKl0GYQaVRaZTm1ifwvcS587SLmYzWZGNSzeDy1ihGQIpXhaLSnmZwXV%2B%2BBQXmdNbz5T2W3jEug8%2BSevjLR%2F9TQkDI3ziioA6uaekBVIUdojLOapVWhQQE%2FXUnzk9vUgwtrnLe00Ldsr7o%2BpbmeJTNwRba228b2g%3D%3D",
                "export_thumbnail": null,
                "project_uuid": "71b71b20-b1e3-4972-aef1-2a12456d5a5f"
            },
            {
                "uuid": "b5fb3dfe-559e-4497-ab8b-68a57156ff85",
                "title": "Output-AudioOnly-71b71b20-b1e3-4972-aef1-2a12456d5a5f.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-04T15:36:57.775715Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/b5fb3dfe-559e-4497-ab8b-68a57156ff85.mp4?Expires=1709845632&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=nus44Re%2BMvtcfy4m%2F8vv9ytogoSTyinhL6Sztt%2B74zyvo%2FhFoOLuvpWYQTUoGURZgcSHdxFqkXznkFy8VnQtGWRzkNbbwzqTd8UQsVid9yhio6pGklHH%2FctumYdexg4wfXcJfAKoBklTYDqYbuNzWrH0YjSSDTk35ya6zd57HZKhTeRnQTtzWn79NMVrXHQH0qqYBPj%2F8sZ118%2F0kNVljHb8dOM2%2BnrtODHdW2QEK%2BoZXMuDSME2q9DJ8Z42ae5JyUj1CaJqJJJoDfQaMl6dnYyFQDtQ%2B%2Bhe7%2FVhr2hDUq01Zam8UBJ936UfTCYeqaj44Xe%2BE4s44MVbamK1114r6Q%3D%3D",
                "export_thumbnail": null,
                "project_uuid": "71b71b20-b1e3-4972-aef1-2a12456d5a5f"
            },
            {
                "uuid": "0207211b-aef5-4240-878e-9606d25ff3ad",
                "title": "Output-AudioOnly-71b71b20-b1e3-4972-aef1-2a12456d5a5f.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-04T15:30:37.105352Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/0207211b-aef5-4240-878e-9606d25ff3ad.mp4?Expires=1709845633&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=jaTqSUNsmDwoSeF5uy80nUtyHZfThdeXJvTmg8mH27TdZDWu%2FTeeAIl%2F%2FPBWRFZrmiJHCNUPfgOUz%2BArMobZ5nxrvK04XrTwqP22G9Vk973TafqSq9CozYeceFglW06s%2FWUeybNz4DOgXOWtY6i4ROu%2BK95If3InAa9ciaxFkrw0e776H2GXN4kwTyvEcKdVn3YFygXM6t7f7glHUENuNKMhumGCDydByf2l7jD95IwpvDksgfddec6TIb%2FIc4O3mbl%2FCqeO40ra59mGyY4vP%2Fnp3NpUsgDXsynnbjk9PCGxU8DBXLZkQFBbIlnFOyARWNiPxUQMdNeHXgH85ckAzg%3D%3D",
                "export_thumbnail": null,
                "project_uuid": "71b71b20-b1e3-4972-aef1-2a12456d5a5f"
            },
            {
                "uuid": "f71a7ed6-7642-4bfa-a4d3-49f4641cdb4e",
                "title": "Output-a1503fe0-2885-4d09-8745-cffabdcf82f0.mp4",
                "state": 4,
                "category": 5,
                "created_at": "2024-03-03T23:06:45.996451Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/f71a7ed6-7642-4bfa-a4d3-49f4641cdb4e.mp4?Expires=1709845633&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=f90K5esYR9y%2FVgUreFs3v8o9ASmabsvca9ibIpAud9sDtm%2BHGWs85kkcQ0bWvlWSLKFqZb0NlWOO1yKTQVMCo3zV7DD2jjr1p4z2Zya0S4T7gNqT%2B22zd%2FxyhKwO1ZvFrRoHf58l5MYrXZrwPAReUBSQVOdnq6FoOWYmzoUmcTavOGokMdBq5wRPKpDABBckOjgdWp8%2FgEZ%2B8YHSoRlgexoYzTOPajeki4QncTm0PwWb0Ej8BB9Tx1KzDwIH37rQz2i74s98BGWyV3kAT3NjP8i6HoUV9Utjfb0g3c6PZF%2Baq5NOdGlXziHQqB4nMjxlmbPcuFoqQxYF4hhBpsSN8A%3D%3D",
                "export_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/f03c8d31-0995-4c1c-a804-1ddf4b0498c0?Expires=1709845633&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=SBQFBzoQfLe7oPJX7XArSVyMJlqQ%2FdWlCoUeXO%2BSi2l%2B%2FtLBpidJpe0R77LdDA2yJ6oP2rtmG817kOqg0R71BaBLLDCSAI0q5bPVXbh1lkShxhanNbCcA5g9pcbNCDfy%2FUBNSDiXi%2F8MTi72rAY99YZovJkPbbzh6p6n3khbVqtw%2FY3sHhOFfjYjrnAWrxtO8r91CMq%2B2TT4HvpXH7BzBSIFIuS15HegievNL1KPAiquJ68WIbfFgS4LwwD4Tz8O1kZIzON7Rmgw6vDue%2BmDxYWcIyRkblJnUZABNAWRA4WxCnoqQfTz%2FefX32%2FcSQ%2BQo3KjJhnuvbDWZpb%2FQ8Ub4A%3D%3D",
                "project_uuid": "a1503fe0-2885-4d09-8745-cffabdcf82f0"
            },
            {
                "uuid": "2597cc1d-8474-4be7-a89a-36ff6a870d96",
                "title": "Output-a1503fe0-2885-4d09-8745-cffabdcf82f0.mp4",
                "state": 4,
                "category": 5,
                "created_at": "2024-03-03T19:20:41.382264Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/2597cc1d-8474-4be7-a89a-36ff6a870d96.mp4?Expires=1709845634&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=g3I1gOsEx%2BT83UrJAostSrO8yHBl9%2FI0ObA1JTq6%2BKfDuSebGyS%2BCdchQEreH%2BGCl9CGghH4QWBDUl7DWFPYZ385kaOFHT%2BPjIPV5WIGFpc0yGdD%2F1mmJ1Fa%2BSzw3fCQMRbVavi0ihVNDk7y%2BccrH2J%2B0MgUHRbDRxY5P0DtNYcnRAAwsTNmNEFG%2BDdadeh44OP2%2BG1vArBs1tHr7UQ273qc3z%2FLvb6WZUqgFo8OpRHitonNrCZRdLRWE0QZejr82pwqtIha8kWPVh%2BK08tgfrHfI6%2FJs5M9NHHjrFx1srxYeErsopmIz5obtEwmle3n4JTfSO77f7iuOL9oWYSczQ%3D%3D",
                "export_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/42683d4b-5c06-49ad-83e8-53c5406f140f?Expires=1709845634&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=YoSeQq0q9WhzQjwJlSG2haM1sIfMFjshxPo8KL2WJpshFkjih67T%2FbVlw1eQLjt2DO1leJ4suGex737sDTGX9KJwQGTQrZqCto%2BqpnOY3WCMZKPNp%2B%2BarGU65qru0dlcL9yKB%2FoiVERrvqpBBijOHhSBFE0YlI%2FK73xoEt8ExuFI4%2FARZWtIcAXiDFqAe2RQ%2Fg2I1tPBVmodiFOUoPWnG%2BcoM%2FJ6Yunt5Ov%2FOpgpdBtQTIh3U2qZBzJsGFpLcUZisbu36tMVq%2FWFEFTFRLY6rz9sMM%2BuNCVANA0qbRVT6m3jLiNJYv3bWC5cN83uato3qmbGkAt%2Fcxv53%2FoDo6KkoQ%3D%3D",
                "project_uuid": "a1503fe0-2885-4d09-8745-cffabdcf82f0"
            },
            {
                "uuid": "e651d1c0-2265-4f58-8489-7c1788320a45",
                "title": "Output-a1503fe0-2885-4d09-8745-cffabdcf82f0.mp4",
                "state": 4,
                "category": 5,
                "created_at": "2024-03-03T19:20:15.393531Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/e651d1c0-2265-4f58-8489-7c1788320a45.mp4?Expires=1709845634&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=fGhg3pm5tBq5TaD%2Bookunr%2B1SHeN4BI3AP4kPNC%2BQYwptQ237oeUkGMbLRJRykFP%2Fr4BeQIEzMHFpjmgN5rmmdOVbbLfOEQgnx1IsLYlzq3MwrgiGOx8fBOssTV9BniLRlJFb0JjJ%2BxKwmX6U1R8x56FxhQWHfWs1xGvyBoPL7MJ%2Fy2TXIxz3HqiRCP3K9AjaH8zq01%2F6bVUtOQ9N8rgIOZLcydq%2BObfz2ObHstjhNVZY7izteDd9BEII7FgdfJeHAM6UORuTJYuWXnwkXdE5jsAO%2F3gyGBeDp8yIGxPZVMTXPUaJeSZBfoz1P1AtntasCh2EaeRW%2Fj3lcyrZH6I3A%3D%3D",
                "export_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/0ad78efd-d03f-4df3-bc72-ffd5fce6ee66?Expires=1709845635&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=v4it7r8Zdz1ApqdZbVBroPn8Hgtbru2x8aqiGUoEbqarFkMOZl8PwXrsa8kH3gSljZl%2Blkh4JIhJ8CXuD2cmONuW5H8DmmNaWQ4ouvzmApw73tAC1G13r%2BvShidPcK136EclQcKKLICeFWFAs%2B%2Frzki9BeB6FYMXqzwSHvRLDizmQmRLKWJkCW8r5KeigNsfZv8PviL7Tv1Se0pfHxdF3YEMusF9axq1Vmj4bJTW4HOQZP3p4Gm%2B5kttKp4P6Hah8KFYJSK1xP%2FIj7WOEy47QPfOQkrbxVNTS389Xetx3yMYzeVW3LcsDTLUjt8PonBeC46o7%2FBIUGjAu5vrn6g2JQ%3D%3D",
                "project_uuid": "a1503fe0-2885-4d09-8745-cffabdcf82f0"
            },
            {
                "uuid": "1cb94884-9910-43e6-a4d2-608f298e336e",
                "title": "Output-a1503fe0-2885-4d09-8745-cffabdcf82f0.mp4",
                "state": 4,
                "category": 5,
                "created_at": "2024-03-03T19:18:17.549639Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/1cb94884-9910-43e6-a4d2-608f298e336e.mp4?Expires=1709845635&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=YeNH05xz03ydrAd4TyWp30vf%2FIyfw%2BX4RzGSharcXSTwmXG02GjXYpfF77ISdBxqOKwoXUdoAYlmEtmXdYq3pcUNaTLocrUKwh%2B98hvlhFxJghRKbFqrX%2BS4ARwVGbSGiGx%2BFGbI2OKh1ScBV7o86OeOiO2mWMePo6NP5R7BsbOGiYrvYl4eX%2B9Di0cgH%2B00917ct67gU2Lv9lMZgHyVHMt891L2LFvBjOKFO23LoJVdPSCPv67o5M0SkaStPZkjAZysTaaBwMYhuxHKFOYS4jEpfJ6zMigfASAxlpPS%2BsWVEiaErI1wnIkHYTk9KpHPv7qoWkWm%2FsWKPWdbEdv9sQ%3D%3D",
                "export_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/349e74b3-58c2-4e4d-89e7-0e3e6d1cd45b?Expires=1709845635&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=N%2BCAN5Pe7E6uTK2Z9c%2B5Rm2vLty874PLx0ypBNZSxzpatPdfQN6CaPr9gsmN%2FM5TwMSHFmt9oaaXAeUMRRzX4BuME5J%2BPGPB%2Bbqq%2BWAtp9w9Zow6vYglFuJan0L8lxQaHihry9cYFEcFKGFV%2FJqTVyWNe6nNaQPlDe11e9D1QvhNiT9KxLqxe1ZsfaPxhzzrej1Ixepvdue%2BItQ1pNoDpc8c0lXewOsH13LssCnXLl%2F6MYBDS%2F0sogNqtaggDUeUBppiHWtIPZPF1%2Bdk8QDdm%2BzLNanZ89MhnEKhBrO3a6%2F8TxsXEmBKORLiqSKY%2Ba1BIj5lLftg83sgsPlUqTJ%2BLg%3D%3D",
                "project_uuid": "a1503fe0-2885-4d09-8745-cffabdcf82f0"
            },
            {
                "uuid": "2d90592a-0d8d-4bce-894a-6f3da35fd95f",
                "title": "Output-a1503fe0-2885-4d09-8745-cffabdcf82f0.mp4",
                "state": 4,
                "category": 5,
                "created_at": "2024-03-03T18:54:05.668548Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/2d90592a-0d8d-4bce-894a-6f3da35fd95f.mp4?Expires=1709845635&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=VZ%2BTCuHTxJeQD9Kg14%2FRNpc4hNtqWNkQttqg0Sq%2BRtVDy9w8At8wMwB63PxHe7iXQ9mbO%2Fxp6vRyJof%2BssKJAEuuj9ziZVx1vv9X1DR6suphiaqzsnUGq3wbQ2lwQxZtrYNXL6xU1ZC3qg%2F2R4yQ4HpU4UYO7dNPuyloV6TRfbaW92bGCjezqooX0bCxD387CSrJx6wGVPj5V3wZQis9eb8%2BeY0UcKaTZqqzATDqIWEcySAuDVtFG6liFZszjY82k0dPzag%2Bwr3UYa7BSyMJPGAZLSsc1tJ5%2Blma39VxbVLDaq52nRtepuZ87K1qvFkamlzpMsLi28rwBmydXTio0Q%3D%3D",
                "export_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/a0396665-1dba-4ab6-8997-ae982771db26?Expires=1709845636&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=JON8o31vpSKswIHcviQZ%2B1SRbEKpOp3nfv5tIFk0dR9mChCTsXew2vmizHgwX0xYJStRpEeYdZ3F8pir%2Fi40Q4VSnkCz1Hwu1CBuUK2IUk%2BZCG3YYhMAZNBjic3GSINCri9Ao45FJ3EmD3B3u8a9ihGpRUUKRf5ZBDk9YNdpVWXKZyIleunghrfSZnChpnBtSupmcPH1BRj%2FZAwhWOCosEgdqNQFaHrW1yDNPohvWxWEdOciksAhejqR9Vo0YrjGWj6tbh6bpMyvPWN0hCX8GI2njSLwU5rYVxQHCPdE%2B2UBEjUXXYrl9P1dW8F2THjrkdQ4FsSNvwc2zGVUZDEU0A%3D%3D",
                "project_uuid": "a1503fe0-2885-4d09-8745-cffabdcf82f0"
            },
            {
                "uuid": "2f5ea42c-8f1c-421a-b9a3-d4697d1bf367",
                "title": "Output-AudioOnly-5d9303f5-880d-4e7c-964f-d934e13987a5.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-02T11:08:58.891495Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/2f5ea42c-8f1c-421a-b9a3-d4697d1bf367.mp4?Expires=1709845636&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=KZRtWnSS7Hn%2Fe6UBkaC34T5quN9RWetMgENow3aU58yRM76cMve0f%2FJ2chVIsEdz7AxeLnxaHnfMLddXwcQcpqf55R48kqfBQDd37nnXUOszF%2BN0%2BQtnTxa917k3AliJ2rNEnKF6S7G8tDhFfewgbxiXSn7yeEz72rg7smDs8xJ%2F2H2EJe44jq7%2FpTpITri6UhPThbj8KmKAibI0VLxueTNwAnkT9GoS5mRkNGYoh%2FOk2X1U9dElDfIYAh4zNr9UkuKWWDWAG3544%2FLr3n%2F2ooZYuiPE5KcanyRmcsz%2Bp2DuyABXdJMwhBuC3rfj%2BgEFq%2FxyGxWgCsPN%2B%2FLCcgnlVA%3D%3D",
                "export_thumbnail": null,
                "project_uuid": null
            },
            {
                "uuid": "5759032e-5540-46dc-9a34-758cbc491343",
                "title": "Output-AudioOnly-5d9303f5-880d-4e7c-964f-d934e13987a5.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-02T11:07:18.807707Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/5759032e-5540-46dc-9a34-758cbc491343.mp4?Expires=1709845637&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=tDydOCPMsQ4Kti1cKLQKT0TzFkT2nF9Z53xB%2B9kR2OIZwQ4TYG9YnVD3E2SGJAWNsP938jZU7WyTYCkhxGxcRbjImV6cCakB%2FeyCyn6jzeM5YzKftzycOVtzUZpKfjq4qSQyFZrQj5pdgTnx2mVMr4H1aLD3NiKgCbp8DtClw73wQPr18GV0K5lSnYa7BE60Wi5gqTucdpSapSv2isION6sduuNa9w%2BiR3gqsmPjfRekkSovSxYYaxFUfUIBmzX4pdmUXh9PbJ8rBFdoFBzQJiQO2JXR%2B4DOccj7ZrbcDs6kqGJEuD0CogPMTlr33EfL4Lv6%2BgLAZGpIKo75ddWyRw%3D%3D",
                "export_thumbnail": null,
                "project_uuid": null
            },
            {
                "uuid": "229a2ff7-92a0-47d0-8597-f43514f2a8e8",
                "title": "Output-AudioOnly-5d9303f5-880d-4e7c-964f-d934e13987a5.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-01T23:50:27.051105Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/229a2ff7-92a0-47d0-8597-f43514f2a8e8.mp4?Expires=1709845637&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=jLJUe7wbBAXM3szeuhxcoYhshkerjoxX%2BDi4Hl1nhdkHn5SFP4ibH%2Boe7pusnEI%2FRfi2Y8jHl5yEh%2BwPcrTtYlLy6oVCIBdFCd5WJkH3BiYQuznpzpOvQ808qsZd%2BOge%2BQMCwi6bIpo6upDPqomvHuRPLrHtCNkD52lAmAbpfCVXOZ3SzR2f3iOBCjqb76E%2BXmRVKXQL1%2F2RTaXbuxIsayR%2BOd8%2BB5LmjGYk3ndApj%2BXB%2FOE2VjXUBUtOXex3bGeq%2BeC3rR7VQPScjDu1JUDr9Xo9WxqpX%2Fvt5wTn7qZldNUWoyjyN9FJaGScpo20P1xQmEUWZNQAU8NIfn%2FiB1qhg%3D%3D",
                "export_thumbnail": null,
                "project_uuid": null
            },
            {
                "uuid": "b30bcaa2-d68e-458b-a7ba-7df3623e85a6",
                "title": "Output-AudioOnly-5d9303f5-880d-4e7c-964f-d934e13987a5.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-01T23:28:15.388347Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/b30bcaa2-d68e-458b-a7ba-7df3623e85a6.mp4?Expires=1709845637&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=MY%2BfSPwNGlHd9A1K%2BzT%2FjdKPaNlVnfdoqk695jjo5uMnroM6yAcla1BgnF%2BoB8Ir7SYc%2FbA%2BIl9H%2BecL%2BQdcFlrBcZNgO8uiX9HfwSUJBRzto5j5%2FJalVXcHSoVhkDLdI88n0SGoG54I3OTJrfYSPopgPq5hR3gLmsrQtdsGwB1c70F1y0HfsE9b0VeisNAKn%2B7nvzHMTOYfwNUvvvWx096zTzsjKxfGmFKtuQkwcr7jdCRJufaLe%2BFX%2B9Onx5CLHS2DCs5607J%2BStRsDUOzHbJeDY%2FALBzz%2BbRyaRSPzn6AxUQCkmG3CXHeClsd7I67%2BGSi5q%2BuBcKL1bvbGnETmA%3D%3D",
                "export_thumbnail": null,
                "project_uuid": null
            },
            {
                "uuid": "d8d08bbe-c087-45d8-9c47-445e33e80cb4",
                "title": "Output-AudioOnly-5d9303f5-880d-4e7c-964f-d934e13987a5.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-01T23:26:59.493442Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/d8d08bbe-c087-45d8-9c47-445e33e80cb4.mp4?Expires=1709845638&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=FKc76zXEB1oRyCeX%2BOhvITq5c0OdX5rJDShcDK92Rmq%2FSOettwQBoeMqZuk1CXGdpHtIn3FQlTjdxw0OgXeF%2Bn0JfeD0S4QzJCS0y8%2BrgnQPuTF%2FX7v2kIKBT4p98dm2GDhbWZ7e0Kk30x3jyKD23e88IjpaKCvMVZQBDWHUs9NoUF1%2FqiC%2FhRiLPqQlHrS2tvVTCO7LMU%2BglOr31Ly5ZhC8YP4Wj3mbc0%2FwVZ96DHAXdSzRGILGscL7JFmXLk2lDrxjecjBrU1jLqHU1LHO8rbMnJCjnUgeNI3u7EswmI%2FalRD8UDRg%2BIoYYXjVkNrOlwomJyNkhIJP55qgXCz%2FVg%3D%3D",
                "export_thumbnail": null,
                "project_uuid": null
            },
            {
                "uuid": "86e98db7-14f2-47cf-baf0-ac5e4f572e56",
                "title": "Output-AudioOnly-5d9303f5-880d-4e7c-964f-d934e13987a5.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-01T22:12:32.618058Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/86e98db7-14f2-47cf-baf0-ac5e4f572e56.mp4?Expires=1709845638&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=D8Yb4pCtfAQcSHGPjDs64SjlzKB%2Fl7T8wECPznYdszlAWNh3TtwAyImhUcS6S6mgnP22grC1ekMzQU%2BaJiS%2BLJ1PywJrad5Adgx0msDiwP24fbkzXj%2BDRrAqbp70B%2FNeuQximM5ap%2FWjXILyVDB2ISQy2J1aAkZD6cq3%2FIsyfCFQIG%2FJBy7IfRgniQeH0JS7xC4OMt0I9qQzOUR%2BPCV2tqD07A%2FMJ47%2F%2FKttXgewKQp8Q27Wuj5cIHxDgf%2FpdIWlEX9KCZ1Q%2FDs%2Bj7LBsEMc%2FpyiJYF%2BKtmG5WxiRBSasTZGfIXcbQNcJw8aaGOYUjRGfMeStL9iHq%2FrvGzxP%2FJxPw%3D%3D",
                "export_thumbnail": null,
                "project_uuid": null
            },
            {
                "uuid": "ba3708ad-9456-44be-8b47-4e40116a0443",
                "title": "Output-AudioOnly-5d9303f5-880d-4e7c-964f-d934e13987a5.mp4",
                "state": 3,
                "category": 5,
                "created_at": "2024-03-01T21:55:52.633662Z",
                "signed_url": "https://storage.googleapis.com/development-videoo-storage-25/ba3708ad-9456-44be-8b47-4e40116a0443.mp4?Expires=1709845639&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=RwwKn%2B4emCKrNQp9yuEaU5KOxqL2o5xlVegIaLZg4mAEAuZjsO36Zv%2BQA8DhZUs0zxh0gmfpR8w0waFmD%2F8HbMcXkZc%2FTzngNfmovfWtOcB3tsn69R9ZXZFw5AoXd9NGXFtCoYzmDDG1OyeSbjek3CGLYDeHvIGzE7yAH6gwDHz%2BLsE%2FCTgecWGxbwcJ4X8vbE%2FBUrLBEgl4DTrEta%2BvoleILh%2FXFrNkmNpxbueDrFqKkB5eX2bs8HBEv1V5ih86tC0HrCFg3grDxWfpMMvF73GYly1o%2BAR8Rw8Nael56IELwzAqpDkQKLKyN%2FC1syxCQRdiP4J0bpjoDoJi5%2FSuVw%3D%3D",
                "export_thumbnail": null,
                "project_uuid": null
            }
        ]
    }
}

export const projectsData = {
  'localhost': {
        "count": 28,
        "next": "http://localhost:8000/user/projects/?page=2",
        "previous": null,
        "results": [
            {
                "uuid": "ee1dec1a-f558-4630-aec6-942578e15e4c",
                "title": "Project - 6",
                "updated_at": "2024-03-05 20:49:55",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/ee1dec1a-f558-4630-aec6-942578e15e4c?Expires=1709844956&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=IBZLesjT%2F4BfF31D2WbFxD0Yq94m%2FsvCPmu0LMYkDv%2F3%2FcZO9mUazF9w9k5h8p%2BWSupk57uW4mxgC2IlCg%2F9rZAVs3hYJx74UcLh7u0BsRaMXdBv%2Fut56ZELzHorwrnZKVK0vwsLZ50iUIK5gWoAlZUsg7TBZAAaAAZAwukaPtydx93z3hOiCcC%2F66hkYB2RJ7OtK1wxUv52lTPmU8rK%2FJdGc6DBKFZRuQMWFjl5eWZYULbnNSEYIUnPptdFfn%2Bju0jdVABcO%2FdoKFuxKARkLtMC8HjqjhYRXOQVLbKbQCDtEjVAke1ZZTTbHv0GynTi3q2qy5XrHniCmn1p8fkPpA%3D%3D"
            },
            {
                "uuid": "33c1a33d-34cb-4407-843f-26122493844b",
                "title": "Project - 26",
                "updated_at": "2024-03-05 20:48:18",
                "project_thumbnail": null
            },
            {
                "uuid": "673633d2-3db3-4da5-ad75-c375afdcd811",
                "title": "Project - 25",
                "updated_at": "2024-03-05 20:26:17",
                "project_thumbnail": null
            },
            {
                "uuid": "93209d3a-4792-4f64-b969-900bfcad5569",
                "title": "Project - 24",
                "updated_at": "2024-03-05 19:01:59",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/93209d3a-4792-4f64-b969-900bfcad5569?Expires=1709844956&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=qWyfG46KKtE%2BXOTIF5fUEM637YcuuX0aHUGAhq0SCe%2BlyszDL4bnAw8dbsazRYCjydf5cOzQYlxCb7QjZ7I5SYVZNW0aAgcet2a82aabF481oFLbRMifJDaoq0TIWWBIzAJcFRHCZPVbRywUrm4xZE9p1ER6rtrTUA9fUwGLMYdE4UbSMhVH9JZVYs1wjLMSSWcO6lEqgVBBp5PBViLEa4E%2Bd5PUG7RyddJLB%2FqAw9Sajab%2F%2FuIis5Dh8%2BCKlQeyHzobFKMmRGXQGM%2BtYp%2FZHRUvp3fGoqlBgiR4Zz9qNaYsO98JyxdS%2FYxyvL8jx2%2B7xbnOvNuOXnInZlLZz3QTNg%3D%3D"
            },
            {
                "uuid": "71b71b20-b1e3-4972-aef1-2a12456d5a5f",
                "title": "Project - 17",
                "updated_at": "2024-03-04 18:21:50",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/71b71b20-b1e3-4972-aef1-2a12456d5a5f?Expires=1709844956&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=M%2FQbU2SHFhV01gbn5Hd4lrJleAldFeOuemCRZECZh7cx66jbPWitJ40EI%2B%2FwIT9qVTAzwjNJ0nmW%2FHEJGKij23GpTQ8%2BwK1sKjrCH1AT%2BEHwa%2FsKiWz2h6RmPO8%2B9cDNRkllMe6A%2BYMBReugQqZ%2F%2BywyxEGHz6RqBrkcYbPOaLSIAGM6JwqxRvnXZ5soVGsSDmb4UtQ1lhluzzmETdFXknD3KF%2FeU10Q%2BLb14NLgiLv33i9uejtqU%2FhrgnKQ8GbcqEmY6tMSzxBUQ3yKnGUepsoehihg8ZSsiO5qH3cu7D5%2FcTk3KknCMKyEtWMbljRDXYAI6aAAe0oCDCifivy7Bg%3D%3D"
            },
            {
                "uuid": "a1503fe0-2885-4d09-8745-cffabdcf82f0",
                "title": "Project - 23",
                "updated_at": "2024-03-03 23:06:44",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/a1503fe0-2885-4d09-8745-cffabdcf82f0?Expires=1709844956&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=pWynx8GhhFuwgb7U2RFSC9KKC%2BfvRRQSoONV%2BAl%2FigWaYTQ%2BBItyoLMSp7AYwp1T0l37zHJp4osLGMN3jHsmW0tv%2BZ5Ijl2RqD5UW1OX4dGErMv5hkF6IDIXWNXwd1eIAZTW2cBvSbmgqDVEzWOT7yVoyZv83mcQFKimeREm9CI4SvPrypM8f5Qc15pRObC0UsjE3va4kZ4dKyoVnGyC15%2B8t3ru9Xmn9rsNkA9Oa8F%2BagMm3Owl4%2BxLrEDpD0nYoqMQasRZHYNogEKj%2B3FoMS%2FOvId1GcV2W%2FJHA50uGots3nZgObX2JF6xCjw51F6gF8VmgF5Jw8q4BOTaPtZl7w%3D%3D"
            },
            {
                "uuid": "5d9303f5-880d-4e7c-964f-d934e13987a5",
                "title": "Subtitles project for Stelian 1-March 21:57",
                "updated_at": "2024-03-03 18:41:49",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/5d9303f5-880d-4e7c-964f-d934e13987a5?Expires=1709844956&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=wRzpAiOoKxSnfwox7xh9bpAohz4S%2B1QbauFrSmhOq4HxxJhYPHy0nrf%2FXuyPZPnSJt%2F4F9fj12AMbCK6Chhv5w9dRXIwSj9YW0EOOndwBSTM1lGqXiI03ff7%2FOIuRc8COwpgTe8xoSRMhX%2B2f5X9DtKHF8NIlj4wiG2%2F1zsQeFIBQg4ch6BUP4JDblLZhZv7t1h9J8z3ol7ilmJtm7vnXbPJFIYTqPBocIxo6e4CdXOzXaXOnHyFyrLvUglCzi9izaL9nJ5JVJuZMJUgIMWNEy%2FuyknAfAkuceGUbkZq9aFHNi040EkTXsmqc%2B4shgLtzb4szyNs48D40VASuLoMbQ%3D%3D"
            },
            {
                "uuid": "14401b1f-3352-4f72-8f92-6058954951fc",
                "title": "Project - 20",
                "updated_at": "2024-03-01 21:18:21",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/14401b1f-3352-4f72-8f92-6058954951fc?Expires=1709844957&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=JdSN71IIeXitm6PD3SkQuu%2Bav8yBgXkWSU9VcR0zMGkT7QWNcHBImQ4V%2BSFylSu%2FZxsDfU1%2BRkcmQgmCCTaXTMZ07SI%2Fq6nUvN%2FOPHqlA8hM56SPlWU8QdPcD9aNlYxyZOWv759sboJ3krLhB8qsuQiKgiGu%2BbHR59QWQaB1gajZrploQ4b%2FVYTd627BuCY8h0X760XMTikVElU%2BTuarGWoUE6dpOEDQf40GECM6R0oKe4ctrCJWqHAJuMN%2BEAdUmXc9S83aMV%2BBHKxOrHcL%2BTLNbNUThX0GfVFJszCyoF6pPbf%2FMBDiW1qJdL9PwZ0JRstdEtD5Bl2T5TqyxW%2FY6g%3D%3D"
            },
            {
                "uuid": "a4c448fe-7fec-49a3-8f70-6c6bdcb2763e",
                "title": "Stelian Template For Subtiles",
                "updated_at": "2024-03-01 19:01:57",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/a4c448fe-7fec-49a3-8f70-6c6bdcb2763e?Expires=1709844957&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=ackB2w01%2Fx2AQMvUco%2Fgdva8xs1ePVocO8kzHIjhN9nduJ6kBW38Y4D3Z%2BwX312NmhqeiqyuZurs0M53Cq2o0%2Fa2jhZmsfXMDS71dkJPNFxLcNUw4ayXyDPNV89A%2BA74agLdQVUNhhv2W9FXpRCCvlLASN2bMtoWPLbruPGz3uoe44oJXQjHf%2FQH0MZW408gk2LLVcG%2BTmfTfHh6tL%2FmhsP5sVmgJuhXuPre93LVF1b4bFS7Wt9SnneK%2BsaYIpUa88i5aaBMLsk7e%2FtXcmAGSeqi2H8lePQaflJNOkFtODEgkRvAQviGfpR%2Bwm%2FiypEefl9Sh3HwO7DdDCu9TQpBig%3D%3D"
            },
            {
                "uuid": "def7754f-33ed-4c58-b541-802141bfbc19",
                "title": "Project - 19",
                "updated_at": "2024-02-29 17:34:59",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/def7754f-33ed-4c58-b541-802141bfbc19?Expires=1709844957&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=e32KKeDA5ntx4bToxPt5wrVN8ng9tasblXp5FP%2F9Q5JMs5mmHfhZjT%2F67ukI04Fqz6VSuRv08A3nZLdmweLGqEqJe4TYjP%2BSM6SeDeHfZOimEnMzQuhDTIzOaVHhDD4rlcji%2B0lpZQl8OcdUpxvbq%2BTN%2B86HNbVViyqJd6X98wry3mlG4J9OnZjzTnMIVUAJimwLtJu96t%2FSldRtijzk0kEYPV1Fa%2F%2BF1299JZ6KTkSdAhsCvoJj1CNikhxOpVHf7EcJNAOjPL6%2BwODyZmnaxN45KtBkp8Y%2BU8ROdLHntqTNcI%2FsiMe811%2FiEN9LBrpUPzRamzqnAeycYni5c7ImMg%3D%3D"
            },
            {
                "uuid": "18d219d5-2072-4685-828b-14074a43e1a6",
                "title": "Project - 18",
                "updated_at": "2024-02-28 16:14:20",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/18d219d5-2072-4685-828b-14074a43e1a6?Expires=1709844957&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=AQXqFRkI%2BVi0bEucn8pTl2%2B5dI1BKPHN4NCtwarB4AMPJvrKMNlGqkqW1PcUK0qq1igB9K5b6623kYrYHH3NigF68%2FzV49Mtgw9DWnMOGXA97TVRz86eWq%2BYa7uj2gULmAfpiODhbb6UMtuUo9h3QKD%2BfjX1rCrgnciG36FUWBRo5nG3NeFQJBycvvi31Qdi9hDcZlZpm2D2Lhsb3G7aZy5VMJ1JgEzfwhZhI4kT0GmD4IhX8ErLax%2FAaRMdMnQUjgnye0hT523hm%2BFNM%2BtGhMcI%2BGin1PJTDRr6L%2BAzPl%2FlmZ%2FJXrhrGCaWGSuOsGKsywO2HrmCWULq%2F6vj%2FfBrJA%3D%3D"
            },
            {
                "uuid": "98ed8cf2-4589-445f-bed1-ca3732db687d",
                "title": "Project - 16",
                "updated_at": "2024-02-26 23:30:33",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/98ed8cf2-4589-445f-bed1-ca3732db687d?Expires=1709844957&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=daRMdTW%2BZKOtQJ4TbFaxgpfd7ko2RngbXe9I6wxLvgBus66GTGYcoxTk1%2B0adeO6NUxn9FfQpU90NjKL1BhtapGa93mrMGqvhvJRx1uwglMJAfTdoN4CCVIGrPFNOWJvHJeOd2r9WTwb%2BnNuH5bJbayMcdvpAw1fCxtMjSDVCb4gPdpGCn3Nwd3ZZcBKtybjpvbuVfTxsVycbay7VGHjwUGekkLWPVtdZUosW5h78kQ1SebWgDJAEXn2RbfAscjg9ZqVXWneUOQJm6pCWt8l8OB0lStK4RyrizfeVgE0cK%2B8%2B3%2B9lJgW4a4kcM5SGDVYiSPB5drPeaPNKJt8oz8rzg%3D%3D"
            },
            {
                "uuid": "568bfda9-f635-476c-adbf-806e0a9b2ac6",
                "title": "Project - 15",
                "updated_at": "2024-02-23 20:52:19",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/568bfda9-f635-476c-adbf-806e0a9b2ac6?Expires=1709844957&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=fYOT9Pzk5dIfkKj6DfPfKJwnhc9WSwozaf7KLh3B2Br5n4EWSJx%2FtdmK0tDcoV1lbXIp2URV6icUMhS%2Brc8%2FJt%2FqRIZ6znvKesRZwyMB12IKU83KOFsEGQyLwJ9dra0fO2gwk%2B%2BbcFj3C%2Bg7%2BFiba%2B4%2B1kMCDebO1GirAoQLOeJGhfd%2BgLKNHtzGbR6tFcWXUNb2t5thmKFglbQkitgVF2mi5idNa0Pr6qzsrIBUZnt2tiww9kbXRs%2FbAdxt4Jw4r9CFRGUXocrOzcPi6cT9Xm1aTyWbxuLn4%2FW2gELRBOsBKJ6Z6biXE%2ByqGPbsh7cVETSFbg33j3HyBJi%2Fv6VLyg%3D%3D"
            },
            {
                "uuid": "502692d3-3b7d-4327-8a96-0f568ed06a0d",
                "title": "Project - 14",
                "updated_at": "2024-02-23 19:08:03",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/502692d3-3b7d-4327-8a96-0f568ed06a0d?Expires=1709844958&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=aUIIGcj5OMnO1U2n16fHsS5UTqDNOcaeOv2u8S1%2FJd7EtQHf39%2FKoZtCMSaZI%2FFL29%2FjYZNuSHpwW%2BTh6jvgDvkMGtwiYwQ9JWK%2F8FrTe9k2oEFln7emsvtMizccr1I%2BORn8Zdv5NrekL7pRy5E3Qxl5Dwy1qf6VM2YcxQCyF%2FOcRM1oImZEqziOpuwHw1Rqi4tUvYgTRHl5%2F0KftM1ZdkW98OBZvTdFXzBELTyhKTcBDrOo9%2FSZgr%2FJVZDDD99RZW05gneA0s4WxnyyipZnDMsQoc%2BeCSjWopYm0krFDQjcwdundw3oAtGuBi9ob75tEigZ%2B6laMcvv%2BdRpCKR8MQ%3D%3D"
            },
            {
                "uuid": "cef9f55f-2b54-4124-b3a5-8201dffd4390",
                "title": "Project - 13",
                "updated_at": "2024-02-23 18:56:55",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/cef9f55f-2b54-4124-b3a5-8201dffd4390?Expires=1709844958&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=i6biCl6iyRp4hxvmYN%2BX54ARcc9QogM9NUOHrpenTZ7A6yFqjiqo%2FFppOOTJbIKFrOrw9ZgEflp7gUzvBWA2CKHYPrE%2FHw2KWe9dVlCmgQxk%2BwPAeOtxlxRHp7GjdsHycCoLrtconLyblHwvCXBYuHTaMKloMBeSjusVq5dKF9knAFnIC2VDZFC%2Fv69xwL9NbuP8kqNhzaF%2FZtne1sGD2ORt8o3Z%2B14gpCaVdnzFEyU8OpyK9soWqTXaLvKmOCRUkcFZjn8wts5FjON6Cz%2BZ0QooRnhJ9SfLJ4Fy%2F9qBsWRLOcDQVTy%2F0%2FgOukJEyVPITVMDdZO79PBjhxJT7svpmQ%3D%3D"
            },
            {
                "uuid": "c7e97c44-03de-421f-a574-9a8e24fc03b2",
                "title": "Project - 10",
                "updated_at": "2024-02-23 15:51:27",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/c7e97c44-03de-421f-a574-9a8e24fc03b2?Expires=1709844958&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=tqRyKdEnWVO7f7TqG0CPvQYWrEJ0rIoTCIfVixzZFrCLmheUDOkoKCBaDVMoaa4b9ObzssDuVbjBLHId1O3%2B%2Bc59zflNpm5KNXHgS9Kox1EP5kv5eYQFZce5HPubkCgwrGM%2BsbSd4en0h3K0goKdK1%2Fec0%2BwgL%2BW4767k4FPNZm1uGEfH1hHn9FeE7HulF0PGu1LK7PhutPU%2FbY1zLpzgk4Ee3U3wg6uBGRhec6HbRLUCceZR3MEJFrXOGvsfIEKfHvPqrCIKCBIr4WAwLLmAZkEtOzTMGIyvZ9N5J7RQgil2gLg3zsP9f7V0g3Csaj7Hl1b9oKU6gg5TOg3UKyw4Q%3D%3D"
            },
            {
                "uuid": "52d543c7-0e2b-40ee-90d2-993d6c429fc5",
                "title": "Project - 12",
                "updated_at": "2024-02-22 22:56:56",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/52d543c7-0e2b-40ee-90d2-993d6c429fc5?Expires=1709844958&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=bHakvTtMt%2F3mfKV9wBY7T3%2FqqENx%2FzgLkLATn9mRtj9lO1UnE6u7%2FfPezPK9t85c%2FzfH4m2AbmXbGCakVYI8ep%2BfJxeqzIlsVR9fxRq3yKPaw9J5j8nYy7KDJEqKoeTvx4xkG3VRVKb%2FGXmqPkHpzvp9fNbWseZZBePwtDIKBzCXQKRVeYBGb1xyPQ8uLN1Al7R6lZ7TEqgBu0ElPZ0G%2FLcbOGkqPN0WV%2FL%2Fh8DiXFb4O9j8xMCYh2avqzlgxC4Wr7ogURgHLoEQ3rpvWqe6F6aGCbtibSZn%2Fhom4UY9VAX3eWZNP2XU7qS5xAuPDed4keg2yOOzv0iExHylSv2COA%3D%3D"
            },
            {
                "uuid": "3ca6fe56-5d87-4c21-9e29-2150e7b93951",
                "title": "Project - 11",
                "updated_at": "2024-02-22 22:05:25",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/3ca6fe56-5d87-4c21-9e29-2150e7b93951?Expires=1709844958&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=kre%2FITNpQ4CFQdNHST%2FLvGLWGz8J5SDH6x8gZWe0s42ZXgcuftCDXtyxmbjl2UvCWI7Xc8xoTNqsIl6XRWQFcTZaEzgjqBHLa4U9%2FOt19qBa7yMWVjpbfhgNISwJBiE3EgAdKldeij1Y5p%2B3cd2KvuyPkGE8eiOitYMcpwb6sb48d3CvjZlGt2IMLJntqBBiwXz%2BpNrASzd4IkkpzeQ4GAWcaTWRbilRYjxGxh0c5%2FOGcj3%2BJqQHsx6fP2Yeprv%2FVBo9tImvjO4gpQYl45gw1Kaxkh94APaT%2FuDtRWgcMsiy4oE0NdRpbSSCdJBmlddHLWk1WmUfB4i1b5YISX8wKA%3D%3D"
            },
            {
                "uuid": "a50c6dd9-1a27-40cd-b299-b26cfafab02b",
                "title": "Project - 9",
                "updated_at": "2024-02-22 20:04:50",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/a50c6dd9-1a27-40cd-b299-b26cfafab02b?Expires=1709844958&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=Q7lfrq%2FObHLqdL%2Fx7Dg8Gn2U3zG7iG5LcquNLAvX7S1gflOCdJ9p%2B50kCJNZ%2F2XIEDg6MC4%2BSfn7prQu5llDQpxRszrgl%2F1AfISi8As1V0rKwz1L5TEi8pa5FPX2mZ6Qj1skwBDDFA52xxZ6g0%2BX5JU7F6r1FShz6iiCiAwmrv3KscW7ZEX4nKJMNhtN1f9wJHLb%2BqTE578nw27E%2BM5r34V3NQw%2Bi3sNzaW6%2B%2FC8tumzB5ZNHEdmIs4FM0KkHvWEcBwrKW8DJGlUCResa0%2BLdDjl4vAuLMNb62Y9Y6Us9I2z%2FGozJ9grJlsBMofsBBnpmj8exWwQqMSXjjGnQjcqrw%3D%3D"
            },
            {
                "uuid": "6a698840-dd98-4cb5-bd13-df38e741b35a",
                "title": "Project - 8",
                "updated_at": "2024-02-22 17:09:11",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/6a698840-dd98-4cb5-bd13-df38e741b35a?Expires=1709844958&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=vJ%2B5BRYcHYOvlX0T3tR6DSvH4tbjBPWqFPh3n7Ov1inKGXroNh%2BbPj%2Bajbq8SWrcHtvSs8eVurNS4aTYTebXyXlQbTCdAPNYDM3FRsjdbzA96zgjlcxBV3su1dFk2LJh4jXGGzdcwkmTdZ6WnRnqy1e2%2FYjmyYgPgbaCgFeTm6ae2E0YNlfI5sieXlhBs51n7Dr9NQ0L8yjcg7%2F39lF4bhkZvxRmSLupxYi54tskgdgijvjqp9muEVE95X00BzyieyJ6Fu3UVPWq8eSstlwwzZqhfm375R5ZGRLxqSTcShPmwt66TsfKqWnmOPMj9%2F25steAnqewbyQKBT2hR%2FK0%2BQ%3D%3D"
            }
        ]
    }
}

export const templatesData = {
  'localhost': {
        "count": 20,
        "next": "http://localhost:8000/templateproject/?page=2&visibility=0",
        "previous": null,
        "results": [
            {
                "uuid": "7b8d9245-756a-48b8-8b17-0b383867623c",
                "category": 1,
                "size": 1,
                "project_uuid": "ffe705de-b0aa-468e-aff3-709425d9af1a",
                "title": "Counter",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2023-10-26 02:53:55",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/ffe705de-b0aa-468e-aff3-709425d9af1a?Expires=1709846207&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=oK2LbzQGz9%2FXDMhpMrZ7SyGtOJ5TYDr693NpJo72QedY0jlTd5s4JFAPVYIMAqfo86%2FWH6TNaFmfRfuFHDQZDB0G6tQxpWJ4wKdTYyn8J%2BIATcqIDptH%2FbGI28m8T2QUwn3YgHtN8Bune%2BJelo94%2Ft805qRqsC4fImSQXsUiblkkHwo5nlGZvLPEefPNQfKQ83uA7g22qu37yUzDvdyh7FC77NiMIxZrlxJ2fvugtKxeUh4rPgtN6A0hQC5F6HTlQczI6FaJ5Wshlkdex5rAQjUathTtxMhVem%2F5jHzaDKfL6lrf75Pil0WOrRdeHn4KCswhiwUgLaKDL%2BNttddGiw%3D%3D"
            },
            {
                "uuid": "1a44f7fc-5783-41ba-9734-c277c5d4ef94",
                "category": 1,
                "size": 1,
                "project_uuid": "d4cff73b-e382-4196-9109-182d5e869714",
                "title": "Spanish",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2023-10-26 02:53:53",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/d4cff73b-e382-4196-9109-182d5e869714?Expires=1709846207&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=QAIbIwe9CFG2nP7otDrb3psfVgnCS4NEwuXzokClF6mZl5gzd9PWjKqTKqSOG1uEnqiQly6w2lQXEMsqaWlESyOvb03woqCZSxnMYe%2F4St6kZwxbBuggHEJymkHpFQm594W1ZYcjtCaOY3WshWFLi1hYVGo3wFX5K1dmPVxxZmggoCl5a%2Fh%2FUx%2Bax4oD1iqE%2FpVUIxY35thp9d4Y0c8QT2ofFoJXuwgRMrYXHBx%2FU4sTHgdcdZH9ZkQxhjf5M4TK%2FXSxE28m%2BYJciITycu8hxoJMdcvl09HkCFPZL7JU5%2FrgJqqom3NWxRnkV%2F4Pop93RC%2FDM5j3URvhaejJj1t3aw%3D%3D"
            },
            {
                "uuid": "a36b575f-52ee-4e46-ab1e-36cf2c196280",
                "category": 7,
                "size": 1,
                "project_uuid": "b46c74fb-73ad-4c11-ab83-480ad0307399",
                "title": "Butterfly Green Screen",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2024-01-24 13:00:07",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/b46c74fb-73ad-4c11-ab83-480ad0307399?Expires=1709846207&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=m1%2FNDjsWvsE1gezDIkIQEUhm0joE9gnFsa9SfBWWi1IGDT1HkWHjrAiZ1gq0ZjJQuBjbU%2FPBYYxlY%2FIHGzE2Dl392azIRqzzuBMbopHEornAypfOrYZqNGySJSwhp1qVvBsDYkX6jD8f1BD82Sa%2BFktqXwjAvIld2WnsW3YmbTUEbi44nFp2tR1VXIcvcH71noh5xVpuz%2BP3kffQgApH8tqPK9O19t1AygWHXjQehgUITJq4JlLQcMCZvMJku2ByeNKU2BQvgfp581mHve8Kvv1nymSFmINfqnmWBNkf0RhnbgvQufWG3MoxE3z%2FQv5gddBeogmOP4DJj1jCBd3K1Q%3D%3D"
            },
            {
                "uuid": "24095ba4-f870-428d-b72e-a021fc6ecf92",
                "category": 5,
                "size": 1,
                "project_uuid": "6a82290f-ee5f-4df3-94e5-607ac5eea990",
                "title": "Stock videos with transitions and subtitles",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2023-11-30 14:35:30",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/6a82290f-ee5f-4df3-94e5-607ac5eea990?Expires=1709846207&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=cYLSJMMOK0d%2FX%2BqDMPrGX%2FWtcpFp3%2BQHbAeQ4trdz%2Fp59%2Bilwt4eyM8r0%2FFGNPNXaJiYyGE0kclsG5UW22Yj2%2F7nexm3XZusHzg5VK769NuThdklUPFf63UyugcRVmi%2B84ozxlTXj%2BGp6P7YnPd0PS%2F6xSk7DCpt%2Fb%2B2qDQEsrPZSdB4DPAKaroVkQOLogC%2BNSAxuKhikB4iMM3d6GC6Hweyc4ndpG4jmKFhvDfrtoYpbW6MeB8Kgm3xdlwrEXBLOk5wWTJjBa30DXnTySu3%2FGPxS9lVUm62P0Nrh8R7BWB3Wsa%2FueMtk0Vfj3HNGbilMZz2mA%2BQH%2FH7SWjSdjPwlg%3D%3D"
            },
            {
                "uuid": "f6595fca-deb5-448f-846e-b22fd2d53f06",
                "category": 5,
                "size": 1,
                "project_uuid": "295d5318-b397-4894-b6de-db75d602d06e",
                "title": "Kiril Alphabet Issue",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2024-02-07 09:22:04",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/295d5318-b397-4894-b6de-db75d602d06e?Expires=1709846208&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=VkRNW677GRoZcWZmdubXcMCf88r8y4p4Myzp1xRHFRPw4%2B83lku6SY3mO736XDLyJyDNc3p42ikkArWM2ZkmuDsqN6MnL3f1mTYJgwGVXtx73uberlUHrzHFtk3ItUTdia72LRapu%2BR6JaU%2F1Dp7hTI%2BWYLsbsUPYlIqawwXRyftQHHPOxZuE0BSuZVCI3bSfb9F9m2bxn3BWiqv1WMqppGm1echLOptXxK%2FS1q91t6vucl41YQv1Lk%2FWRKfRDUIk0Qgi5K30Br7MEl375J0FATftyZVd1a4THd8%2FyOLCY%2BW1ltTc%2FoiItv0Aq0HrCgwP76Pd%2Fb6n2nQ3cH0Ok95yQ%3D%3D"
            },
            {
                "uuid": "ed28372a-0af0-4d4e-85de-a63a55df021b",
                "category": 1,
                "size": 1,
                "project_uuid": "3b92aac3-3b81-4a96-af33-b150c4e99f03",
                "title": "India Dance (no audio)",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2023-10-26 02:53:51",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/3b92aac3-3b81-4a96-af33-b150c4e99f03?Expires=1709846208&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=K2p3oBp1HfjnfS%2BKNzAegSQ%2Fh04jUPo%2FFUDT5%2BYDSgYWMPdO0tp522mDCSK9VaG9U%2BCME5to30ad1t2uHK7MnAfSCZZaLaklP49Kwo1CRr6%2BqJU6btD%2Fs6xlgMiUE6pDKIQC0qYoLRZwevrIuwDrcXsLtJSv6Os6d6jTuTXNZ99ahyRT%2BaUrl7pwOkR3E1gjl2A4HDO6Gi0L2fHUOftIaRgX6UEQAEGM%2BCwLMo6GNuGGVBObTgo4%2BJ%2FO3hsDz7Jn%2ByDOPF8%2Fn3B5Z16KWP1jZp1h17q5fJ3BftjOnLwDt743pDoH0M0EZ6emLwAhQieLdth%2BYdshDNK1JEPMwzTuDw%3D%3D"
            },
            {
                "uuid": "3f271a3a-2ed2-4141-a413-ffac66de89ab",
                "category": 1,
                "size": 1,
                "project_uuid": "e0bb37d2-3290-4c8e-91b1-b94c7d3b4a32",
                "title": "French",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2023-10-26 02:53:55",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/e0bb37d2-3290-4c8e-91b1-b94c7d3b4a32?Expires=1709846208&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=Utr%2FHxUwbKUKxG6yEESYSxmzQofvroWq5hfWSYi5hpGWvnb%2Bdga0JlavGBDAYPApnuiX9PUx61LJafCLSsPPRPBU6gsNIEY2Xfyl6ezQOnhVaeyUBFpoXG60l3q1dRpmooBx1xoRQrHIvhBOwQJggNYJJ%2F%2FHZsefMp0Yi3ngC9Bw7DQAbwJilhHLS7GSUg42SP8PCxnQgBy6F3ef9TZNJFpgl0BMsB0lHcb3eYT%2BMu%2FVC9iYeD%2BqA9NKD4Posu7ykZDIICuEb%2BcuQb%2F1OweiYh0F3JwiSvtzoQZ78LB66lY0FSO47EVi22KQ1PV2mipO4m28Umy0M2TPwEdp4S34xg%3D%3D"
            },
            {
                "uuid": "16c5dd32-ea8e-4de2-a3b9-f5abdab731d0",
                "category": 1,
                "size": 1,
                "project_uuid": "688ff1c4-c756-4e3f-9f81-d60ef7208fcf",
                "title": "Multiple Videos",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2023-10-26 02:53:54",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/688ff1c4-c756-4e3f-9f81-d60ef7208fcf?Expires=1709846209&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=Ssb3LWExEoLE9v5G0NWykD%2FWhyPIfPY9lEQ515CDz1vOLGxZHPBQbp50IuvdfQ8m%2Fku1Sona%2FrOPaQMHyS%2BRmxn%2Bo5wbsZhCYMtPccAA6pvLgtETYKsOLdW%2BZ4vL0BEoX0UgJbY5K1ErCN7q4lddj9tQkoq%2F1LkSx10yeR2q2iKRlEGweDYC7FJ86%2BaSIir5pLt7HGfkP12aAdn%2BbDCpXDspxbQkADpRfvnSHBAaTJcL34VbP%2FrvSkoHgoGa9OXQRLzNaXhRkZRblPzYq2Hw6qaw0P5vBeEk3FZH%2Bv%2FxIkROeJZxYMFA7A%2FymuvR2%2BglOI8d8yALnd8iSw1MOxbiUg%3D%3D"
            },
            {
                "uuid": "84a08fbf-105f-47a1-9fbc-5e6ed3553f25",
                "category": 1,
                "size": 1,
                "project_uuid": "f2e58524-d152-4640-ab58-72cb18710a02",
                "title": "Buffering Test B",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2023-10-26 02:54:00",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/f2e58524-d152-4640-ab58-72cb18710a02?Expires=1709846209&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=XMgm8WyIo7R64SPIL%2BFN8OO%2BEYpCmsc6OnxNzL2g4Kpz%2BU1H3Pzg63npEGrL5QCX%2FUg0enLwoDGTamRMMdmAxf8SLagsh32TRP2zNVBsJgYOyFBTLbwmKAS8mqh8K6PceAwVln%2FTe2pnVlo%2F2%2Bg6qm8E9kW3huieh7yY2bZmKSSJCwyflE7N%2BLw%2FTog4IgporVg%2FcAG3oywmkv7xHJwxPCLqnn2C8on28XJo7040j90ZPBPQhfr8RWzpjhQYHk1bFE%2FzD%2BfLq4YeYULt6T3z6BkdDAFcVbl489KWemXm3m5ndsDWSNU5G3qEO04NEVdS712kPV2YqUSNmQAdBqpLZg%3D%3D"
            },
            {
                "uuid": "9ae28905-e789-4b3d-bd4f-3c882e5dea84",
                "category": 2,
                "size": 1,
                "project_uuid": "668d7d7f-9e43-4c23-90b2-8190f528a31a",
                "title": "Miss Panell",
                "get_visibility_display": "PUBLIC",
                "updated_at": "2023-10-26 02:53:52",
                "project_thumbnail": "https://storage.googleapis.com/development-videoo-storage-25/668d7d7f-9e43-4c23-90b2-8190f528a31a?Expires=1709846209&GoogleAccessId=videoo25%40videoo25.iam.gserviceaccount.com&Signature=u9yIi%2BkY0RKDoOWPrun8Fup%2BPAroKV0xo5yN%2F8GyGUX3iNfhb4N%2BmzNMbzDrHwRQSaUNdm%2F%2BYGeujIYZoXQnyqmXwunv4CEkZ95FEsha%2FJg76IEFh4YCPT9aT9AZrR%2FDBJbefsh1yYzypcspnPxHnVcLJ6NSSJnKwRqjAh6Ex6S%2BCegJdyEa3OwZVUqIi0Y0IiBjptO4ynyi9TqcOtZ4z1S97%2BK15YaFuEJAGwu5C7qMwiklkeweXFdixw%2BGGe3oZwfX9r5As5c0Tq03jBTY44tUxIvWXYSlivkcDUngjj3uu2FoLK1H2rMW3qEv9T2niy8KjpMMeKtNAGWh35ZK%2BA%3D%3D"
            }
        ]
    }
}

export const DefaultProjectJSONdata = {
  "fps": 24,
  "text": {},
  "asset": {},
  "title": "Your Video - 2f9c8c6fd3e94f0da8755d81e879b6d9",
  "width": 1920,
  "height": 1080,
  "images": {},
  "videos": {},
  "audios": {},
  "effects": {},
  "filters": { "preset": "" },
  "version": 1.0,
  "bg_color": "00000000",
  "duration": 0,
  "elements": {},
  "subtitles": {},
  "user_uuid": null,
  "audio_only": false,
  "created_at": "2022-11-30 18:16:35",
  "updated_at": "2022-11-30 18:16:39",
  "subtitle_url": null,
  "thumbnail_url": {},
  "workspace_uuid": '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9',
  "sequence": 0,
  "templateproject": {}
}

export const ProjectJsonData = {
  "2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9": {
    "fps": 24,
    "text": {},
    "asset": {
      "9183d546-70e0-4860-928d-b45ad4d0c890": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "9183d546-70e0-4860-928d-b45ad4d0c890",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/9183d546-70e0-4860-928d-b45ad4d0c890?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=N0GYJe097go2sXec5DwOkfoDd17aa1Ahi3Rwj94Qgwl4MtaOSWMaYpwZk2od8XL45BMUtnysTBMCso1fIK6%2BPUAqFrAoLcl%2FnO8HzyqPB1r2vKuc43%2BHHXQWjzYN41R3mEyvdTF9sYyuktDckSEjEhl7exlk7eD%2B%2FBjl8LlqV0C8SyO05P9j00pDJB2IPdTfLj%2Bz1uYbwAGs4m5SmuflpBS8q0fLIuBbXwXfHFGq0qCGEcZ5pxEjndAQLRqQv%2FXNvgmwAZw9iFqEC%2BuunjRvAYYh%2FHxZhTVCwiR67iwFpLQf%2Fr%2FVDv0dp8DWL3VqtJRCBWSN5ByyJhav0l2BCBwHSQ%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/9183d546-70e0-4860-928d-b45ad4d0c890?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=N0GYJe097go2sXec5DwOkfoDd17aa1Ahi3Rwj94Qgwl4MtaOSWMaYpwZk2od8XL45BMUtnysTBMCso1fIK6%2BPUAqFrAoLcl%2FnO8HzyqPB1r2vKuc43%2BHHXQWjzYN41R3mEyvdTF9sYyuktDckSEjEhl7exlk7eD%2B%2FBjl8LlqV0C8SyO05P9j00pDJB2IPdTfLj%2Bz1uYbwAGs4m5SmuflpBS8q0fLIuBbXwXfHFGq0qCGEcZ5pxEjndAQLRqQv%2FXNvgmwAZw9iFqEC%2BuunjRvAYYh%2FHxZhTVCwiR67iwFpLQf%2Fr%2FVDv0dp8DWL3VqtJRCBWSN5ByyJhav0l2BCBwHSQ%3D%3D",
        "type": 1,
        "thumbnail": {
          "a405fcdc-940e-4bb8-9f0f-b76234cb4b62": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/a405fcdc-940e-4bb8-9f0f-b76234cb4b62?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=FJk%2Fm7L8L2A1gWatdvWcqgmb1%2FFQJRh8xTh7PgGV8Yhat%2Bg4DE2Wo6yYs%2BUa3oM45%2BKI%2BfOy2xyCsukitAobeb47zortlJObGpThX59U5cjgy9Bx506lR8T1aH6yO9I%2FLsDvz%2FafENSs2BZMV7mbMeOYIgPAGEss18O1CTsmz0BcUSnsDaMYoGM4N9HhN7grbay1LrfQLeqt5tHJ4Jm7VVRj6QNfS%2FjQTooICX3VwYoEVsxZCQJqSyIwWbRe9ALGh3RBja2uoa%2FJtLR%2Fo42uGEcSoasORg95wlprtahIq1ZNKy%2BqsjYevwGZLpWrlfu6eGkoaGTGG7J7yRUlc1dFLA%3D%3D",
            "timestamp": 0.1
          },
          "fabddc5e-1dee-444d-880d-69f6a5c84961": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/fabddc5e-1dee-444d-880d-69f6a5c84961?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=bgn91N2qeEWLDuVpyHMHzrooUOVpNYvAwq2UnarfGMTKM0haoKCbff5LuC86wv2FQohEwjj%2BNSAoCBDG%2BofFidzktFWN6SHHH4%2BeRCo%2BjqQ92%2Fi0Goi0w%2BO5WF3diKwrirv%2BIT9lznyHSv%2BxPPY%2BAQ%2BkIZ148l%2B2bq%2FwFt0QnaKb0dI4UlpO2WTXEnCWAu%2FMyOSQBj467kZdPu4NcyHUQFkscMLn6oQP3nC5U3xvE%2BvSMzwYopfWd43AY5w9WWvHJWOnUsS4pcxOCUlc0rTNBruQ16I9qP1UuG%2F7B%2BUFh0IWu40Ahef66FIRSdhx5cwy5EJlJuRWs3HEOBF29QCtZA%3D%3D",
            "timestamp": 8.4
          },
          "cc90b732-0cc5-4799-b68e-c695a19460fe": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/cc90b732-0cc5-4799-b68e-c695a19460fe?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=N5jQ4QD1BlfQVkQVfUmi6mf8lfKnCNlMq2KE6vCvAOJVJT4biqdeLbGHqL2vL8Z8q7msfH9yv%2BWjm7I0v70nWFidBlGQaBGLVazr2WPV1VOmLicn4c5BuWahlCU8EeJsj4Nn%2B12yhEPCqFhWudksMPU7NabypVMJiWnds5UQmL6Bow19Qq1sr4rjQe85l0DYLXg3zfF1K70ITswqO8sBnNkg7Kj0UfFArjv7gfl1dErufPV7RDUdAP0YVp8%2FquBF7PbQ9QcejTwBPNKsNzSLodOyh%2BXv3v0dUy%2B0%2FSg2RA%2FS%2FAgN23H%2FevYINRQL%2BJuWkMifENdk8j1SH0YWcMV1Aw%3D%3D",
            "timestamp": 16.7
          },
          "1cab354d-3b07-435d-a7c9-d83a71023323": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/1cab354d-3b07-435d-a7c9-d83a71023323?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=fYXnQopNCpfsJhsTiXEc6fKGhp4zFYe6VESz6Y7wzUcTtfctekfnlN4xQSYQn16aBA0%2F4NT1YiycjX3kE429HIjyKugOh8Mmls%2FdzTEHhh6QlrUrsGEwJBoz51eaq%2Bb2Q1O4%2FhfPYoab1z%2BLUzCOiiviOq5OjjrNgrYYbNr4ty9siMAMsxwgcDCs6THtDHmbfuL4i5yRWHKcrH1XPbYKfhwr8%2F0j1DkPJq6xS%2F3t8rthlMaXFB6c3BDHcsZoCNcSslnMOnPzFpGK%2Brycwyb9e0LRzVlOT5fdK5YLOy0CpNLObUku5tHgLi%2FKhog8510%2BzQteyc7FteuwVukuY7xO9g%3D%3D",
            "timestamp": 25.1
          },
          "78437fc0-05a0-43f8-8234-8402ce8ae786": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/78437fc0-05a0-43f8-8234-8402ce8ae786?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ibn7SnL9qVeOtuLrtNoYuD%2Bdm5zbcoyeZEN8Ov4JkrLhOP9DzUNnIOY8e69mh2b%2FUXqSoCfGb8T4OYbHyllgF1CHTYJxQWBuKx7bZDWR7zIKiMdZveL%2Fe7353Yc6siF74wImdTuRS865O2Tp1mFmL4f3sG5S3yUn2%2BeanmtP2E%2FUIQh5tyxHqU8h7tyWLCGwGS9zMUSZPFd%2BZA5fmnvGAjLoGR9VjGxNz%2FODcFTd8q26JHm8IkuCTv3Kw%2BU%2BVfsKC8Irdw%2FYtU8a7bGrQgA2XEq%2Bl5mLnMRvG1tWIEtEjkGEdep%2FcNrVW5dPOdxCxpuDPdlj2Ef65uN5UKpPeTUz%2FQ%3D%3D",
            "timestamp": 33.4
          }
        }
      },
      "638c0c7e-9c2a-4197-954a-b3fc061cd9c7": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "638c0c7e-9c2a-4197-954a-b3fc061cd9c7",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/638c0c7e-9c2a-4197-954a-b3fc061cd9c7?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ZzkK6qDGICspK5QYWuUmQp0Ow28lbk6oYxEcL1Qe%2BHrSHoWbzSSnNvhc82n5ippD51gfVAEpnL%2BNGNDkdmeVfbhPzMX14CAAEZDFfBWSaP0thHUObQ79UfRyG3yeqgkV17VRRT%2F3vsY%2FeDRAImkOrAfe3jGEiyXjk%2BNxh6U30BzRpaW4zhJtWgIThYMU19AnJjZHgUGpHdQYl3AtJAM2zq6RIKUi6QrqXbJ%2FuDmQw2jdhUmicdWWtp0Ks6aiUa3WFRNYn1ySUGAeGt%2B%2BDGAIek6TeBh%2FdAMI27bCJkRmyEwiRG6pmYvnMPbbi%2FNCg5JjcmxiT9pO8m8n21K6Aw2Dew%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/638c0c7e-9c2a-4197-954a-b3fc061cd9c7?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ZzkK6qDGICspK5QYWuUmQp0Ow28lbk6oYxEcL1Qe%2BHrSHoWbzSSnNvhc82n5ippD51gfVAEpnL%2BNGNDkdmeVfbhPzMX14CAAEZDFfBWSaP0thHUObQ79UfRyG3yeqgkV17VRRT%2F3vsY%2FeDRAImkOrAfe3jGEiyXjk%2BNxh6U30BzRpaW4zhJtWgIThYMU19AnJjZHgUGpHdQYl3AtJAM2zq6RIKUi6QrqXbJ%2FuDmQw2jdhUmicdWWtp0Ks6aiUa3WFRNYn1ySUGAeGt%2B%2BDGAIek6TeBh%2FdAMI27bCJkRmyEwiRG6pmYvnMPbbi%2FNCg5JjcmxiT9pO8m8n21K6Aw2Dew%3D%3D",
        "type": 1,
        "thumbnail": {
          "de870b6d-b657-4a08-a317-8525a9773467": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/de870b6d-b657-4a08-a317-8525a9773467?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=KQmA4XFQz%2FKDYghtdrnAZE2HqeTRIuWxqXkaWoYJ45NLI%2FqbD0R1Ym57FeDMZeBWnlldxlCsO9Jrcb4n6NFJM1ZrYVjInyWG4ZOjq%2Fd%2FuWdGMNrrSxUEJZTeFk16qC9PxG3UD5pbRGUodla5p7tkzrX%2BRIapCXdQTT4tVi3xD6NziD9vwBpBzFlP9I5w5zPAGth0xDsmzBj3j98TH0Wt4N2ItlytcN0l3TKuoLtwRo3iigmp5%2B7SwOzh7DGPUk9Nd95dPvSXCTfN%2B3uiFhKX02%2Bok8Ca2jbcuzs%2BvIjLSmdF%2FllYMhns%2FfjOASu1kUrH%2B3Ol3UiO5KeXTefR1B5Exw%3D%3D",
            "timestamp": 0.1
          },
          "22319043-1520-4e33-bd82-11ad52c3c3b5": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/22319043-1520-4e33-bd82-11ad52c3c3b5?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Wt4DRJu2lIT%2BOrad19%2BGtYHKIkse1izgCu8tyOedeOqVRyVaful4aGsAHUbGVwBmNXKe6d%2BxxGoGpv%2FNx%2F%2BFqypkJiPpatLfvhRTwoXu6gnh80xWkca7j5YVLhgfyXT858rlRxJ%2BzlGALE5BT1hwe8XWv7Y%2FOCRiBaMQULhL1UrhaNSrgJ7MBwgMxuPtwER15rGOF8GoPBfMOPKkMHKgdnwGSxVy99m0afWo2AYZssDf9o6f8b%2Fj%2B1p0mh%2BSLdURQeSnzVC2muLXXuMwKtGc5TxxP4lCRpiFwEZRaHt6hx1%2BKp7DJzD34KhERmon%2F0LVq075lyJcHoAXTALCBd0FDA%3D%3D",
            "timestamp": 12.1
          },
          "6199b7d5-8cfb-489b-a10d-3d03c71fa7b5": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/6199b7d5-8cfb-489b-a10d-3d03c71fa7b5?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=fT46xlMCAUSlxOO%2B4sKD%2FL5jK0EG3mVklbbWY%2BzmIRImbArgEtwrjBrYEx3qSCCZ0I2IYcgLnzXvwYdd8cS5qNY8cZxa4IjUJSX%2Bs82WuUjGyrgTWjlPMC58n5rdc48am%2Fcwqm0GATqbVfzudqE%2BuxqtW%2BZR4ctY69pi7xG4PbmfoXRNfrQjDIRHe8xOPaDhjpaRaVL9EMpdo9JwrskoJPT8zvpT4f3naHwrS8fUA6WVbZBH%2FMpMvFXHTTSRvR9uT4gCxfZDYaoq%2F%2FUMj4I7yGUairOZY7R0rG5hbZLcNWYp%2B3B%2FVQ5HkMbluHvjDVkecQPz0USWFHi8EWz58ThMMA%3D%3D",
            "timestamp": 24.1
          },
          "d49d13bc-763f-4495-a0c3-ee6ed80c0ab6": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/d49d13bc-763f-4495-a0c3-ee6ed80c0ab6?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=FtJRdh5A64F%2FxuTHOdi8cwybtRl8g79KZjM04fmA3Fq3pVJqgUPtYDcuSOqGmACig8LhqPe5Zrey1bekl3%2FVS1ZzVvoRiyVJUo9EFSHNkGkrXizpFp5lhAsm2YpC%2BeWse%2BasRIiYkBHoGg7CBrj3uMIIiz97X%2FkSe6e4X6Ex%2FFjAZ5AydNrDVyBJBWGQqK6L4lmKRAvK78ZyP9jOf9hL7rdMvXHAeQz7QmerAdyu75xKgrHqrRhw9Ug%2BGb2cnN%2BvYwU%2BtLECxKPFo57tZxqkhtuBL3fV2hu31Il94zeq%2FJbS8rYeWFRFBONG%2FyuDZohRe39DwBJGc7OOkoIMgSfgdw%3D%3D",
            "timestamp": 36.1
          },
          "7e052456-28b2-49ea-bd40-9a54d2979b7b": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/7e052456-28b2-49ea-bd40-9a54d2979b7b?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=I177rVVtE%2FFh7uLibRLNs3L7Vjd1abZjsolYCLv2omOwpTtrbF8TdZhxV3EbP6iM29rv82UTdvmazU7QUSsBBIigN2Eb0EOU5umv4V0C2kkS7vMNo%2Bfb8YJiBEU3EVgfdDm2U%2Bcx7BJwgDuS8I1ymxYCwywbEjJRBzpTugGJUQ0PQ14l9xzDiqtI7ilE2Z9A%2B5oT1dBM%2FKF04ZLXrwwIdhJkKEyhkpvJjc7Uo86Qs8Ar3f1jWi0UiuUdi4oTMg0a0O%2BnOAtWo9evf6XBcvxmtcf1xJh%2B8vt%2F9QucgvZCyIhIFk5UggiFFLRTQmde7wRVcR%2B%2FuWvLcBmFfhwsmsN9rQ%3D%3D",
            "timestamp": 48.1
          }
        }
      },
      "98d6ba7c-2079-479c-825f-ff00507a4519": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "98d6ba7c-2079-479c-825f-ff00507a4519",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/98d6ba7c-2079-479c-825f-ff00507a4519?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=UHEnse7SkD1Ov3ztYsgM5dZt%2Bs9X0Buk%2BlK9rW8P26TLevnBZvM0cwALZKlclX0ck7J90xs6Ree7xyVCLun8czznFRQ9hqh4h8lG0tYz%2F5nG75%2FJLvKXtzpV%2BfP%2BRsJ%2BBtmioJyC4DlXX7CRl1%2BxwchYw5%2B%2FZCwxVHoTIv%2FSSgN3Adrq3SVJji3DHCZs9wplZ6oCTaLOL8vGsnIoNki8uJ%2B1%2FlVu5FBtVRgHDBw2w%2BXJuwueIYT6Tlu%2BKX1%2FSsTCZHh9xbUFa8As2oqX3DD1d7jqxV5g1FtX%2FF7Anq%2BGjkS3eK6Yxl5U7rD5SrN7dRYVYvV1jR5HbIxecYnTXHAbBw%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/98d6ba7c-2079-479c-825f-ff00507a4519?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=UHEnse7SkD1Ov3ztYsgM5dZt%2Bs9X0Buk%2BlK9rW8P26TLevnBZvM0cwALZKlclX0ck7J90xs6Ree7xyVCLun8czznFRQ9hqh4h8lG0tYz%2F5nG75%2FJLvKXtzpV%2BfP%2BRsJ%2BBtmioJyC4DlXX7CRl1%2BxwchYw5%2B%2FZCwxVHoTIv%2FSSgN3Adrq3SVJji3DHCZs9wplZ6oCTaLOL8vGsnIoNki8uJ%2B1%2FlVu5FBtVRgHDBw2w%2BXJuwueIYT6Tlu%2BKX1%2FSsTCZHh9xbUFa8As2oqX3DD1d7jqxV5g1FtX%2FF7Anq%2BGjkS3eK6Yxl5U7rD5SrN7dRYVYvV1jR5HbIxecYnTXHAbBw%3D%3D",
        "type": 1,
        "thumbnail": {
          "3a6eb8e2-b7ea-40a1-a4df-de98843018b3": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/3a6eb8e2-b7ea-40a1-a4df-de98843018b3?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=HG0Iw8PnmDJS546UCqcIGCoqKC4Z7pFw6DcGjVg8mGAs8Wi%2F1TOm%2Byvl5K1AoXFk9KAfAILhb8KUbUNmEbnT9aoRJ8%2BGH7dZKMNNb02mZCVk3N5l50lklYjzAorlZ2PcVnbP5p6Z7flVJ0yxkZHizNHSjpDK4QKAZrujtuFFU1g8Zbgw7rxe2pb%2BWSuJXWjd3HjuzckWWqKPsANeUypDAftxdjFG%2BHacZO9IMzcQ0R%2BWS1DBWzkFaINoBv775ucYnCowlgvhKRkyHlRbB2MZv2CGwkcTaZxZVQN9nUX%2FmNhVrXzJbtPtxGq1tGn%2FksNJ2HfHUuYabRiazDlVVB%2F1MA%3D%3D",
            "timestamp": 0.1
          },
          "8c76e5f4-5621-4c27-b86c-53874c0814ef": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/8c76e5f4-5621-4c27-b86c-53874c0814ef?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Yi%2F7UORRPFLDDvS9mEiwXgjPEWUiffd5EXXkKkBYSfzd9XczmlKxlgQ3zLRQn2AbmXjWVp5OnwEggj6vIg1dJTEB%2FFsXrupUHfOETEjF7eSldflp4UjZBi%2F%2F7hum2SkD7fUkZWyTKd1gX8P6nmteAjy%2BmBbAj8Oana2Mp%2FNrS9axv11H8b6oS0M0q9OpjcbdArYi7EsLgB0s18ouV2E51%2BZ4Wb0RAKWLskPRHZA2c%2BVwZaCWGvKD%2F0fx5Aux2%2FYZ9kIdN9sTDsMFckuwG1sQgMMUnWlk6pcdy7bOWRRehVTjLaQrE59iX3NHNvhcJ%2BWFYeE8kIcMF26XL4zhgp%2FgWw%3D%3D",
            "timestamp": 12.1
          },
          "e7937124-dbac-4914-844c-757bdfc1bc06": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/e7937124-dbac-4914-844c-757bdfc1bc06?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=HSjfEez7MDM9YJhyzLvHJI8UGvXdgMw04d9apz7imiFWLthEPhiwbBpjgVNTWF6Mrk8fn37ndPCV8JMOocx8EjM3no3XO2ljODbGAnyMvQuXOrgmtnO0TKxTJjwO4G4zh%2FBaEcZ%2FXCRPpXSyhJJi3ay%2F63bBT60sWWXjXvIS8y9ZVTahAOh%2BDJabe0qfDQhwzbTBU01kGXJuHA0sDGp2P%2BpwHEF68igg%2BHKo5CkxoKBJ1vS%2F2Tz%2FvckS9QFA5%2BDXawe%2BO61btXHMI0Fw1nVNtUVsvLz%2BTGysKoGYTviMrcDba86MmE%2BuBs%2Buv2eo5wgTEtaUgCw1ACcsllXgUBK%2FzQ%3D%3D",
            "timestamp": 24.1
          },
          "4e8eb6bb-a56b-4827-a952-fd226b7bef22": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/4e8eb6bb-a56b-4827-a952-fd226b7bef22?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Zg%2FPmR1DnjGMqYUkL8cvsfW7NoklMwdng7p8K7L2qmztV7qE%2BqKWmU5pcuRi0JcNLI0D2CsBQEvLxAJBW%2BX8s468aZQOt%2BPBGN9DSBmNs6UB1wLCGZ8tWsB6n%2FAh69gEwvjS5pQIPal%2Blygk6lJRKOqVpQqaQSHAclg4pb4wvOlYa16VcEPFvP7w2A0HzvoksuaWDLCetwq17OC%2FqrDTn%2F0KtGltSwCxiU8u3Z47oEg%2BP8incsoSJWxOGkjap7jxvvPl8M74t90%2BBz%2BJ9v%2FWytJecsDHrCVSjicZSIcK6LuOY8BYgwjJgHcV4cy9Mgwus03oTZcjwpa4%2B68CKyLA4g%3D%3D",
            "timestamp": 36.1
          },
          "972316f7-8894-4a7c-b3a8-de7d684b466a": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/972316f7-8894-4a7c-b3a8-de7d684b466a?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=eprnvPY%2B6yQ0eZo5C%2F6ocPNa86pI72j9jI3gPy9TNA4RubZ5jFuMscpE3YTNkNzuxhPi8kCxNde%2BEHk7u3ZLd9p7Wd93GB0hVMAd3iVvFtpy%2B0oT%2BEh0BbGx09xnTDjr%2BELLsd%2BHJMdN%2BwoZUxD0YmzkK5GiwqVwDi9efXxuKOQklEwHyZg27AeSJlMrVJ3t4Cyf83PRKWRAX%2BD2j6DAIV50bVosSjkVBVIT1A8oJjkThct1UAmPQWuu06F83aQ%2FH1iF9tSsVqX5nXJDDYykvBMPRkBxWpN1K9U1YvjTyWXZ58%2BKllcFIh%2Ffm0eMAu4rgtDRe44URRpZtyQ%2Fi5q7sg%3D%3D",
            "timestamp": 48.1
          },
          "46c9849c-22ac-4091-b83a-5ca9ff5de2f7": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/46c9849c-22ac-4091-b83a-5ca9ff5de2f7?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=CdGT9PWL6m7e2oh2HnldCg1QYlbnw4rHpg3UwgoX1F%2B1DDOQiJOv%2BO9o8BBBxuKJrJYBtTfx4vaVRkkpgB%2FteIpAx4OdI5of3WWIGOfFk9QmJyOpycFTMsLTQDMOY6s5mGbri340T0aNwfB6DkE8O%2Bxsz4TLlpY%2FmHBqQWf1TE%2FKlp3NUla4fRH2u%2FYRtC1YyLElEkSmYPZgIB4MLzNB1LUiO%2Fc0UXzzpPwKAnPophGwy1hpbkI%2F%2FidIQrXeB0SM9g48%2Fqeg4sIxRGtPlLBXRj35%2BfO2l2DyNMTNRPSgvbUb%2B%2FfTYLkyvWMPaMbix3Hjlf%2FukZARHumuDI%2F4NPeXAQ%3D%3D",
            "timestamp": 60.1
          },
          "ddbe68e0-a7c0-4409-a16f-cd16b8e843db": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/ddbe68e0-a7c0-4409-a16f-cd16b8e843db?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=OiA6ISRDod5BzCz%2FeIvb%2F1tG6ZAs2RddJrrYrPn8naptWh3Vw3oEW4LlTDcMp0ZT8hJvt6auhg0qo46ZGASJi2x2IfOhp4PkFJF0vOy%2BzIeBpudeoUVAWvUPlz%2F4mCN9lWDp%2FLrgexxRYIn2AffVE9HOIY25Dxq9FxsW%2FUpZxxRA9p5hsEXGWBWd5WiBUXWaiA6GGukdWLeoeiHT%2FTMUoA9TV3VQr5agYILnm4JD1JlEQwqS8AxzFMGhOc%2FFkKO6MbNuYv6AxDs12QJ8ABMpG8IM4Y4HCeKFnX%2BAu4prQeZgqVCbno0FmhsIiqoaOFU3pPCEGjP%2Fsdc2nI6KaDCwDg%3D%3D",
            "timestamp": 72.1
          },
          "33575664-a44f-435f-bd7a-f04cc6c6e52e": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/33575664-a44f-435f-bd7a-f04cc6c6e52e?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=HS%2BJBCmgRBPlSHi4qg35PPMzfOfzCU9eWSRITzrkOF%2FAeq7VoDTfp%2Btghsg2eUMujd3pHuetGn59dlvFismt2HgyOqiYIsLuFM3BbfpMqIBvzndga8uavg4964iaBTNgI5lQngvkjHG63JZIlbD7B4Kais%2BgBy3B7kK8MP8rjOYridPEf4PoDdB%2Fn5PUF333rKIDB3qd0p7raAWplAiZtBhSBWuqxblyI92TexJvwbT%2BhXWhASzjkupm1F2tlwKQQX3tcb7ZSgslkNZ0MOaFWLyi2HvheaAwve3NYuO59EHGcNhCchDqBBM6MroHZpSQ4RfA7m9r%2FOhX3%2FCUBjpWBw%3D%3D",
            "timestamp": 84.1
          },
          "d37e65b1-23b5-4375-9b12-0952cf3e841b": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/d37e65b1-23b5-4375-9b12-0952cf3e841b?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=W%2FIWM4jVh%2Bl%2BH6QNKDrfa6xrrNpxfwHKTH1R14dGZyzelkmX9La2UilywN%2FE07LLwZHJiVbfwJuJKhMIoSHs4zCFBJgGW%2Bv1AL7kp4o%2BzJIZRapl6UF6eaNJl2MkwuKnjxoGuoVzi051jHqmHY46U%2BJ1G6xuTij2eteEbtiLen6CcIpQPYbdjo%2BdLK4deoW%2FeFukK0lZlpialiToyG3X%2BZJf1UZcfI7FfKI9Y%2FSGapUSa9vmehzLtD2p6CaEucYzUhIbtH24aVgGcjqvR9lWw5RPdVTbKqb4Zn22HWkUYA%2BoyOpnlunBfxxSrKwiKxtOF6YJAGWwm82fdSmMZKDrHA%3D%3D",
            "timestamp": 96.2
          },
          "256f2944-b920-43a2-9365-0cedc447c1f1": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/256f2944-b920-43a2-9365-0cedc447c1f1?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Y1of99NKjocKMTqpj9VfQ7Mq23rag9%2FxAZ8gQ7tek4Awo73ISMUooKW8WwVFJmdnufKWXjEhs%2FMMj9M3BEf76Rx7Xff90O%2FfPQtqGd%2F0JRzPCcobgFQlqhQ9n6LoMeIRA4dW852meUgE5c9bbYOJntCWZ2N6N9iDJtN5Q%2F3iKsQnRYAdQtvZ0CgiUFiLYMZcdXXxWo3Zi2kKvMYyOB6PdeaCvj%2FF7HHHQVBavGbylzS4zZF2EtYLjCB0FNOVqxKqUviQZVV296NIjIWS%2FZa8ApoTI0EC2xnkarI%2FznbtJWzAtDktaYwQh0sXQxjwq%2Fes7GXoEB2SxdDQcabFLa3kGQ%3D%3D",
            "timestamp": 108.2
          }
        }
      },
      "623693bb-ccda-4288-9fe4-32b8056ed487": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "623693bb-ccda-4288-9fe4-32b8056ed487",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/623693bb-ccda-4288-9fe4-32b8056ed487?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Z1i1Z76GT%2FPxx8O4OIqwqzaPOBCcw3Nhq2kinSjNLD9owVBxv8FvrFxYHuB682px9e7YUcMgiurV4heNDVsT9v8GSOLXfKeG1CNxQ%2FluZvQm6AWGW%2B2UE%2BxKS81L54eDPIo9hVGUBqA3u94kJI1m9SAgODkJaXqOxC8MDFgyVd7DYvSbcaBNOYFlKU0P%2Fh%2F3H%2Fr5BAO7IbIQIDo8oez8Sn6SpS77KZkZ0rOR5LcTSfJ1aqd165JwLVXAsRteA05hMGWhuTVC11e%2B7c1bqRtpsjUsKWivadiwB6vyyxAQmwCSL50ld72JbgWyl1LEsRiS2sjivCk4tgAhDXwre6UOGQ%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/623693bb-ccda-4288-9fe4-32b8056ed487?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Z1i1Z76GT%2FPxx8O4OIqwqzaPOBCcw3Nhq2kinSjNLD9owVBxv8FvrFxYHuB682px9e7YUcMgiurV4heNDVsT9v8GSOLXfKeG1CNxQ%2FluZvQm6AWGW%2B2UE%2BxKS81L54eDPIo9hVGUBqA3u94kJI1m9SAgODkJaXqOxC8MDFgyVd7DYvSbcaBNOYFlKU0P%2Fh%2F3H%2Fr5BAO7IbIQIDo8oez8Sn6SpS77KZkZ0rOR5LcTSfJ1aqd165JwLVXAsRteA05hMGWhuTVC11e%2B7c1bqRtpsjUsKWivadiwB6vyyxAQmwCSL50ld72JbgWyl1LEsRiS2sjivCk4tgAhDXwre6UOGQ%3D%3D",
        "type": 1,
        "thumbnail": {
          "88f4fb1c-377b-4daa-a00e-cfa594d2f031": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/88f4fb1c-377b-4daa-a00e-cfa594d2f031?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=IF2B2i%2F9BxZ9t%2FiIUY4mN9vUIHKl1Pu20g2%2FkEad88hoE9yOIANNgG1R4jCB5fIH4RhWaAbEW8VshGhJH5doGUthv09DYSRCr5%2FE8iC8dJNg38lBKDGzkvcgRvAT9r%2FYxzFs9zS5fsFKIfX1DMu5k%2FjZ3BUNxy7v11ckqTE5IB4VA3xHER7ackuyZX22DHV33HIXUjufELIVcIdOcWJe7cdhl0EZa5k0c0IvAuy%2FuGiHYKvyF1XaRKNY%2BzVKWj%2Fof5ZvFkJQSmWXUqAN93osKXJ8gWzj3zTTUNO%2F78UOTZS4DKIH%2Fb73Mge7BzR1U6VYG4x8ajFSaqROOgx7slu2Fw%3D%3D",
            "timestamp": 0.1
          },
          "94b17512-2aec-47cb-94a0-94ea9ba5c272": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/94b17512-2aec-47cb-94a0-94ea9ba5c272?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=XKSrtEE7EMlJyKq9s9RRtLWxkSTYTIBtRRIcE14xI3GGNoWOvB7nuDouB%2ByXf5TbyH5OafwTbaHMha0Et%2FtS%2BuUnsX0lvPJpNYQ6rN%2FBPjZceI1LYcR%2Fjrlo5be4G79GhCEx6WPHnH20XcT1HyZyrlN1suECYCwNje%2BvkXPJVkjLVHaIXVkAd239e6O1v6vFxyA4z%2BYes%2F%2B%2FUkJI%2BmUWl2eiZ0kSCzeHbtOLsjJ3i5KkOjY2QakUfRx%2BcbmKY1x8xtgf4iyBMH9JQ%2FhD3jcvOoK0KY7Ly2l2otjxM8v43kEpwBPkKXvtCmpqWCcFE68nGZIsAd5rWscViB5d4p9vcg%3D%3D",
            "timestamp": 12.4
          },
          "da7344a4-556a-49e4-95cd-6725d548622b": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/da7344a4-556a-49e4-95cd-6725d548622b?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=R%2BY0s8vBNUI5aiN6%2B1nXuHD8qonobdFBHLCcv47gjjUTy4LUOxT1C%2B69lb%2BUFgoVV%2FsldKkrDAexfgiwMXizyMavP7uGPDDNO5uULlzptMCtnGUxKSAoDZuMP%2FC8%2F7qEut%2FQYIuloYqsWI6txx6HmW6Y1Q0JgJc6H69w1%2FbKZJSVh4%2BR1QxV%2FCzxYXYWxsRzIdUaOA%2FwyjZIbmTew%2BNz6oqsTOzeEzkfF1cyZxOEyKhosYjNRsImV5RSlH7XamR75bhC7ZAZJa7p8xQB6szHKKhphuBM9sh4UQ2%2FqHYcU9FpxF%2ByhlyTpta5LfMvDTe2F%2FiyhYJiVGdfKAudt%2Fv9mQ%3D%3D",
            "timestamp": 24.6
          },
          "028dd938-128c-4239-8f69-8869a0eef266": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/028dd938-128c-4239-8f69-8869a0eef266?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=YWBI2ByBaNwKNyYBqma6v40P16U%2Bfjl4Dh6UR%2BDgL3Eay6cFps8Jue9vUZQOjjSyQ3aCBM8x7V9SC2U9eCkC%2B1nVsEOWuWhXp4PX6kx%2FLPnLNe7pRedP8%2Bb5AanfCEjH%2FmWresTm%2FLyPBmc%2BfxVBjalM3rbWVvDDOt9Z5mFd3QAzm4cebXp391ej5vp6gcQOjgDggFRM3b363nHw7vbxFQG8AM%2B59eYhN7j5uR1cTN20Fjxslyd9sUvbySDummPqxbCbprS2F2WxzXD2EcXXDjcvK89ehgD7I8eisEDsV44Zpgz0Xt3G44pHuIfPLGF1ufI8He18Hp4tfhpgfEMxZw%3D%3D",
            "timestamp": 36.9
          },
          "5554a1b9-d9fb-4367-8e14-4ce1917f37c9": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/5554a1b9-d9fb-4367-8e14-4ce1917f37c9?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=VUqSu8GYpGi%2BULgfagqolUL7uPW880P%2BZ7XQ9OPMMfp%2FKRj5C5E9pVYAu3bDHCEEeWYZw30bemeUzwVaIkqkJGqaoCjT5L7BdM9UKGmljFhAy4zwD5nq%2F6hBJ14K7%2B%2B93LPqI2tBl0tQhffRjKvDnpIGYPQRJzG0m2tJkYXfjNOzTEfNFJwzE2ECKWU3DGQFD1dc4MGxFjIW6rwdju1%2BiB0EaZ5yiscRVhBuNSD4nqAabyUAAmcArpU4%2F5ScsKn%2Fhztq%2FU%2BNpkqjPwHgqNjeMCiLzdysULUYaChu0XA1TK1SHcpc%2F6vjdNtTOaT1rlbuZRjOVwgLJDKs2KIw45fnmw%3D%3D",
            "timestamp": 49.2
          }
        }
      },
      "0be3f3c4-97a7-4c86-a3b9-6b130dd52a62": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "0be3f3c4-97a7-4c86-a3b9-6b130dd52a62",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/0be3f3c4-97a7-4c86-a3b9-6b130dd52a62?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RzHfahbOQTVw2XcyaZ1eeoAC81yqu9F3EV88djKkWtEMaAj72QYYA8WoR07LpTeJ1gDSM2AyI15weXp0pLpXRcB4otSifcVwUnz14I72ePXoVaarbWOf16Q4Yxa4aLnQUoxiUcST5UXTZpS9WpgdUywmVp4FG7AsciUq4BS%2BcCFfvdlzjoEK6Qt6m2cEphtv6FTpVN5BlyWMrCjtj%2FXQDDIcOpIeyYcBL%2BkQwFZYa03ZpbQwsjJM4Ytp1RUtIm%2BgDwOK0L2JDEnwrJsCbJZ6mB7ezS%2F0PUA05Rr7sRWz72VBud7tTXZgWuExxWBEV5n%2BfUUcm66BMa41fxx%2BBSERsQ%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/0be3f3c4-97a7-4c86-a3b9-6b130dd52a62?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RzHfahbOQTVw2XcyaZ1eeoAC81yqu9F3EV88djKkWtEMaAj72QYYA8WoR07LpTeJ1gDSM2AyI15weXp0pLpXRcB4otSifcVwUnz14I72ePXoVaarbWOf16Q4Yxa4aLnQUoxiUcST5UXTZpS9WpgdUywmVp4FG7AsciUq4BS%2BcCFfvdlzjoEK6Qt6m2cEphtv6FTpVN5BlyWMrCjtj%2FXQDDIcOpIeyYcBL%2BkQwFZYa03ZpbQwsjJM4Ytp1RUtIm%2BgDwOK0L2JDEnwrJsCbJZ6mB7ezS%2F0PUA05Rr7sRWz72VBud7tTXZgWuExxWBEV5n%2BfUUcm66BMa41fxx%2BBSERsQ%3D%3D",
        "type": 1,
        "thumbnail": {
          "d2a8fa66-2076-4128-a4bc-fca4c588a731": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/d2a8fa66-2076-4128-a4bc-fca4c588a731?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=IAeTBm%2FV%2FEYbn04FU4fabRzXznMOornAgkRO9f25e8nuwWK54Ov2A0Itbti9gdsDxBTQwig1xJ1TZqcIIHNTvpVqzxqdCAdgpDHUC1jSOB6ra8hedKxPymzbSyFH%2BPPea%2BKQ82YmA3dSZALsVDijVxOXy5QduswVbj6tCCZX46pC60BHFsDxpph2heGlOrTTBxdUmgO%2BJM8L2oyHQnosYSIzj%2F1IE8ybR3EqwpSQyVpmlysgVPVAmFx3pX6xXuF6wkaNRbVyTpxOqvPqOC5Qoc6vsX9HQrABew7ERzg8u53XTFemVSW24RUGLEzBQP5Q6p52wRXh65p%2BwOZfrv1UQA%3D%3D",
            "timestamp": 0.1
          },
          "9bfafe9c-cf77-4f3e-b741-58dc0a3b62c7": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/9bfafe9c-cf77-4f3e-b741-58dc0a3b62c7?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Z3FdLIJLttCt3zpqWxeFEQwstzouDGQnI%2FERooCOcgpLHqqNULtGJS5GGtCO5n3Z9TJcnrq%2FnbsQ1BZFsKxiTCZFW%2F53SzBiSsjw2s6TZ6oOGYsoq8z%2BoAZKMJe9DG32RqkCltqpUK6UnmttoNwYO0xVEaK3yPt9giJz67ED0ckfNxg61YzU0sjMQ3jrtCBOXsEGIwRDUaQFApjQpZQNvSU82MPogJXalngPLy8axvKKpZ7AIPeWLzIIcIJmS6xakVcAyW9q98SwYLF3CfTJA00lL2Ac%2F7%2FIF1R%2Fkblbexa5Iq1ndGxzRhuSjc5YolvbCPtZNKu4hvzyA1TQN%2FCzug%3D%3D",
            "timestamp": 4.6
          },
          "7acd5005-c439-46f4-a8de-ab9fe3e3b7ff": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/7acd5005-c439-46f4-a8de-ab9fe3e3b7ff?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ho3%2Fc0Uuio62aIVuYLi%2BxO1%2Bx37mS775QKWTcAwYb8pl4TlHCO%2BZf4klWUXMG7P1TVPTIwfTwFVWXV7b6N8GrHnQuVu7cl3%2BP43RGM%2FpzeAeW6qixEUqroa80hqC9Wn1hOGMkm8KfNdxCVnSmdrLiLlHaP88xmD7qJNFZ2Ecx%2FsZyehYLTu1PE0FXMV%2BJMlt%2BkOO0jarnorpRHHzWjSE1Z8s1rO1JkPTEzZ331e%2FjtpdVNzm3GI%2F%2FgvFbA1t8nN9sXk44%2BexJi532pfX3KH%2BXIVOp8eQ%2FTqV77JG7o2nPYo%2BDcoYXH0XPv9WHY6JNtm31raFmssOENG4jN%2FvdZa%2F7w%3D%3D",
            "timestamp": 9.0
          },
          "4e13a1e7-0f67-4782-a941-a24f4fb7e2ea": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/4e13a1e7-0f67-4782-a941-a24f4fb7e2ea?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Zva7JAFBzuHgWApTiSoqqpHS9I0%2BvEwRXsNB%2BLs1Uf7l4LZuonxo2sKquUf4SZ%2BzWkMKmgQviWjMN5Bm5QHApNS7%2FnKWMnhZ5cp4yaxPBRqWTclclSJq3pLgrkwG4rsSFMTvs%2FIvXCHjJHOjKmbOFm2AwaoeddkACasbWaNIlJafy2HVzH59OvzJQ5LpRUPIJCyy5MbUoZ9PlwS%2ByQnA8VaPmmyRQ7kY01QtpXsYTRTadnKprgP%2FvkTZsSy8BzGSbBGEMK%2FqosuJJqrjbja99b%2B%2Fh31d3GXWQYtnZOKFzKxfEilVR4Ui%2Fk7Yn1h1Aqlh2IgOBWCmpheZy4wrIygXwA%3D%3D",
            "timestamp": 13.5
          },
          "354fd902-d85b-4ad7-ab44-8892e2b5db0f": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/354fd902-d85b-4ad7-ab44-8892e2b5db0f?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=YjggZ9R9HNZ0%2FHDLf8hMt9cv6nzXSLCE250yASfmBIYraO2d4TAmD9Xr%2BVW8fWhraT%2FI8lgtjHgTE6D%2BOqPbJYawNoobewAhhdyKBNTOe0uFz4wA6P%2FIJlqQUSL5jaNHefqj%2B7yoEFa7xeN26WyxBRLPp6J89B%2BWqLTvTs0MapBEyE8XdRD5O1Di6NsR0B9%2BURLX6uMNs%2FHrZeOtNcW%2Bq%2B2fJm9w9ITtgKGlLhmPnV7aacU6K9LXshiVmPeD7EaxNyHMLjvbDZ%2FV4gyeii2WftEkqUBLSzrDA3EvNBYRvxRNT5iJ2iLBySn5TEe1efKSAttsl2Rr0%2BDyUcTYsDZGaw%3D%3D",
            "timestamp": 17.9
          }
        }
      },
      "2d9aeb87-863e-401a-83d5-ee0cf1fb12ae": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "2d9aeb87-863e-401a-83d5-ee0cf1fb12ae",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/2d9aeb87-863e-401a-83d5-ee0cf1fb12ae?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=cvEybn2XQrcU4ZQpTgo2bkg9zzdECD3VPE3Ounsn%2FohwuJEajeYPmdS8LMLd5IUBD6RiQDiMgkwlJOcALl2ecwQq2j7FhpS%2BvIkBGKRN98qIeGGOFFsAj1s4EnchD%2F460UKQzSfEE0LdsbTW9t1JleY7BTVnlkkBGXl60TkVVqjAUtsiVKonRp%2B1WTHC6Gi%2B69NUwbDt28avTG5T6jzgORY6MXW30SfIlG5LLJ52YihHmuOTx4Km2UdA9QBvKnI4OFCG8%2Fx6gC%2BelKevQlFIKLF6Ln8rmXGamjzKSomS%2F7qh1bRpLuZndMMYT0AtUO3lpum7Zjk8kQg%2B0nmGYgHyWg%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/2d9aeb87-863e-401a-83d5-ee0cf1fb12ae?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=cvEybn2XQrcU4ZQpTgo2bkg9zzdECD3VPE3Ounsn%2FohwuJEajeYPmdS8LMLd5IUBD6RiQDiMgkwlJOcALl2ecwQq2j7FhpS%2BvIkBGKRN98qIeGGOFFsAj1s4EnchD%2F460UKQzSfEE0LdsbTW9t1JleY7BTVnlkkBGXl60TkVVqjAUtsiVKonRp%2B1WTHC6Gi%2B69NUwbDt28avTG5T6jzgORY6MXW30SfIlG5LLJ52YihHmuOTx4Km2UdA9QBvKnI4OFCG8%2Fx6gC%2BelKevQlFIKLF6Ln8rmXGamjzKSomS%2F7qh1bRpLuZndMMYT0AtUO3lpum7Zjk8kQg%2B0nmGYgHyWg%3D%3D",
        "type": 3,
        "thumbnail": {}
      },
      "e9cf8635-1b80-4064-9fea-ed43caa84954": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "e9cf8635-1b80-4064-9fea-ed43caa84954",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/e9cf8635-1b80-4064-9fea-ed43caa84954?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=MDrHFx394wMFLzXA5QBmeBy%2BDQrKmTPRVeyCqzB%2B2sYnIr%2BqZMhc3BY7%2Fl6Tg4pE%2BcZFrYwIUQzg96NgMIXW5lNqVRsJXXFQXEqjiHrrqxoFPo03GHiWA%2FTmMWL4Thf6zu%2F0lTrtq%2F1sFDoF3t8qEjeh2nGu0NLyW70gO7H8sZdx%2Bj6JmVqdum%2FefMQ1QrmZ%2FTe62bbjxsDuEkfMGyh9i0ltexgcp8v2yAVn6b3Y5E6CQEANOSra%2FT%2Fr0hjFuUi8QoQe5YIS4c4fmu3mXgtzCs%2BS8kaOpDNewl87gj1JwLJ9X%2FeWgIu94qTyNcgjq4Re7gi5ibTnmJzfHjjTpbcXPw%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/e9cf8635-1b80-4064-9fea-ed43caa84954?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=MDrHFx394wMFLzXA5QBmeBy%2BDQrKmTPRVeyCqzB%2B2sYnIr%2BqZMhc3BY7%2Fl6Tg4pE%2BcZFrYwIUQzg96NgMIXW5lNqVRsJXXFQXEqjiHrrqxoFPo03GHiWA%2FTmMWL4Thf6zu%2F0lTrtq%2F1sFDoF3t8qEjeh2nGu0NLyW70gO7H8sZdx%2Bj6JmVqdum%2FefMQ1QrmZ%2FTe62bbjxsDuEkfMGyh9i0ltexgcp8v2yAVn6b3Y5E6CQEANOSra%2FT%2Fr0hjFuUi8QoQe5YIS4c4fmu3mXgtzCs%2BS8kaOpDNewl87gj1JwLJ9X%2FeWgIu94qTyNcgjq4Re7gi5ibTnmJzfHjjTpbcXPw%3D%3D",
        "type": 1,
        "thumbnail": {
          "9918f8f4-5c6f-47b3-aaed-091b52471b44": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/9918f8f4-5c6f-47b3-aaed-091b52471b44?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RGjIaCtUGLK%2F3zto6RuRSBF5EWTi1%2FRfVmKejo5ESeNoae%2BtxTM7J7EjFFm0NfEeuqfK%2FazThrkDeskkVMDwSgVEXQ%2FK58tWjPRYOp8yBpR1DK50oC8r3E6ZDHWOpePTskXrTjhsO2iAVkb4jOrvDyb8LKpIHrv5W40FS6yJJE1%2FUrbbttwF3xmfZWTTzkq6hhIPjLJ2tRVhkWy1F1ZFMAX%2Ftgh1kJW80Fy5NXgfjKMby%2Bj4NNqqx7PCykjoEzG7sECsryevPxx3S%2BHM1ivrm8FPkMjrMQpQzBCOYRqP4Ad%2FnvqKj7u2j4k%2BtKnspm10NdGpiS9ePn%2Bg%2BmpqonPi5w%3D%3D",
            "timestamp": 0.1
          },
          "9cde4799-243c-41aa-8b74-6d1fa7898a21": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/9cde4799-243c-41aa-8b74-6d1fa7898a21?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=EnvE%2BSKrgxfm7enlTutiMeHbZAfr8PBwv4Hx%2F6E5PmX6RMkk5D7TBnnRL89prtFtjsBPejogVJZRYDryIVGjwQct7Vp5YLWomjRuPzzSqTwGmE11%2F5a%2BnPAObU7FhOHcn96cftJjBlqWd7WWeYWj5fy9jEEB35JvfWxwQSuvRObGMDbZi3zgOaCKzD4Vbjl5MqOcMuSocJK9F9Rm%2FOqTV%2FHFBdH2ig1SvmqO70kcpQolHRjxr3lnKUJbv9N0qb5rP2JTFLSmp9QPh5CZBJu7PGDO0qchOKbbrKv8U%2F5KjwyDddqYSxWkh05j1hqGnzitpOPbjS2rDrvqXR%2BSi5sO%2FA%3D%3D",
            "timestamp": 10.5
          },
          "f977dcb0-63d2-48e8-be7d-ca29ac14149e": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/f977dcb0-63d2-48e8-be7d-ca29ac14149e?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=gw2rXYJEZknzVEL9Ww6qxRLZGqHd3xH%2FDT9qIFTBv2MoFD%2B%2FGCZwCnkCDj2UO%2BU%2BOGX%2BIT8b81KZKlkO%2BK%2BGOTE9FGHnHMacknhipV0D7FItlEgERAagwJdrvceYHdkZcwUnKsxHYpL%2B%2FSCDv5ZWVhtv1Kl5OBlZTzsO%2BqXCq%2F687macKymEXInN%2Fu6jNtpBCt2LWlnV1o60wLg6BY3SmLS38hbKVWxR34UNnkxJMsVmJ%2BwKZAqabtjXPuwsuiOU%2B7CBbz%2BZ%2BlVZvWTX9sHnJi1aFsQHO6LpkLLSTZfn7vZSU4WGWrPYVs2D6wDkzmjQFNyGIcggG03W7zWqJiZcFw%3D%3D",
            "timestamp": 20.8
          },
          "3956122c-3808-4940-95a4-ebba0d30ba70": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/3956122c-3808-4940-95a4-ebba0d30ba70?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Cs%2BG2moao5u6rOTNpN4USeNrLw%2B9NPwGRqe5Qtca7Su0OKmldSfXAw1yuXCZPrBvx5Q2Q77trSQgqifmrHFclzjaVH%2F9W7TO9rTtnbrX80zxkYMrSaTw5idBWb4kKa%2FAfPQrZUVKTImkkH%2BUg92Uzjg7B4L2XcRDIPPvR6IYpFOlDqPy9u7Pd5cWjPOhURm8yViqWw1EAxHqhZubz3bNi7ENG7ZUgYr890yYwB%2BZ1fkJxfy08TZyHbf1CeuwcgnbVOrALlCtpg3Q2aU4SRjo9NXzKADThd%2B07v%2FtyDXEaVhANcs8P5o8pPuadSfYamDBqW6iX3s7VV3GBBXCFuNmiA%3D%3D",
            "timestamp": 31.2
          },
          "fd8ac278-6e24-4116-abd9-9dd72c584494": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/fd8ac278-6e24-4116-abd9-9dd72c584494?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ZxLKga0%2F1a638kMd0Kg5%2Bykbfy7iCSluEayzRJD3xQjOvlIv0D4qrq5won3faT4u7FzETIQ7f4mghLrxufKTVONIeFVAxjoZ55MF5118ZWXE8CEnWzI3Rxckzk78nNyxh7FKJLBSQ67l5vf0rkVVYswz%2B2fY%2B5z9wSrM2%2BrkJOZ%2BY298rrXfqisAX%2FM9ivxzNa2kwpf9OHoKe0Z2nOjHt2xu2vKWdBM2S0MiPk68pviTAI3xRgXoGkTYVTd9uw%2BE1SI%2FmHrflA3uETIZrJO9W3Tw9bzCrb%2Fli5%2BK12mSXTjoANAeYmHDSrYNvtbmjo0tvBrWrVIHGWGrsstXwXZP5A%3D%3D",
            "timestamp": 41.6
          }
        }
      },
      "62c1cace-ffaf-4e0b-81b9-004019d8a79a": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "62c1cace-ffaf-4e0b-81b9-004019d8a79a",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/62c1cace-ffaf-4e0b-81b9-004019d8a79a?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RTCGz8earYv8JuxfMNrBmuyBIzT99U33LbYxfJG8D48F6YxRCRS3bTMx4U6qb0gAwiOKBPwrSpfdISE3YMk9wg2k8d%2BJSYkFaytqM7M%2FIYDB%2BddUgsHs6VsucD9s9ZBhXPGD49F7YKaBhVggEI5z3tFjG5bw%2F3OuFirbNrOyU6dXKEm5G1qNi98D%2FWr%2F44RlVsMoSGX6BRZm6sX7BjkHqV0SBZL8B%2B7fUNiMKVJMBbiHQLWPhmlhRONIEdjbUwSNs5ThB%2FCt0OSiHd0EX6O%2FPRFOJTDnPvnW9meS2fMDRRl%2FU3VLG%2F0pKIq3l5Dn02Rg%2FhqW%2FUnU3LUywalECMPe2A%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/62c1cace-ffaf-4e0b-81b9-004019d8a79a?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RTCGz8earYv8JuxfMNrBmuyBIzT99U33LbYxfJG8D48F6YxRCRS3bTMx4U6qb0gAwiOKBPwrSpfdISE3YMk9wg2k8d%2BJSYkFaytqM7M%2FIYDB%2BddUgsHs6VsucD9s9ZBhXPGD49F7YKaBhVggEI5z3tFjG5bw%2F3OuFirbNrOyU6dXKEm5G1qNi98D%2FWr%2F44RlVsMoSGX6BRZm6sX7BjkHqV0SBZL8B%2B7fUNiMKVJMBbiHQLWPhmlhRONIEdjbUwSNs5ThB%2FCt0OSiHd0EX6O%2FPRFOJTDnPvnW9meS2fMDRRl%2FU3VLG%2F0pKIq3l5Dn02Rg%2FhqW%2FUnU3LUywalECMPe2A%3D%3D",
        "type": 3,
        "thumbnail": {}
      },
      "986ef2f8-f6dc-4899-9c23-9e4e6d34e336": {
        "filters": {
          "preset": ""
        },
        "original": "Output-AudioOnly-ae8c16e7-2348-4119-9b07-a1043f646159",
        "filename": "986ef2f8-f6dc-4899-9c23-9e4e6d34e336",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/986ef2f8-f6dc-4899-9c23-9e4e6d34e336?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RtTao1A%2FUoENRT5uJ5AkKIT2wBanwfa%2F%2FYD5zYpLLCVRHpFcHY6CnauXulfFvR55wEZi1WnPXYL9gdMZgZ%2BlhQ6SqsJjbB3b9R%2FSRzeImYqA4SrrfimycxSwVyT4oUuWmj7Xd4OlV%2Bom0u%2F9RnnUqLGls7KABg3uKLZGA6iBtuJtNkBL3o%2FvXVZYTb7RI5EQnVaCBO05V3dCzo9IzFdMTUArN5aiyOE6mVc25guwIANFWnNwSgOnGPaQ2588GxNSMeBPPYP3mDHwnRJb4RipmtJ9NwazjCNaSW4XWSat8Ic893OGJedCuAUGmq1JKzhgyNJNU17A8TOAcL6jrVxgPQ%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/986ef2f8-f6dc-4899-9c23-9e4e6d34e336?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RtTao1A%2FUoENRT5uJ5AkKIT2wBanwfa%2F%2FYD5zYpLLCVRHpFcHY6CnauXulfFvR55wEZi1WnPXYL9gdMZgZ%2BlhQ6SqsJjbB3b9R%2FSRzeImYqA4SrrfimycxSwVyT4oUuWmj7Xd4OlV%2Bom0u%2F9RnnUqLGls7KABg3uKLZGA6iBtuJtNkBL3o%2FvXVZYTb7RI5EQnVaCBO05V3dCzo9IzFdMTUArN5aiyOE6mVc25guwIANFWnNwSgOnGPaQ2588GxNSMeBPPYP3mDHwnRJb4RipmtJ9NwazjCNaSW4XWSat8Ic893OGJedCuAUGmq1JKzhgyNJNU17A8TOAcL6jrVxgPQ%3D%3D",
        "type": 5,
        "thumbnail": {}
      }
    },
    "title": "Project - 11",
    "width": 1920,
    "audios": {},
    "height": 1080,
    "images": {},
    "videos": {
      "701d59d3-5595-4189-83c9-9400e892b22e": {
        "alpha": 1,
        "groups": "701d59d3-5595-4189-83c9-9400e892b22e",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 41600,
        "rotation": 0,
        "sequence": 0,
        "dimension": {
          "h": 1080,
          "w": 1467
        },
        "asset_uuid": "9183d546-70e0-4860-928d-b45ad4d0c890",
        "coordinate": {
          "x": 226.5,
          "y": 0,
          "zIndex": 0
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/01057931-65e6-445f-af07-1e5391b28086",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 41600,
        "timeline_end": 41600,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "90472e7f-d6db-4685-a3bc-f9152aa81b2e": {
        "alpha": 1,
        "groups": "90472e7f-d6db-4685-a3bc-f9152aa81b2e",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 22257,
        "rotation": -38.0162,
        "sequence": 1,
        "dimension": {
          "h": 309.94370370370365,
          "w": 413.26814814814816
        },
        "asset_uuid": "0be3f3c4-97a7-4c86-a3b9-6b130dd52a62",
        "coordinate": {
          "x": 85.53086419753086,
          "y": 511.6049382716049,
          "zIndex": 3
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/186436ef-1981-40b5-bb56-44d8b9d94220",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 22257,
        "timeline_end": 22257,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "93e728dd-4543-4212-8bf6-220d516de2a8": {
        "alpha": 1,
        "groups": "93e728dd-4543-4212-8bf6-220d516de2a8",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 51851,
        "rotation": -35.5642,
        "sequence": 2,
        "dimension": {
          "h": 236.3753086419753,
          "w": 420.26469135802466
        },
        "asset_uuid": "e9cf8635-1b80-4064-9fea-ed43caa84954",
        "coordinate": {
          "x": 21.728395061728392,
          "y": 246.91358024691357,
          "zIndex": 3
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/43b6199b-0c5b-48cc-a752-c7e929df1637",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 51851,
        "timeline_end": 51851,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "992b2643-460d-4274-88d0-861ca6fc9513": {
        "alpha": 1,
        "groups": "992b2643-460d-4274-88d0-861ca6fc9513",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 51851,
        "rotation": 0,
        "sequence": 3,
        "dimension": {
          "h": 197.28158024691356,
          "w": 350.7812345679012
        },
        "asset_uuid": "623693bb-ccda-4288-9fe4-32b8056ed487",
        "coordinate": {
          "x": 576.7901234567901,
          "y": 525.4320987654321,
          "zIndex": 4
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/d6d81394-7d6a-449f-bebd-735dc84d68f2",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 51851,
        "timeline_end": 51852,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "d0ee3f1d-41e8-40e6-9a8b-5974e0586f64": {
        "alpha": 1,
        "groups": "d0ee3f1d-41e8-40e6-9a8b-5974e0586f64",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 52002,
        "rotation": -32.5477,
        "sequence": 4,
        "dimension": {
          "h": 215.99999999999997,
          "w": 384
        },
        "asset_uuid": "638c0c7e-9c2a-4197-954a-b3fc061cd9c7",
        "coordinate": {
          "x": 1.9753086419753085,
          "y": 37.53086419753086,
          "zIndex": 1
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/d7470b28-eef1-417c-b9fe-94dcf8ba0965",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 52002,
        "timeline_end": 52003,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "e13cf568-e7b8-463c-b128-5c4975a5ea0d": {
        "alpha": 1,
        "groups": "e13cf568-e7b8-463c-b128-5c4975a5ea0d",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 52001,
        "rotation": -130.776,
        "sequence": 5,
        "dimension": {
          "h": 215.99999999999997,
          "w": 384
        },
        "asset_uuid": "98d6ba7c-2079-479c-825f-ff00507a4519",
        "coordinate": {
          "x": 1536.79012345679,
          "y": 497.77777777777777,
          "zIndex": 2
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/479a46f3-04dd-4e86-bb62-cbf45fc57a6a",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 52001,
        "timeline_end": 52002,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      }
    },
    "effects": {},
    "filters": {
      "value": "#ffffffff",
      "preset": ""
    },
    "version": 1.1,
    "bg_color": "00000000",
    "duration": 52003,
    "elements": {},
    "subtitles": {},
    "user_uuid": "cf95ba98-e051-4a60-a34c-3f4cd25bd044",
    "audio_only": false,
    "created_at": "2023-06-21 07:30:54",
    "updated_at": "2023-07-25 08:11:44",
    "subtitle_url": null,
    "thumbnail_url": {
      "42e68f46-f4ea-4cfd-90bb-5b1722e4e36a": {
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/42e68f46-f4ea-4cfd-90bb-5b1722e4e36a?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=UgauPiyidCR1Xtus%2BjPa9dBOY1YJebxW1WjT2bY6T1LDUenZBfdTEnr6eUlCHKYINscpjxA7uZeY1tgr6zTJbl6YX459Ldq9DTV0XA2oUJ%2Fg0%2BlTm%2BwxTtE1mF5dWUAS%2FfSVyQgXvcvCSBPAreZYxwZt3%2F3Tnmn1PIz5Ov6KhWHmkPr9HfhxuSpI6PI327%2B2Hz8CXiWY3VEUgcMtVSu3dG9EIBYAotPv5zlo4jV8e04GUiWFpq8pdkxet5EkukotXaZqgoik%2FFVGN5XbDyzEp4dGweU8Py1Ia8BvbDb9NKbzZrBCD%2BJZMFXRUfQKOZybPDlS6Oa%2Fkx8S53iOeBo9hQ%3D%3D"
      }
    },
    "workspace_uuid": "5a4abed8-4dd8-44f1-a63a-38ad10b99234",
    "templateproject": {},
    "sequence": 4
  }
}


export const settingBackData = {
  "uuid": "2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9",
  "sequence": 4,
  "history": {
    "fps": 24,
    "text": {},
    "asset": {},
    "title": "Your Video - 2f9c8c6fd3e94f0da8755d81e879b6d9",
    "width": 1920,
    "height": 1080,
    "images": {},
    "videos": {},
    "audios": {},
    "audios": {},
    "effects": {},
    "filters": { "preset": "" },
    "version": 1.0,
    "bg_color": "00000000",
    "duration": 0,
    "elements": {},
    "subtitles": {},
    "user_uuid": null,
    "audio_only": false,
    "created_at": "2022-11-30 18:16:35",
    "updated_at": "2022-11-30 18:21:12",
    "subtitle_url": null,
    "thumbnail_url": {},
    "workspace_uuid": '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9'
  },
  "current": {
    "fps": 24,
    "text": {
      "b825bed1-c5e1-44bf-b06e-f91b4be43f94": {
        "alpha": "FF",
        "groups": "b825bed1-c5e1-44bf-b06e-f91b4be43f94",
        "context": "Text",
        "ratioWH": { "h": 1920, "w": 1080 },
        "duration": 3000,
        "rotation": 0,
        "dimension": {
          "h": 513.4642105263157,
          "w": 577.7147368421053
        },
        "coordinate": {
          "x": 326.84210526315786,
          "y": 1199.9984210526316,
          "zIndex": 50
        },
        "is_flipped": 0,
        "properties": {
          "bold": "bold",
          "italic": "italic",
          "alignment": "center",
          "font_size": 40,
          "text_color": "#000000",
          "font_family": "Archivo-Black",
          "background_color": "#ffffff"
        },
        "transition": {
          "end": { "preset": "", "duration": 0 },
          "start": { "preset": "", "duration": 0 }
        },
        "timeline_end": 3000,
        "timeline_start": 0
      },
      "ca28a36f-364e-41ca-832a-c72f0f181795": {
        "alpha": "FF",
        "groups": "ca28a36f-364e-41ca-832a-c72f0f181795",
        "context": "Text",
        "ratioWH": { "h": 1920, "w": 1080 },
        "duration": 3000,
        "rotation": 0,
        "dimension": {
          "h": 540.4736842105262,
          "w": 608.0921052631578
        },
        "coordinate": {
          "x": 71.05263157894737,
          "y": 25.26157894736842,
          "zIndex": 50
        },
        "is_flipped": 0,
        "properties": {
          "bold": "bold",
          "italic": "italic",
          "alignment": "center",
          "font_size": 40,
          "text_color": "#000000",
          "font_family": "Archivo-Black",
          "background_color": "#ffffff"
        },
        "transition": {
          "end": { "preset": "", "duration": 0 },
          "start": { "preset": "", "duration": 0 }
        },
        "timeline_end": 3000,
        "timeline_start": 0
      }
    },
    "asset": {},
    "title": "Your Video - 2f9c8c6fd3e94f0da8755d81e879b6d9",
    "width": 1920,
    "height": 1080,
    "images": {},
    "videos": {},
    "audios": {},
    "effects": {},
    "filters": {
      "preset": ""
    },
    "version": 1.0,
    "bg_color": "00000000",
    "duration": 3000,
    "elements": {},
    "subtitles": {},
    "user_uuid": null,
    "audio_only": false,
    "created_at": "2022-11-30 18:16:35",
    "updated_at": "2022-11-30 18:21:46",
    "subtitle_url": null,
    "thumbnail_url": {},
    "workspace_uuid": '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9'
  }
}