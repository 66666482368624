import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { EmptyCom } from '../EmptyCom';
import { Box, Typography, Grid, Card, FormControlLabel, Switch, TextField } from "@mui/material";
import { deleteAccount,  GetEmailMarketing } from '../../../../axios/ApiProvider'

import "./preferences.scss"

export const Preferences = () => {
    const languageStrs = useSelector((store) => store.editdata.languageStrs)
    const loginState = useSelector((store) => store.maindata.loginState);
    const [userData, setUserData] = useState({})
    const [mailMarket, setMailMarket] = useState(false);
    const [mailNotify, setMailNotify] = useState(false);
    useEffect(() => {
        let tempData = {}
        let item = localStorage.getItem('user_data')
        if (!!item && JSON.parse(item)?.state === 'loggedin') {
          tempData = JSON.parse(item)
        }
    
        setUserData(tempData)
        console.log('saved', tempData);
      }, [loginState])

    useEffect(() => {
        (async() => {
          var marketData = await GetEmailMarketing();
          setMailMarket(marketData.data.marketing_email);
          setMailNotify(marketData.data.notification_email);
        })()
      }, [])

    const onClickMailMarket = async () => {
        //setMailMarket(!mailMarket);
        var marketData = await GetEmailMarketing(!mailMarket, mailNotify);
          setMailMarket(marketData.data.marketing_email);
          setMailNotify(marketData.data.notification_email);
      }
    const onClickMailNotify = async () => {
    //setMailNotify(!mailNotify);
    var marketData = await GetEmailMarketing(mailMarket, !mailNotify);
        setMailMarket(marketData.data.marketing_email);
        setMailNotify(marketData.data.notification_email);
    }


    return (
        <Box className="custom-preference-wrapper">
            <Box className="custom-prefernece-toolbar-section">
                <Box className="toolbar-Breadcrumbs-contain">
                    <Link to={`#`}>{languageStrs.preferences}</Link>
                </Box>
            </Box>
            <Box p={6}>
                {!userData.state && <>
                    <EmptyCom />
                </>}
                {!!userData.state && 
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item md={6} xs={12}>
                                <FormControlLabel className='ml-3'control={<Switch checked={mailMarket} onClick={() => onClickMailMarket()}/>} label={languageStrs.market_emails} />
                                <FormControlLabel className='ml-3'control={<Switch checked={mailNotify} onClick={() => onClickMailNotify()}/>} label={languageStrs.editor_notification} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>                
                }
            </Box>
        </Box>
    )
}