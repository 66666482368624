import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career1 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            React Frontend Engineer
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking a skilled and passionate React Frontend Engineer to join our team. As a Frontend Engineer, you will be responsible for developing and maintaining user interfaces for our web applications using React.js. You will work closely with our backend engineers and designers to implement engaging and responsive user interfaces that deliver an exceptional user experience.

              This is an exciting opportunity to work on cutting-edge projects and contribute to the development of innovative digital solutions. You will have the chance to collaborate with a talented team and leverage your React.js expertise to build user-friendly and scalable web applications.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span>
              <div className='text-15 md:text-18'>
                - Collaborate with cross-functional teams to understand project requirements and translate them into technical specifications
              </div>
              <div className='text-15 md:text-18'>
                - Develop high-quality, reusable, and efficient React.js components and user interfaces
              </div>
              <div className='text-15 md:text-18'>
                - Implement responsive designs and ensure compatibility across different devices and browsers
              </div>
              <div className='text-15 md:text-18'>
                - Optimize application performance and enhance the user experience
              </div>
              <div className='text-15 md:text-18'>
                - Conduct thorough testing and debugging to identify and fix issues
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with backend engineers to integrate frontend interfaces with server-side functionality
              </div>
              <div className='text-15 md:text-18'>
                - Stay up-to-date with the latest frontend technologies, best practices, and trends
              </div>
              <div className='text-15 md:text-18'>
                - Assist with other frontend-related tasks as needed
              </div>
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span>
              <div className='text-15 md:text-18'>
                - Bachelor's degree in Computer Science, Software Engineering, or a related field
              </div>
              <div className='text-15 md:text-18'>
                - Strong proficiency in JavaScript and experience with React.js
              </div>
              <div className='text-15 md:text-18'>
                - Solid understanding of web development principles, including HTML, CSS, and responsive design
              </div>
              <div className='text-15 md:text-18'>
                - Experience with frontend build tools and package managers (e.g., webpack, npm)
              </div>
              <div className='text-15 md:text-18'>
                - Familiarity with version control systems (e.g., Git)
              </div>
              <div className='text-15 md:text-18'>
                - Knowledge of UI/UX principles and best practices
              </div>
              <div className='text-15 md:text-18'>
                - Strong problem-solving and analytical skills
              </div>
              <div className='text-15 md:text-18'>
                - Excellent communication and collaboration abilities
              </div>
              <div className='text-15 md:text-18'>
                - Portfolio or examples of previous work demonstrating proficiency in React.js development
              </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a talented React Frontend Engineer looking for an exciting opportunity to work on innovative projects and contribute to the development of cutting-edge web applications, we would love to hear from you. Join our team and be part of a dynamic and collaborative environment where your skills and expertise will make a difference.

              To apply, please submit your resume and portfolio to info@videoo.io. Please include "React Frontend Engineer Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>

  )
}