import { Box } from '@mui/system';

import './transcribe-video.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import HeroImg from './../../../assets/image/tools/hero-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import { createNewProject } from './../uploadHelper';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Audio to Text.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/add_more_files.jpg';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Audio to Text.png';

export const TranscribeVideo = () => {
    return (
        <>
            <Box className='section-15'>
                <Box className='div-1232 _1300'>
                    <Box className='breadcrumbs'>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>Home</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>Tools</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <Box className='breadcrumb-text-block'>Transcribe Video to Text</Box>
                    </Box>
                </Box>
            </Box>

            <Box className='section-9 directory std-hero-section'>
                <Box className='div-1232'>
                    <Box className='hero-grid'>
                        <Box className='hero-grid-text'>
                            <Box className='hero-header-title'>Vdieo to Text</Box>
                            <Box className='hero-content-title'>Free online Transcribe video tool - Transcribe videos online</Box>

                            <Box onClick={() => {
                                createNewProject("feature-transcribe-video")
                            }} className='choose-btn'>
                                <Box className='choose-btn-text'>Choose Video</Box>
                                <Box className='choose-btn-img'><img src={UploadImg} alt='uploadImg' /></Box>
                            </Box>
                        </Box>

                        <Box className='hero-img'>
                            <Box><img src={HeroImg} alt='heroImg' /></Box>
                        </Box>
                    </Box>

                    {/* <Box className="logos-grid">
                        <Box className="usedByLogos-item">
                            <img src={instagramLogo} alt="instagram logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={youtubeLogo} alt="youtube logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={facebookLogo} alt="facebook logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={twitterLogo} alt="twitter logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={tiktokLogo} alt="tiktok logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={whatsappLogo} alt="whatsapp logo" width={100} />
                        </Box>
                        {{usedLogs.map((logo, index) => (
                            <Box className="usedByLogos-item" key={index}>{logo}</Box>
                        ))} }
                    </Box> */}
                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232'>
                    <Box className='div-block-67'>
                        <h2 className="heading-19">Transcribe Video Online, Free!</h2>

                        <Box className='text-section-block'>
                            <ul className='text-section-ul'>
                                <li className='py-10'>
                                    If you have a video that needs to be converted to a different format, you can use Videoo.io, a cloud-based online video conversion tool.
                                    With Videoo.io, you can convert your videos quickly and easily without the need for expensive software or hardware.
                                </li>

                                <li className='py-10'>
                                    Videoo.io is a user-friendly online video converter that supports a wide range of video formats.
                                    You can access the video converter from anywhere, anytime, and convert your videos to the format of your choice.
                                </li>

                                <li className='py-10'>
                                    Converting a video using Videoo.io is a simple process that can be completed in just a few steps.
                                    First, upload your video to the video converter.
                                    Once your video is uploaded, choose the output format you want to convert your video to.
                                </li>

                                <li className='py-10'>
                                    Videoo.io supports popular video formats such as MP4, AVI, MOV, MKV, and more.
                                    Select the desired output format from the available options.
                                    You can also customize the video settings such as resolution, bitrate, and frame rate if needed.
                                </li>

                                <li className='py-10'>
                                    After selecting the output format and video settings, click the "Convert" button to start the conversion process.
                                    Videoo.io will process your video and convert it to the specified format.
                                    Once the conversion is complete, you can download the converted video to your computer or device.
                                </li>

                                <li className='py-10'>
                                    Converting your video using Videoo.io allows you to adapt your videos to different platforms, devices, or specific requirements.
                                    Whether you need to convert a video for uploading to a website, sharing on social media, or playing on a specific device, Videoo.io has you covered.
                                </li>

                                <li className='py-10'>
                                    Converting a video using Videoo.io is a quick and convenient way to change the format of your videos.
                                    With its user-friendly interface and support for various video formats, Videoo.io is an excellent tool for anyone who needs to convert videos online.
                                    Start converting your videos today and enjoy the flexibility of having your videos in the format you need.
                                </li>
                            </ul>
                        </Box>

                    </Box>
                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232 wide _3'>
                    <Box className='div-block-70'>
                        <Box className='div-block-69'>
                            <h2 className="heading-20">How to Transcribe a Video</h2>
                        </Box>

                        <Box className='div-block-68'>
                            <Box className='how-to-div-item'>
                                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Add Your File</h3>
                                <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Choose Output Format</h3>
                                <p className="how-to-content">Select the output format you want to convert your video to. Videoo.io supports a wide range of formats such as MP4, AVI, MOV, MKV, WEBM and more</p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Transcribe and Download</h3>
                                <p className="how-to-content">Click the "Transcribe" button to start the conversion process. Once the conversion is complete, download the converted video to your computer or device</p>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
