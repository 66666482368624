import './blogs/videooblog/videooblog.scss';
import { Layouts } from '../component/layouts/layouts';

export const Privacy = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Privacy Policy
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              At Videoo.io, we value the privacy and security of our users. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our online cloud-based video editor. By using Videoo.io, you agree to the terms of this Privacy Policy.

              <br /><br />

              <span className='font-semibold'>Information We Collect:</span> When you use Videoo.io, we may collect certain personal information, including but not limited to:
              <ul>
                <li>Name, email address.</li>
                <li>Usage Data: Information about how you use our service, including IP addresses, browser type, operating system, and pages visited.</li>
              </ul>
              We collect this information to provide you with a personalized and enhanced video editing experience.

              <br /><br />

              <span className='font-semibold'>How We Use Your Information:</span> We use the information we collect to:
              <ul>
                <li>Improve and personalize our services.</li>
                <li>Communicate with you regarding updates, promotions, or service-related messages.</li>
                <li>Ensure the security of our platform.</li>
                <li>Perform analytics to understand user trends and preferences.</li>
              </ul>

              <br /><br />

              <span className='font-semibold'>Information Sharing and Disclosure:</span> We do not sell, trade, or otherwise transfer your personal information to third parties without your consent. However, we may share your information with trusted third-party service providers who assist us in operating our platform and delivering our services. These service providers are obligated to maintain the confidentiality and security of your information.

              <br /><br />

              <span className='font-semibold'>Data Security:</span> We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.

              <br /><br />

              <span className='font-semibold'>Third-Party Links:</span> Videoo.io may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to read the privacy policies of those websites before providing any personal information.

              <br /><br />

              <span className='font-semibold'>Children's Privacy:</span> Videoo.io is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take steps to remove that information from our servers.

              <br /><br />

              <span className='font-semibold'>User Rights:</span> You have the right to:
              <ul>
                <li>Access the personal information we hold about you.</li>
                <li>Request corrections to any inaccurate or incomplete personal information.</li>
                <li>Request deletion of your personal information.</li>
                <li>Object to the processing of your personal information.</li>
                <li>Request data portability.</li>
              </ul>

              <br /><br />

              <span className='font-semibold'>Changes to This Privacy Policy:</span> We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised policy will become effective immediately. We encourage you to review this Privacy Policy periodically.

              <br /><br />

              <span className='font-semibold'>Contact Us:</span> If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at info@videoo.io.
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
}
