import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import { Box, Select, MenuItem, Tooltip, Grid, Button, Stack } from "@mui/material";

import './exports.scss';
import PanoramaFishEyeOutlinedIcon from '@mui/icons-material/PanoramaFishEyeOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import GridItem from "./GridItem"
import ListItem from "./ListItem"
import { getExports } from './../../../../axios/ApiProvider';
import { copyObject } from "../../../workspaces/dashboard.config";
import emtpyThumb from '../../../../assets/image/emptyThumb.jpg'
import NodataImage from "../../../../assets/image/no data.png"

const BASE_BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
const BASE_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_URL;

export const Exports = () => {
  const [sortKey, setSortKey] = useState(1);
  const [toolbarStyle, SetToolbarStyle] = useState('grid');
  const ToolbarStyleChange = () => { SetToolbarStyle(toolbarStyle === 'grid' ? 'list' : 'grid'); }

  const [isLoading, setIsLoading] = useState(true);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [pretPageUrl, setPretPageUrl] = useState(null);
  const [projectsArr, setProjectsArr] = useState([]);
  const [sortedArr, setSortedArr] = useState([]);
  const [reloadUrl, setReloadUrl] = useState(BASE_BACKEND_URL + '/user/exports/')

  useEffect(() => {
    (async function () {
      GetExports(BASE_BACKEND_URL + '/user/exports/');
    })();
  }, [])

  useEffect(() => {
    let tempArr = copyObject(projectsArr)

    tempArr.sort((a, b) => {
      let returnValue;
      if (sortKey === 1) returnValue = a.title - b.title
      else if (sortKey === 2) returnValue = (+ new Date(a.updated_at)) - (+ new Date(b.updated_at))
      else if (sortKey === 3) returnValue = (+ new Date(b.updated_at)) - (+ new Date(a.updated_at))
      return returnValue
    })

    setSortedArr(tempArr)
  }, [projectsArr, sortKey])

  const GetExports = async (url) => {
    setIsLoading(false);
    let result = await getExports(url);
    if (result.state === 'success') {
      setProjectsArr(result.data.results);
      setNextPageUrl(result.data.next);
      setPretPageUrl(result.data.previous);
    }
    setIsLoading(true);
  }

  const nextPage = () => { 
    GetExports(nextPageUrl); 
    setReloadUrl(nextPageUrl)
  }
  const prePage = () => { 
    GetExports(pretPageUrl); 
    setReloadUrl(pretPageUrl)
  }

  const onHandleDelete = () => {
    GetExports(reloadUrl)
  }

  return (
    <Box className="custom-projects-wrapper">
      <Box className="custom-projects-toolbar-section">
        <Box className="toolbar-Breadcrumbs-contain">
          <Link to={`#`}>Projects</Link>
        </Box>

        <Box className="toolbar-layoutOption-section">
          {/* <Box className="sortby-wrapper">
            <span className="sortby-title">Sort By</span>

            <Select value={sortKey}
              className="toolbar-SortBy-select"
              onChange={(event) => { setSortKey(event.target.value); }}
            >
              <Box className="toolbar-SortBy-select-header">Sort By</Box>

              <MenuItem value={1} className="toolbar-SortBy-select-item">
                <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                <span>Name</span>
              </MenuItem>

              <MenuItem value={2} className="toolbar-SortBy-select-item">
                <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                <span>Date Created</span>
              </MenuItem>

              <MenuItem value={3} className="toolbar-SortBy-select-item">
                <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                <span>Date Modified</span>
              </MenuItem>
            </Select>
          </Box> */}

          <Box className="projects-toolbar-style-contain" onClick={ToolbarStyleChange}>
            {toolbarStyle === 'grid' ? (
              <Tooltip title="view as list"><FormatListBulletedOutlinedIcon /></Tooltip>
            ) : (
              <Tooltip title="view as grid"><WindowOutlinedIcon /></Tooltip>
            )}
          </Box>
        </Box>
      </Box>

      <Box className='projects-next-preview-btn'>
        <Stack direction="row" spacing={2}>
          <Button onClick={prePage} variant="outlined" startIcon={<SkipPreviousIcon />} disabled={!!pretPageUrl ? false : true}>
            previous
          </Button>

          <Button onClick={nextPage} variant="outlined" endIcon={<SkipNextIcon />} disabled={!!nextPageUrl ? false : true}>
            next
          </Button>
        </Stack>
      </Box>

      <Box className="custom-projects-contents-section">
      {isLoading && sortedArr.length === 0 && 
        <>
          <img src={NodataImage} alt="no data" />
          <h1 className="text-center text-[30px]">You don't have any content here.</h1>
        </>
      } 
      {sortedArr.length !== 0 &&
        <>
          {toolbarStyle === 'grid' ? (
            <Box className="projects-container">
              <Grid container spacing={2}>
                {sortedArr.map((item, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={2.4} key={index}>
                    <GridItem itemData={item} onDelete={onHandleDelete} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box className="list-projects-container">
              <Box className="projects-header-wrapper">
                <Box className="projects-header-NameTableCell1"></Box>
                <Box className="projects-header-NameTableCell1">Name</Box>
                <Box className="projects-header-NameTableCell2">Status</Box>
                <Box className="projects-header-NameTableCell3">Date Modified</Box>
                <Box className="projects-header-NameTableCell4"></Box>
              </Box>

              {sortedArr.map((item, index) => (
                <ListItem itemData={item} key={index} onDelete={onHandleDelete} />
              ))}
            </Box>
          )}
        </>
      }
      </Box>

      {!isLoading && (
        <Box className='absolute top-1/2 left-1/2 -translate-x-1/2'>
          <ReactLoading type={'spinningBubbles'} color='rgb(53 126 221)' height={100} width={100} />
        </Box>
      )}
    </Box >
  )
}