import { matchPath } from "react-router";
import { useLocation, Link } from "react-router-dom";
import { Box } from "@mui/material";

import logoImg from './../../../assets/image/Logo.png';
import { PlanMenu } from "./planMenu";
import { NewVideoMenu } from "./newVideoMenu";
import { SidebarMenuInfo, NewVideoInfo } from './../dashboard.config';

import { GetProjectLink } from "../../../axios/ApiProvider";

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL

const handleCreateNewProject = async () => {
  let result = await GetProjectLink();
  if (!result || result.state == 'error') window.toastr.error(result.data.message);
  else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
}

export const DashboardNavbar = ({ userData }) => {
  const pathname = useLocation().pathname;
  const pathMatch = matchPath({ path: "/dashboard/:name", exact: true, strict: false }, pathname);

  return (
    <Box className="dashboard-sidebar-contain">
      <section className="d-sidebar-section">
        <Box className="d-logoImg">
          <Link to={'..'}>
            <img src={logoImg} alt='logoImg' />
          </Link>
        </Box>

        <Box className="d-sidebar-plan-menu-contain">
          <PlanMenu userData={userData} />
        </Box>

        <Box className="d-sidebar-plan-menu-contain">
          <br />
        </Box>

        {/* <NewVideoMenu /> */}

        <Box className="d-sidebar-menu-items  ">
          {NewVideoInfo.map((item, index) => {
              return (
                <Link to={item.link} key={index}
                  className={`d-sidebar-menu-item ${item.class}`} onClick={handleCreateNewProject}>
                  <Box className="d-sidebar-menu-item-icon">{item.icon}</Box>
                  {item.title}

                  {item.badge.state && (
                    <Box className={`d-sidebar-menu-badge-icon ${item.badge.class}`}>
                      {item.badge.icon}
                    </Box>
                  )}
                </Link>
              )
            })}

          {SidebarMenuInfo.map((item, index) => {
            return (
              <Link to={item.link} key={index}
                className={`d-sidebar-menu-item ${item.class} ${!pathMatch ? index === 0 ? 'active' : '' : pathMatch.params.name === item.link ? 'active' : ''}`}>
                <Box className="d-sidebar-menu-item-icon">{item.icon}</Box>
                {item.title}

                {item.badge.state && (
                  <Box className={`d-sidebar-menu-badge-icon ${item.badge.class}`}>
                    {item.badge.icon}
                  </Box>
                )}
              </Link>
            )
          })}
        </Box>
      </section>
    </Box>
  )
}