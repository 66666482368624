import React, {useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {Box, Menu, MenuItem} from "@mui/material"
import ContentCutIcon  from '@mui/icons-material/ContentCut'
import MoreVertIcon from "@mui/icons-material/MoreVert"

import { deleteAsset } from '../../../../axios/ApiProvider'
import { CalcuDifferentDay } from '../../dashboard.config';
import emtpyThumb from '../../../../assets/image/emptyThumb.jpg'

const GridItem = ({ itemData, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useNavigate();
  const open = Boolean(anchorEl);
  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDeleteAsset = async () => {
    const result = await deleteAsset(itemData.uuid)
    handleClose()
    onDelete()
    if (result.state !== 'success') {
      return window.toastr.error(result.data.message)
    }
  }


  const handleClickItem = (e) => {
    e.preventDefault();
    if(itemData.project_uuid) {
      history(`/asset/view/${itemData.uuid}/${itemData.project_uuid}`);
    } else {
      history(`/asset/view/${itemData.uuid}`);
    }
  }
  return (
    <Box className="projects-item">
      <a href="#!" onClick={handleClickItem} className="projectTitle-ThumbnailWrapper">
        <Box className="projectTitle-ThumbnailActions-section">
          <Box className="ThumbnailAction__ThumbnailActionWrapper">
            <ContentCutIcon /> Edit
          </Box>
        </Box>

        <Box className="projectTitle-ThumbnailBackgroundsContainer">
          {itemData?.export_thumbnail && (
            <img alt='project-thumbnail'
              src={itemData.export_thumbnail}
              className="projectTitle-ThumbnailImage-section"
            />
          )}

          {!itemData?.export_thumbnail && (
            <img src={emtpyThumb}
              alt='project-thumbnail'
              className="projectTitle-ThumbnailImage-section"
            />
          )}

          <Box className="projectTitle-ThumbnailImageBackgroundOverlay" />

          {itemData?.export_thumbnail && (
            <img alt='project-thumbnail'
              src={itemData.export_thumbnail}
              className="projectTitle-ThumbnailImageBackground"
            />
          )}

          {!itemData?.export_thumbnail && (
            <img src={emtpyThumb}
              alt='project-thumbnail'
              className="projectTitle-ThumbnailImageBackground"
            />
          )}
        </Box>
      </a>

      <Box className="projectsTitle-TileInfoWrapper">
        <h1 className="TileInfoWrapper-header">{itemData.title}</h1>
        <div className="TileInfoWrapper-TileInfoTime">{CalcuDifferentDay(itemData.created_at)}</div>
        <Box className="TileInfoWrapper-TileInfoActions">
          <Box className="TileInfoActions-btn" onClick={handleClickMore}>
            <MoreVertIcon />
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClickDeleteAsset}>Delete Transcode</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  )
}

export default GridItem
