import React from 'react';
import { useReducer, useMemo } from "react";
import { createContext, useContext } from "react";

const INIT_STATE = {
  loading: false,
  userData: null,
}

// create context
const GlobalContext = createContext({});
const reducer = (state, { type, payload }) => (
  { ...state, [type]: payload }
)

// use contexts
export function useGlobalContext() {
  return useContext(GlobalContext);
}

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const onLoading = (flag) => {
    dispatch({ type: 'loading', payload: flag })
  }

  return (
    <GlobalContext.Provider
      value={useMemo(() => [
        state, {
          dispatch,
          onLoading,
        }
      ], [state])}
    >
      {children}
    </GlobalContext.Provider>
  )
}
