import { Box } from '@mui/system';

import './cut-video.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import { createNewProject } from './../uploadHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Cut Video.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Cut Video.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Cut.png';

export const CutVideo = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
      <Box className='section-15'>
        <Box className='div-1232 _1300'>
          <Box className='breadcrumbs'>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Videoo</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Tools</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <Box className='breadcrumb-text-block'>Video Trimmer</Box>
          </Box>
        </Box>
      </Box>

      <Box className='section-9 directory std-hero-section'>
        <Box className='div-1232'>
          <Box className='hero-grid'>
            <Box className='hero-grid-text'>
              <Box className='hero-header-title'>Video Trimmer Online</Box>
              <Box className='hero-content-title'>Free online video editing software - create videos online</Box>

              <Box onClick={() => {
                createNewProject("feature-cut-video")
              }} className='choose-btn'>
                <Box className='choose-btn-text'>Choose Video</Box>
                <Box className='choose-btn-img'>
                  <img src={UploadImg} alt='uploadImg' />
                </Box>
              </Box>
            </Box>
            <Box className='hero-img'>
              <iframe
                  src={`https://www.youtube.com/embed/R1Tyb-oSTFU`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                  style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
              />
            </Box>
          </Box>

          {/* <Box className="logos-grid">
              <Box className="usedByLogos-item">
                  <img src={instagramLogo} alt="instagram logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={youtubeLogo} alt="youtube logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={facebookLogo} alt="facebook logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={twitterLogo} alt="twitter logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={tiktokLogo} alt="tiktok logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={whatsappLogo} alt="whatsapp logo" width={100} />
              </Box>
              {{usedLogs.map((logo, index) => (
                  <Box className="usedByLogos-item" key={index}>{logo}</Box>
              ))}}
          </Box> */}
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232'>
          <Box className='div-block-67'>
            <h2 className="heading-19">Cut Video Online, Free!</h2>
            <Box className='text-section-block'>
              <ul className='text-section-ul'>
                <li className='py-10'>
                If you need to trim or cut a long video into smaller segments, you can easily do it using Videoo.io, a cloud-based online video editor. Cutting a video into segments is a simple process that can be completed in just a few clicks.
                Videoo.io offers a user-friendly interface that allows you to split your videos into multiple segments. You can access the video editor from anywhere, anytime, and split your video to your desired length.

                Here are the steps to split a video using Videoo.io:
                </li>

                <li className='py-10'>
                Upload your video: Start by logging in to your Videoo.io account and uploading your video file to the editor.
                </li>
                <li className='py-10'>
                Add the video to the timeline: Once your video is uploaded, drag and drop it onto the timeline. You will see the video appear as a series of clips on the timeline.
                </li>
                <li className='py-10'>
                Select the portion of the video to split: Click and drag the edges of the clip to select the portion of the video you want to split. You can also use the playback controls below the preview screen to play, pause, or scrub through your video and find the exact point you want to split.
                </li>
                <li className='py-10'>
                Split the video: Once you have selected the portion of the video you want to split, click on the "Split" button in the toolbar above the timeline. You will see the video split into two separate clips.
                </li>
                <li className='py-10'>
                Delete the unwanted portion: Click on the unwanted portion of the video to select it, and then hit the Delete key on your keyboard to remove it. You can also use the Trim tool to adjust the edges of the remaining clips to fit your desired length.
                </li>
                <li className='py-10'>
                Repeat the process: If you want to split the video into more segments, repeat steps 3 and 4 until you have the desired number of segments.
                </li>
                <li className='py-10'>
                Preview and save your edited video: Once you have split your video into multiple segments, preview it to ensure that it looks the way you want it to. You can also add effects, transitions, text overlays, and other elements to make your video more engaging and professional-looking. If you are happy with the result, save your edited video to your computer or directly to your social media or website.
                </li>
                <li className='py-10'>
                  In conclusion, splitting a video using Videoo.io is a simple and effective way to improve the quality of your video and optimize it for your website or social media channels. With its easy-to-use interface and additional features, Videoo.io is a great tool for anyone looking to create high-quality, professional-looking videos.
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232 wide _3'>
          <Box className='div-block-70'>
            <Box className='div-block-69'>
              <h2 className="heading-20">How to Cut a Video</h2>
            </Box>

            <Box className='div-block-68'>
              <Box className='how-to-div-item'>
                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Your File</h3>
                <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Cut Video</h3>
                <p className="how-to-content">Simply click on the video’s image and drag the corners to adjust. Or click ‘Settings’ and choose a preset canvas size (like ‘YouTube’, or ‘Instagram Stories’) to cut automatically</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Share!</h3>
                <p className="how-to-content">That’s it. You’re ready to share your video with your friends and followers. Hit ‘Export’ and your video will start to download</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
   
  )
}