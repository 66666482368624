import { VIDEOPLAYING, ZOOMRATE, TIMECOUNT, VIDEOBUFFERING, VIDEOPLAYSTARTED } from "../constants/videoPlayConst";

export const videoPlayerStore = (state = {}, action) => {
  switch (action.type) {
    case VIDEOPLAYING: {
      return { ...state, videoPlaying: action.payload };
    }

    case ZOOMRATE: {
      return { ...state, zoomRate: action.payload };
    }

    case TIMECOUNT: {
      return { ...state, Time_Count: action.payload };
    }

    case VIDEOBUFFERING: {
      return { ...state, videoBuffering: action.payload };
    }

    case VIDEOPLAYSTARTED: {
      return { ...state, videoPlayerStarted: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
