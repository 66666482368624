import { Box } from '@mui/system';

import './join-merge.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import { createNewProject } from './../uploadHelper';
import { Footer } from '../../../component/home-footer/footer';
import { Header } from '../../../component/home-header/header';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Join-Merge.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Join-Merge Video.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Join-Merge.png';

export const JoinMergeVideo = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
      <Box className='section-15'>
        <Box className='div-1232 _1300'>
          <Box className='breadcrumbs'>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Vdieoo</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Tools</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <Box className='breadcrumb-text-block'>Merge Videos</Box>
          </Box>
        </Box>
      </Box>

      <Box className='section-9 directory std-hero-section'>
        <Box className='div-1232'>
          <Box className='hero-grid'>
            <Box className='hero-grid-text'>
              <Box className='hero-header-title'>Merge Videos Online</Box>
              <Box className='hero-content-title'>Free online video editing software - create videos online</Box>

              <Box onClick={() => {
                createNewProject("feature-joinmerge-video")
              }} className='choose-btn'>
                <Box className='choose-btn-text'>Choose Video</Box>
                <Box className='choose-btn-img'>
                  <img src={UploadImg} alt='uploadImg' />
                </Box>
              </Box>
            </Box>
            <Box className='hero-img'>
              <iframe
                  src={`https://www.youtube.com/embed/OXvC_XKgKzM`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                  style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
              />
            </Box>
          </Box>

          {/* <Box className="logos-grid">
              <Box className="usedByLogos-item">
                  <img src={instagramLogo} alt="instagram logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={youtubeLogo} alt="youtube logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={facebookLogo} alt="facebook logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={twitterLogo} alt="twitter logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={tiktokLogo} alt="tiktok logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={whatsappLogo} alt="whatsapp logo" width={100} />
              </Box>
              {{usedLogs.map((logo, index) => (
                  <Box className="usedByLogos-item" key={index}>{logo}</Box>
              ))}}
          </Box> */}
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232'>
          <Box className='div-block-67'>
            <h2 className="heading-19">Join/Merge Video Online, Free!</h2>
            <Box className='text-section-block'>
              <ul className='text-section-ul'>
                <li className='py-10'>
                  If you have multiple video clips that you want to merge into one video, you can easily do it using Videoo.io, a cloud-based online video editor. Joining or merging videos is a simple process that can be completed in just a few clicks.

                  Videoo.io offers a user-friendly interface that allows you to merge your video clips into one seamless video. You can access the video editor from anywhere, anytime, and join your videos to create a cohesive final product.

                  Here are the steps to merge videos using Videoo.io:
                </li>

                <li className='py-10'>
                  1. Upload your video clips: Start by logging in to your Videoo.io account and uploading your video clips to the editor.
                </li>
                <li className='py-10'>
                  2. Add the video clips to the timeline: Once your video clips are uploaded, drag and drop them onto the timeline in the order that you want them to appear in the final video.
                </li>
                <li className='py-10'>
                  3. Arrange the clips: Click and drag the clips on the timeline to arrange them in the order that you want them to appear in the final video.
                </li>
                <li className='py-10'>
                  4. Join the clips: Once you have arranged the clips, click on the "Join" button in the toolbar above the timeline. You will see the clips merge into one seamless video.
                </li>
                <li className='py-10'>
                  5. Add effects and transitions: If you want to add effects, transitions, text overlays, or other elements to your video, you can do so using Videoo.io's additional features. You can customize your video to make it more engaging and professional-looking.
                </li>
                <li className='py-10'>
                  6. Preview and save your edited video: Once you have merged your video clips into one video and added any desired effects or transitions, preview your video to ensure that it looks the way you want it to. If you are happy with the result, save your edited video to your computer or directly to your social media or website.
                </li>
                <li className='py-10'>
                  In conclusion, merging video clips using Videoo.io is a simple and effective way to create a cohesive final product. With its easy-to-use interface and additional features, Videoo.io is a great tool for anyone looking to create high-quality, professional-looking videos.
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232 wide _3'>
          <Box className='div-block-70'>
            <Box className='div-block-69'>
              <h2 className="heading-20">How to Join/Merge a Video</h2>
            </Box>

            <Box className='div-block-68'>
              <Box className='how-to-div-item'>
                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Your File</h3>
                <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Join/Merge Video</h3>
                <p className="how-to-content">Simply click on the video’s image and drag the corners to adjust. Or click ‘Settings’ and choose a preset canvas size (like ‘YouTube’, or ‘Instagram Stories’) to merge automatically</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Share!</h3>
                <p className="how-to-content">That’s it. You’re ready to share your video with your friends and followers. Hit ‘Export’ and your video will start to download</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
   
  )
}