import { Box } from "@mui/material"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import './footer.scss';
import Flogoimg from '../../assets/image/FLogo.png';
import { getBranchName } from "../../axios/ApiProvider"
import { GetProjectLink } from "../../axios/ApiProvider"
import { BASE_BACKEND_URL } from '../../axios/ApiProvider'
const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const Footer = () => {
  const [branch, setBranch] = useState('')
  useEffect(() => { getbranch() }, [])

  const getbranch = async () => {
    let branchName = ''
    let response = await getBranchName()
    if (response.state === 'success') {
      let resData = response.data
      branchName = resData?.render_branch
    }

    setBranch(branchName || '')
  }

  const onClick = async () => {
    let result = await GetProjectLink();
    if (!result || result.state == 'error') window.toastr.error(result.data.message);
    else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}`
  }


  return (
    <Box className="Footer">
      <Box className="Footer-content">
        <Box className="Footer-Item">
          <Box className="FLogo">
            <img src={Flogoimg} alt='footer-logo' />
          </Box>

          <Box className="FContent-1">
            Easily create visually appealing videos, incorporate subtitles, and increase your audience reach with simplicity.
          </Box>

          <Box className="social-link">
            <Box className="social-footer">
              <a target="_blank"
                className="social-link-1"
                href="https://www.youtube.com/@videoo-io"
              />

              <a target="_blank"
                className="social-link-2"
                href="https://www.twitter.com/VideooTeam"
              />

              <a target="_blank"
                className="social-link-3"
                href="https://www.instagram.com/VideooTeam"
              />

              <a target="_blank"
                className="social-link-4"
                href="mailto:info@videoo.io"
              />
            </Box>

            <Box className="text-rights">©Copyright 2024</Box>
            <Box className="text-rights">VIDEOO LIMITED</Box>
            <Box className="text-rights">C/O Fdca-Office 2 Bennet's House, 21 Leyton Road, Harpenden, England, AL5 2HU</Box>
            <Box className="text-rights">info@videoo.io</Box>
          </Box>
        </Box>

        <Box className="Footer-Item">
          <Box className="fHeader">Tools</Box>

          <Box className="fContent">
            <a onClick={onClick}>Video Editor</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}>Subtitles</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}> Screen Recorder</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}>Video Compressor</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}>Video Converter Software</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}> All Tools</a>
          </Box>
        </Box>

        <Box className="Footer-Item">
          <Box className="fHeader">Create</Box>

          <Box className="fContent">
            <a onClick={onClick}>Social Media Videos</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}>Business Videos</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}>Promo Videos</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}>All Create</a>
          </Box>
        </Box>

        <Box className="Footer-Item">
          <Box className="fHeader">Product</Box>

          <Box className="fContent">
            <a href="/pricing">Pricing</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}>Enterprise</a>
          </Box>

          <Box className="fContent">
            <a onClick={onClick}>English US</a>
          </Box>
        </Box>

        <Box className="Footer-Item">
          <Box className="fHeader">Resources</Box>

          <Box className="fContent">
            <a href="/videooblog">VIDEOO Blog</a>
          </Box>

          <Box className="fContent">
            <a href="/videoohowto">YouTube How to</a>
          </Box>
        </Box>

        <Box className="Footer-Item">
          <Box className="fHeader">Company</Box>

          <Box className="fContent">
            <a href="/videoocareers">Careers</a>
          </Box>

          <Box className="fContent">
            <a href="/privacy">Privacy</a>
          </Box>

          <Box className="fContent">
            <a href="/terms">Terms</a>
          </Box>

          <Box className="fContent">
            <a href="/security">Security</a>
          </Box>

          <Box className="fContent">
            <a href="/cookies">Cookies</a>
          </Box>
        </Box>
      </Box>

      <div className="flex flex-col gap-5 mt-30 opacity-80">
        <span className="text-white">{BASE_BACKEND_URL}</span>
        <span className="text-white">{branch}</span>
      </div>
    </Box>
  )
}