import { mainStore } from './mainReducer';
import { videoEditStore } from './editReducer';
import { videoPlayerStore } from './videoPlayer';
import { webglStore } from './webglReducer';
import { projectStore } from './projectReducer';

function rootReducer(state = {}, action) {
	return {
		maindata: mainStore(state.maindata, action),
		editdata: videoEditStore(state.editdata, action),
		videodata: videoPlayerStore(state.videodata, action),
		webgldata: webglStore(state.webgldata, action),
		projectdata: projectStore(state.projectdata, action)
	};
}
export default rootReducer;
