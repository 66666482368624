import { Box } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";

import './more.scss';
import { Header } from "../../component/home-header/header";
import { Footer } from "../../component/home-footer/footer";
import {ConvertVideo} from "./convert-video/convert-video"
import {CompressVideo} from "./compress-video/compress-video"
import {TranscribeVideo} from "./transcribe-video/transcribe-video";
export const More = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
        <Routes>
          <Route path="/video-converter" element={<ConvertVideo />} />
          <Route path="/video-compressor" element={<CompressVideo />} />
          <Route path="/transcribe-video" element={<TranscribeVideo />}></Route>
          <Route path="/" element={<Navigate replace to="/tools/crop-video" />} />
        </Routes>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
  )
}