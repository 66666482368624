import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export const MuiThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}