import { Box, Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import './compress-video.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';

import { createNewProject } from './../uploadHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1 = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Compress Video.png';
export const how_to_2 = BASE_STATIC_STORAGE_URL + '/images/features/Compress Video.png';
export const how_to_3 = BASE_STATIC_STORAGE_URL + '/images/features/Export Compress Video.png';

const VideooEditor_AddTransitions_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Transition%20to%20Video.png'
const VideooEditor_AddMusic_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Music%20to%20Video%202.png'
const VideooEditor_AddText_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Text%20to%20Video.png'
const VideooEditor_AddStock_Image = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Stock.png'

const VideooEditor_CompressVideo_Step1 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-1-MainPage.png'
const VideooEditor_CompressVideo_Step2 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-2-CompressPage.png'
const VideooEditor_CompressVideo_Step3 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-3-SelectVideoType.png'
const VideooEditor_CompressVideo_Step4 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-4-Options.png'
const VideooEditor_CompressVideo_Step5 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-5-OptimizeForSocialMedia.png'
const VideooEditor_CompressVideo_Step6 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-6-CompressionLevel.png'
const VideooEditor_CompressVideo_Step7 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-7-Resolution.png'
const VideooEditor_CompressVideo_Step8 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-8-FramePerSecond.png'
const VideooEditor_CompressVideo_Step9 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-9-WaitUntilUploadFinish.png'
const VideooEditor_CompressVideo_Step10 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-10-CompressButtonReady.png'
const VideooEditor_CompressVideo_Step11 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-11-CompressingProcess.png'
const VideooEditor_CompressVideo_Step12 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-12-CompressVideoReady.png'
const VideooEditor_CompressVideo_Step13 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-CompressVideo-13-AllCompressedVideos.png'

export const CompressVideo = () => {
    return (
        <Box className="T_containner">
            <Box className="Header"><Header /></Box>

            <Box className="content-containner">
                <Box className="section-15" sx={{ marginTop: '4rem' }}>
                    <Box className="div-1232">
                        <Typography variant="h3" gutterBottom fontWeight={'bold'}>
                            Video Compressor
                        </Typography>
                    </Box>
                    <Box className="div-1232" sx={{ marginBottom: '1rem' }}>
                        <Typography variant="body1" gutterBottom >
                            Efficiently reduce your video file size while maintaining high quality with Videoo’s advanced video compression technology.
                        </Typography>
                    </Box>
                    <Box className="div-1232" onClick={() => {
                        createNewProject("feature-compress-video")
                    }} >
                        <Button variant="contained" className="create-now">Compress Video</Button>
                    </Box>
                </Box>
                <Box className='section-15 pt-12'>
                    <Box className='div-1232'>
                        <Box className='breadcrumbs'>
                            <a href={'/'}>
                                <Box className='breadcrumb-text-block'>Videoo</Box>
                            </a>
                            <Box className='breadcrumb-arrow'></Box>
                            <a href={'/'}>
                                <Box className='breadcrumb-text-block'>Video Editor</Box>
                            </a>
                            <Box className='breadcrumb-arrow'></Box>
                            <a href={'/'}>
                                <Box className='breadcrumb-text-block'>Video Compressor</Box>
                            </a>
                        </Box>
                    </Box>
                </Box>

                <Box className="section-9 directory std-hero-section" sx={{ paddingY: '3rem' }}>
                    <Box className="div-1232">
                        <Box className="text-center">
                            <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>How to Compress Video in Simple Actions</Typography>
                        </Box>
                        <Box className="text-center" sx={{ marginBottom: '3rem' }}>
                            <Typography variant="body1" gutterBottom >In three simple actions, you will be able to compress your videos using Videoo.io</Typography>
                        </Box>
                        <Box className="container">
                            <Grid container spacing={3}>
                                <Grid item md={4} xs={12} className="text-center">
                                    <Card className="py-6">
                                        <Box className="text-center py-4">
                                            <img className="mx-auto" src={how_to_1} />
                                        </Box>
                                        <Box className="text-center py-4">
                                            <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Action 1</Typography>
                                        </Box>
                                        <Box className="text-center py-4">
                                            <Typography variant="h5" gutterBottom >Upload Your Video</Typography>
                                        </Box>
                                        <Typography variant="body1" gutterBottom>
                                            Select your video and upload it. You can drag & drop, or choose the file from your computer or mobile device
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item md={4} xs={12} className="text-center">
                                    <Card className="py-6">
                                        <Box className="text-center py-4">
                                            <img className="mx-auto" src={how_to_2} />
                                        </Box>
                                        <Box className="text-center py-4">
                                            <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Action 2</Typography>
                                        </Box>
                                        <Box className="text-center py-4">
                                            <Typography variant="h5" gutterBottom >Compress It</Typography>
                                        </Box>
                                        <Typography variant="body1" gutterBottom>
                                            Choose the compression settings such as resolution, bitrate, and format to reduce the file size while maintaining video quality
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item md={4} xs={12} className="text-center">
                                    <Card className="py-6">
                                        <Box className="text-center py-4">
                                            <img className="mx-auto" src={how_to_3} />
                                        </Box>
                                        <Box className="text-center py-4">
                                            <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Action 3</Typography>
                                        </Box>
                                        <Box className="text-center py-4">
                                            <Typography variant="h5" gutterBottom >Download or Share Now</Typography>
                                        </Box>
                                        <Typography variant="body1" gutterBottom>
                                            Save the compressed video to your computer or directly share it on social media or websites
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <Box className="section-9 directory std-hero-section" sx={{ paddingY: '3rem' }}>
                    <Box className="div-1232">
                        <Box className="text-center">
                            <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Benefits of Compressed Videos</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center', marginBottom: '3rem' }}>
                            <Typography variant="body1" gutterBottom >Video Compressor Helps You To:</Typography>
                        </Box>
                        <Box className="container">
                            <Grid container spacing={3}>
                                <Grid item md={4} xs={12}>
                                    <Box>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Save Storage</Typography>
                                    </Box>
                                    <Box className="my-10">
                                        <Typography variant="body1" gutterBottom>
                                            Videoo’s Video Compressor helps you save valuable storage space by significantly reducing the file size of your videos without compromising on quality. This is especially beneficial for content creators, businesses, and individuals who need to store large volumes of video files. By compressing your videos, you can free up storage space on your devices, making it easier to manage and organize your media library.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Whether you are working with high-resolution videos or extensive video libraries, Videoo’s advanced compression algorithms ensure that your files are optimized for efficient storage. This means you can store more videos on your devices, reducing the need for additional storage solutions and saving costs.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Also, some services such as <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>WhatsApp</a> have limitations on video sizes while uploading, so Videoo.io's Compressor Tool can help you to compress your video to fit the size limit.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Box>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Upload Faster</Typography>
                                    </Box>
                                    <Box className="my-10">
                                        <Typography variant="body1" gutterBottom>
                                            Videoo’s Video Compressor allows you to upload videos faster by reducing their file size without compromising quality. This feature is particularly beneficial for content creators and businesses that frequently share videos on Social Media.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            By compressing your videos, you can significantly reduce the time it takes to upload them, even if you have a slower internet connection. This means you can get your content online quickly, keeping your audience engaged and your workflow smooth.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Faster uploads also mean less waiting time, allowing you to focus on creating more content and interacting with your audience. Whether you’re sharing short clips or full-length videos, Videoo’s Video Compressor ensures your files are optimized for quick and efficient uploads.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Experience the convenience of faster uploads with Videoo’s Video Compressor, helping you save time and increase productivity while maintaining the quality of your videos.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Box>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Stream Smoothly</Typography>
                                    </Box>
                                    <Box className="my-10">
                                        <Typography variant="body1" gutterBottom>
                                            Videoo’s Video Compressor ensures that your videos stream smoothly, providing an uninterrupted and high-quality viewing experience for your audience. By reducing the file size without compromising on quality, our compressor optimizes videos for streaming across various platforms and devices.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Smaller file sizes mean quicker buffering and less lag, which is especially important for viewers with slower internet connections. Whether your audience is watching on <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>YouTube</a>, <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Facebook</a>, <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Instagram</a>, or any other platform, Videoo’s Video Compressor ensures your content plays back smoothly and without interruptions.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Optimized videos also help reduce data usage, making it easier for viewers to watch your content on mobile devices without worrying about exceeding their data limits.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Experience the benefits of smooth streaming with Videoo’s Video Compressor, delivering your high-quality videos seamlessly to audiences everywhere.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <Box className="section-9 directory std-hero-section" >
                    <Box className="div-1232">
                        <Box className="text-center">
                            <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>How to Compress a Video, a step-by-step Guide</Typography>
                        </Box>
                        <Box className="text-center" sx={{ marginBottom: '3rem' }}>
                            <Typography variant="body1" gutterBottom >You can use AI Compressor Tool from Videoo.io to compress your videos.</Typography>
                        </Box>

                        <Box className="section-9 directory std-hero-section" >
                            <Box className="div-1232">
                                <Box className="container">
                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step1} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 1 - Select Compress Video Tool</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    - Signup & Login to Videoo.io
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    - Go to Tool Section on the header. Select Video Compressor Tool
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step2} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 2 - Click Compessor Button</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    - In this page, you will find detailed information about Video Compressor Tool by Videoo.io
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    - Just click "Compress Now" button.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step3} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 3 - Select Your Video</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    - Now, you are in new your new video editing project for compressing your videos. 
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    - Either select a video from your device, or record a Webcam or Screen Capture video.
                                                </Typography>
                                                <Typography>
                                                    - One important note: Keep in mind that, Compress menu will be in "Preparing..." mode, until your video finishes uploading.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step4} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 4 - Compressor Options</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    When you access the Options popup in Videoo’s Video Compressor, you'll find a range of settings to customize your video compression process:
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong> - Select Compression Level</strong> – Choose between High, Medium, and Low compression levels to balance between file size and video quality.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong> - Change Resolution</strong> – Adjust the video resolution to fit your needs, with options ranging from 4K Ultra HD (3840x2160) to TV (640x480).
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong> - Adjust FPS</strong> – Modify the frames per second (FPS) to ensure smooth playback. Options include 60 FPS, 30 FPS, 24 FPS, and 15 FPS.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong> - Optimize for Platform</strong> – Select the social media platform you are targeting, to automatically apply the best settings.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step5} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 5 - Optimize For Social Media</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    Videoo’s Video Compressor includes an "Optimize for Social Media" feature to ensure your videos are perfectly suited for various platforms.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong> - Select a Social Media Platform</strong> : Choose from a list of supported social media platforms, including <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>WhatsApp</a>, <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Instagram</a>, <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Facebook</a>, <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>TikTok</a>, <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Twitter</a>, and <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>LinkedIn</a>.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong> - Apply Platform-Specific Settings</strong> : Once a platform is selected, Videoo automatically applies the optimal settings for resolution, compression level, and frame rate to meet the specific requirements of that platform.
                                                </Typography>
                                            </Box>

                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step6} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 6 - Compression Level</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    Videoo’s Video Compressor offers flexible Compression Level options to help you balance video quality and file size. Select Compression Level: Choose from three compression levels: High, Medium, and Low. Each level provides a different balance between video quality and file size.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong> - High Compression</strong> : Opt for High compression to significantly reduce file size. This is ideal for saving storage space and speeding up uploads, though it may result in some quality loss.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong> - Medium Compression</strong> : Choose Medium compression for a balanced approach. This setting reduces file size while maintaining good video quality, suitable for most general purposes.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong> - Low Compression</strong> : Select Low compression to retain the highest video quality with minimal file size reduction. This is best for videos where maintaining original quality is crucial.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step7} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 7 - Selecting new Resolution</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    Select Resolution – In the popup, you’ll find a list of available resolutions. Choose the one that best fits your requirements to upscale or downscale.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Choose 4K Ultra HD – Select 4K Ultra HD (3840x2160) for the highest quality, suitable for high-definition displays and professional use.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Choose 1440p HD – Select 1440p HD (2560x1440) for a balance of high quality and reduced file size, perfect for most modern displays.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Choose 1080p HD – Select 1080p HD (1920x1080) for standard high-definition quality, widely compatible with various devices and platforms.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Choose Lower Resolutions – For smaller file sizes or specific use cases, choose 720p HD (1280x720), 480p DVD (720x480), 576p (720x576), or TV (640x480).
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step8} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 8 - Changing FPS of Video)</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    Videoo’s Video Compressor allows you to adjust the frames per second (FPS) to optimize video playback quality and file size.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Select FPS – In the popup, you’ll find a list of available frame rates. Choose the one that best fits your needs.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Choose 60 FPS – Ideal for high-motion content, such as gaming videos and sports footage, providing the smoothest playback.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Choose 30 FPS – Standard frame rate for most online videos, offering a good balance between smoothness and file size.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Choose 24 FPS – Traditional frame rate for cinematic videos, giving your content a film-like appearance.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Choose Lower FPS – For reducing file size and bandwidth usage, consider 12 FPS, which is suitable for simpler animations or low-motion content.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step9} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 9 - Wait for Video to Upload</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body2" gutterBottom>
                                                    - Your options are ready, but you might still need to wait.
                                                </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    - If your video is slightly bigger, than, you need to wait for your video to upload fully.
                                                </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    - When your video is uploaded, The "Preparing..." button will transition into "Compress".
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step10} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 10 - Ready to Compress</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body2" gutterBottom>
                                                    WHen your video is uploaded, now you can press "Compress" button.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step11} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 11 - Compressing your Video</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body2" gutterBottom>
                                                    Now, you should wait until Compressing operation finishes.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step12} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 12 - Compress Output</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong> - </strong> Once the compress is complete, you will be prompted to download to your device or share the video on social media.
                                                </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    By following these steps, you can easily compress and fine-tune your video to use in different social media platforms.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item md={6} xs={12} >
                                            <img width={'100%'} src={VideooEditor_CompressVideo_Step13} />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <Box sx={{ paddingBottom: '0.5rem' }}>
                                                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 13 - Browsing all Compressed Videos</Typography>
                                            </Box>
                                            <Box sx={{ paddingBottom: '1rem' }}>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong> - </strong> Also, you can access to all your compressed files from the main menu in Videoo.io, in "Your Compress" videos section.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className="section-9 directory std-hero-section" >
                    <Box className="div-1232">
                        <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} >Features of Videoo’s Video Compressor</Typography>
                        <Box className="container py-15" sx={{ marginTop: '2rem' }}>
                            <Grid container spacing={4}>
                                <Grid item md={6} xs={12}>
                                    <Box className="mb-5">
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                                            AI-Powered Hassle-free Video Compression
                                        </Typography>
                                    </Box>
                                    <Box className="py-10">
                                        <Box>
                                            <Typography variant="body1" gutterBottom>
                                                Videoo’s Video Compressor leverages advanced AI technology to provide a hassle-free video compression experience. Our AI-powered tool intelligently analyzes your video content to determine the optimal compression settings, ensuring minimal quality loss while significantly reducing file size. This makes it easier than ever to prepare your videos for sharing on various platforms without worrying about manual adjustments or complex settings.
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                With AI-powered compression, Videoo automatically adjusts parameters such as resolution, bitrate, and frame rate to match the specific requirements of different social media platforms. Whether you’re optimizing videos for <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Facebook</a>, <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>TikTok</a> or other social media services, our compressor ensures your content meets platform standards and looks its best.
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                The intuitive interface allows you to compress videos with just a few clicks, eliminating the need for technical expertise. Experience the convenience and efficiency of AI-powered video compression with Videoo, making video optimization simpler and faster than ever before.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box className="mb-5">
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                                            No Loss in Quality
                                        </Typography>
                                    </Box>
                                    <Box className="py-10">
                                        <Typography variant="body1" gutterBottom>
                                            Videoo’s Video Compressor is designed to reduce file sizes without compromising the quality of your videos. Our advanced compression algorithms ensure that your videos maintain their original clarity, sharpness, and detail, even after significant size reduction. This means you can share and upload your videos faster while still delivering a professional and visually stunning experience to your audience.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Whether you're optimizing videos for <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>WhatsApp</a>, <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Instagram</a> or other social media platforms, our compressor ensures your content meets platform standards and looks its best.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            By preserving the original quality, Videoo’s Video Compressor allows you to showcase your creativity and message without any degradation. You no longer have to choose between high quality and manageable file sizes – with Videoo, you get both.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Enjoy the benefits of high-quality video compression that keeps your content looking as crisp and vibrant as the original, ensuring a top-notch viewing experience every time.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Box className="mb-5">
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                                            Resolution Changer
                                        </Typography>
                                    </Box>
                                    <Box className="py-10">
                                        <Typography variant="body1" gutterBottom>
                                            Videoo’s Video Compressor includes a powerful Resolution Changer feature, allowing you to easily adjust the resolution of your videos to meet different needs and requirements. Whether you're preparing content for social media, web streaming, or high-definition displays, the Resolution Changer gives you the flexibility to select the best resolution for your project.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            The available resolution options include:
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 4K Ultra HD (3840x2160)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 1440p HD (2560x1440)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 1080p HD (1920x1080)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 720p HD (1280x720)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 480p DVD (720x480)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 576p (720x576)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - TV (640x480)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            With these options, you can optimize your video for any platform, ensuring the best possible viewing experience. Whether you're compressing videos for <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Twitter</a>, or <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>LinkedIn</a> or any other website, Videoo’s Resolution Changer ensures your content is always presented in the optimal resolution.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Adjusting the resolution is simple and intuitive. Just select the desired resolution from the dropdown menu, and Videoo will automatically adjust your video's resolution while maintaining the best possible quality. This feature is perfect for optimizing video performance and compatibility across various devices and platforms.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Experience the convenience and flexibility of Videoo’s Resolution Changer, making it easier than ever to create high-quality videos tailored to your specific needs.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Box className="mb-5">
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                                            Frame per Second (FPS) Changer
                                        </Typography>
                                    </Box>
                                    <Box className="py-10">
                                        <Typography variant="body1" gutterBottom>
                                            Videoo’s Video Compressor includes a versatile FPS Changer feature, allowing you to adjust the frames per second (FPS) of your videos to match your specific requirements. The FPS setting determines how many frames are displayed per second in your video, which can significantly affect the viewing experience and file size.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            The FPS Changer provides a range of frame rates to choose from, enabling you to optimize your videos for various types of content and platforms. Whether you need a smooth, high frame rate for action-packed scenes or a lower frame rate to reduce file size, Videoo’s FPS Changer has you covered.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Some of the common FPS options include:
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 60 FPS: Ideal for high-motion content, such as gaming videos and sports footage.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 30 FPS: Standard frame rate for most online videos, offering a balance between smoothness and file size.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 24 FPS: Traditional frame rate for cinematic videos, providing a more film-like appearance.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            - 15 FPS: Suitable for reducing file size, often used for simpler animations or low-motion content.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Adjusting the FPS is easy and intuitive. Simply select the desired frame rate from the available options, and Videoo will apply the changes to your video. This ensures that your content meets the specific standards and preferences of your target platform.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Experience the flexibility and control of Videoo’s FPS Changer, making it simple to create videos that deliver the perfect balance of quality and performance.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Box className="section-9 directory" >
                    <Box className="div-1232 px-18">
                        <Box>
                            <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Other Video Tools</Typography>
                        </Box>
                        <Box className="text-center">
                            <Typography className="text-center" variant="body1" gutterBottom color={'#4D4D4D'}>There are plenty of other features that are avaliable with Videoo.io</Typography>
                        </Box>
                    </Box>
                    <Box >
                        <Grid container spacing={3}>
                            <Grid item md={3} xs={12}>
                                <Card className="p-6" sx={{ background: '#abbed133' }}>
                                    <Grid >
                                        <img width={'100%'} src={VideooEditor_AddMusic_Image} />
                                    </Grid>
                                    <Typography sx={{ paddingY: '1rem' }} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                                        Add Music to Video
                                    </Typography>
                                    <Box className="mx-auto text-center">
                                        <Button to="../add-music-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Card className="p-6" sx={{ background: '#abbed133' }}>
                                    <Grid >
                                        <img width={'100%'} src={VideooEditor_AddText_Image} />
                                    </Grid>
                                    <Typography sx={{ paddingY: '1rem' }} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                                        Add Text to Video
                                    </Typography>
                                    <Box className="mx-auto text-center">
                                        <Button to="../add-text-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Card className="p-6" sx={{ background: '#abbed133' }}>
                                    <Grid >
                                        <img width={'100%'} src={VideooEditor_AddStock_Image} />
                                    </Grid>
                                    <Typography sx={{ paddingY: '1rem' }} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                                        Add Free Stock Content to Video
                                    </Typography>
                                    <Box className="mx-auto text-center">
                                        <Button to="../add-free-stock-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Card className="p-6" sx={{ background: '#abbed133' }}>
                                    <Grid>
                                        <img width={'100%'} src={VideooEditor_AddTransitions_Image} />
                                    </Grid>
                                    <Typography sx={{ paddingY: '1rem' }} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                                        Add Transitions to Video
                                    </Typography>
                                    <Box className="mx-auto text-center">
                                        <Button to="../add-transition-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box className="section-9 directory" sx={{ paddingY: '3rem' }}>
                    <Box className="div-1232 px-18">
                        <Box className="text-center">
                            <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                                Frequently Asked Questions
                            </Typography>
                        </Box>
                        <Box className="text-center mx-auto" sx={{ marginBottom: '3rem', width: '60%' }}>
                            <Typography variant="body1" gutterBottom>
                                Welcome to our FAQ section about our Video Compressor! Here, we answer the most common questions about using our video compression tool. Whether you're new to video editing or a seasoned pro, you'll find helpful tips and insights to make the most of our platform.
                            </Typography>
                        </Box>
                        <Box className="py-15">
                            <Typography variant="h5" gutterBottom>
                                Question #1: What types of videos can I optimize for social media?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Our Video Compressor allows you to optimize videos for various social media platforms, including WhatsApp, Instagram, Facebook, TikTok, Twitter, and LinkedIn. Each platform has specific settings to ensure your videos are compressed and optimized for the best quality and performance.
                            </Typography>
                        </Box>
                        <Box className="py-15">
                            <Typography variant="h5" gutterBottom>
                                Question #2: What compression levels are available?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                The Video Compressor offers three levels of compression: High, Medium, and Low. You can choose the appropriate level based on your need for quality and file size reduction.
                            </Typography>
                        </Box>
                        <Box className="py-15">
                            <Typography variant="h5" gutterBottom>
                                Question #3: What resolution options are available for video compression?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                You can compress videos to various resolutions to suit different needs. The available resolutions include 4K, 1440p HD, 1080p HD, 480p DVD, 576p, and TV 640x480. This flexibility allows you to maintain the desired video quality while reducing file size.
                            </Typography>
                        </Box>
                        <Box className="py-15">
                            <Typography variant="h5" gutterBottom>
                                Question #4: Can I change the frames per second (FPS) of my videos?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Yes, our Video Compressor allows you to adjust the FPS of your videos. You can choose different frame rates to optimize your video for various platforms and viewing experiences.
                            </Typography>
                        </Box>
                        <Box className="py-15">
                            <Typography variant="h5" gutterBottom>
                                Question #5: How do I select the appropriate compression level for my video?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                The choice of compression level depends on your need for quality versus file size. High compression significantly reduces file size with some quality loss, Medium compression balances quality and size, and Low compression retains most of the original quality with a moderate reduction in size. Consider the platform and the importance of video quality when selecting a compression level.
                            </Typography>
                        </Box>
                        <Box className="py-15">
                            <Typography variant="h5" gutterBottom>
                                Question #6: Will compressing my video affect its quality?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Compression may affect video quality depending on the level you choose. High compression will result in more noticeable quality loss, while Medium and Low compression levels offer better quality retention. Adjust the compression level based on your specific needs and the intended use of the video.
                            </Typography>
                        </Box>
                        <Box className="py-15">
                            <Typography variant="h5" gutterBottom>
                                Question #7: Is there a recommended resolution for social media videos?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                The recommended resolution varies by platform. For instance, 1080p HD is generally suitable for most social media platforms, offering a good balance between quality and file size. However, platforms like Instagram and WhatsApp may also perform well with lower resolutions such as 720p or 480p to reduce upload times and data usage.
                            </Typography>
                        </Box>
                        <Box className="py-15">
                            <Typography variant="h5" gutterBottom>
                                Question #8: How do I know which FPS to choose for my video?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                The choice of FPS depends on the type of content and the platform. For most social media platforms, 30 FPS is standard and provides smooth playback. Higher FPS, such as 60 FPS, is ideal for action-packed content, while lower FPS can be used for videos where file size is a priority.
                            </Typography>
                        </Box>
                        <Box className="py-15">
                            <Typography variant="h5" gutterBottom>
                                Question #9: Can I preview my video after compression?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Yes, our Video Compressor allows you to preview your video after compression to ensure the quality meets your expectations before finalizing the export. This feature helps you make necessary adjustments to compression settings if needed.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="section-9 directory std-hero-section" >
                    <Box className="section-15 py-12 mx-auto">
                        <Box className="div-1232">
                            <Box className="mx-auto">
                                <Typography sx={{ textAlign: 'center' }} variant="h3" gutterBottom fontWeight={'bolder'}>Make Your Videos Easily</Typography>
                            </Box>
                            <Box className="mx-auto text-center mt-10" lassName="div-1232" onClick={() => {
                                createNewProject("feature-compress-video")
                            }}>
                                <Button variant="contained" className="py-10 px-33" color="success">Compress Videos Now<ArrowRightAltIcon /></Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="footer-containner"><Footer /></Box>
        </Box>
    )
}
