import { Divider } from '@mui/material'
import { ModalCom } from './modal'
import { useEffect, useState } from 'react'

import { tips } from '../../util'
import { EmailValidate } from '../../provider/validate/FormValidate'

export const ConfirmModal = (props) => {
  const { title, content } = props
  const { openState, onClose, onConfirm } = props

  const confirmClick = async () => {
    let result = await onConfirm()

    if (result) {
      onClose()
    }
  }

  return (
    <ModalCom onClose={onClose}
      openState={openState}
    >
      <div className="max-w-350 w-screen flex flex-col bg-white rounded-md">
        <div className="flex flex-row px-20 py-15">
          <span className="text-18 font-semibold">
            {title}
          </span>
        </div>

        <Divider />

        <div className="flex flex-row px-20 py-15">
          {content}
        </div>

        <Divider />

        <div className="flex flex-row gap-10 justify-end px-20 py-15">
          <div onClick={onClose}
            className="border border-neutral-700 px-20 py-10 rounded-md cursor-pointer hover:bg-red-200 hover:text-white"
          >
            Cancel
          </div>

          <div onClick={confirmClick}
            className="border border-green-500 bg-green-500 text-white px-20 py-10 rounded-md cursor-pointer hover:bg-green-600"
          >
            Confirm
          </div>
        </div>
      </div>
    </ModalCom>
  )
}

export const TransferModal = (props) => {
  const { openState, onClose, onTransfer } = props
  const [email, setEmail] = useState('')

  useEffect(() => {
    setEmail('')
  }, [openState])

  const tranfer = async () => {
    let checkemail = EmailValidate(email)

    if (checkemail.status) {
      let result = await onTransfer(checkemail.email)

      if (result) {
        onClose()
      }
    } else {
      tips('warning', 'please enter validate email address')
    }
  }

  return (
    <ModalCom openState={openState}
      onClose={onClose}
    >
      <div className="max-w-350 w-screen flex flex-col bg-white rounded-md">
        <div className="flex flex-row px-20 py-15">
          <span className="text-18 font-semibold">
            Transfer Owner
          </span>
        </div>

        <Divider />

        <div className='flex flex-col gap-10 px-20 py-15'>
          <div className="flex flex-row">
            Please enter email address
          </div>

          <div className='border border-neutral-700 px-15 py-10 rounded-md'>
            <input type='text'
              className='default-input text-15'
              onChange={({ target }) => { setEmail(target.value) }}
              value={email}
            />
          </div>
        </div>

        <Divider />

        <div className="flex flex-row gap-10 justify-end px-20 py-15">
          <div onClick={onClose}
            className="border border-neutral-700 px-20 py-10 rounded-md cursor-pointer hover:bg-red-200 hover:text-white"
          >
            Cancel
          </div>

          <div onClick={tranfer}
            className="border border-green-500 bg-green-500 text-white px-20 py-10 rounded-md cursor-pointer hover:bg-green-600"
          >
            Transfer
          </div>
        </div>
      </div>
    </ModalCom>
  )
}

export const AddMemberModal = (props) => {
  const { openState, onClose, onAddMember } = props
  const [email, setEmail] = useState('')

  useEffect(() => {
    setEmail('')
  }, [openState])

  const addMember = async () => {
    let checkemail = EmailValidate(email)

    if (checkemail.status) {
      let result = await onAddMember(checkemail.email)

      if (result) {
        onClose()
      }
    } else {
      tips('warning', 'please enter validate email address')
    }
  }

  return (
    <ModalCom openState={openState}
      onClose={onClose}
    >
      <div className="max-w-350 w-screen flex flex-col bg-white rounded-md">
        <div className="flex flex-row px-20 py-15">
          <span className="text-18 font-semibold">
            Add Member
          </span>
        </div>

        <Divider />

        <div className='flex flex-col gap-10 px-20 py-15'>
          <div className="flex flex-row">
            Please enter email address
          </div>

          <div className='border border-neutral-700 px-15 py-10 rounded-md'>
            <input type='text'
              className='default-input text-15'
              onChange={({ target }) => { setEmail(target.value) }}
              value={email}
            />
          </div>
        </div>

        <Divider />

        <div className="flex flex-row gap-10 justify-end px-20 py-15">
          <div onClick={onClose}
            className="border border-neutral-700 px-20 py-10 rounded-md cursor-pointer hover:bg-red-200 hover:text-white"
          >
            Cancel
          </div>

          <div onClick={addMember}
            className="border border-green-500 bg-green-500 text-white px-20 py-10 rounded-md cursor-pointer hover:bg-green-600"
          >
            Add Member
          </div>
        </div>
      </div>
    </ModalCom>
  )
}

export const ChangeMemberLevel = (props) => {
  const { memberData, openState, onClose, onChangeLevel } = props
  const [level, setLevel] = useState(3)

  useEffect(() => {
    let tempLevel = 3

    if (memberData?.level) {
      tempLevel = memberData.level
    }

    if (level !== tempLevel) {
      setLevel(tempLevel)
    }
  }, [memberData])

  const changeLevel = ({ target }) => {
    let tempLevel = Number(target.value)
    setLevel(tempLevel)
  }

  const changeLevelClick = async () => {
    try {
      if (memberData.level === level) {
        throw new Error('please select another level')
      }

      if (level < 1 || level > 3) {
        throw new Error('level value error')
      }

      let result = await onChangeLevel(level)

      if (result) {
        onClose()
      }
    } catch (err) {
      tips('warning', err.message)
    }
  }

  return (
    <ModalCom openState={openState}
      onClose={onClose}
    >
      <div className="max-w-350 w-screen flex flex-col bg-white rounded-md">
        <div className="flex flex-row px-20 py-15">
          <span className="text-18 font-semibold">
            Add Member
          </span>
        </div>

        <Divider />

        <div className='flex flex-col gap-10 px-20 py-15'>
          <div className="flex flex-row">
            Please enter email address
          </div>

          <div className='border border-neutral-700 px-15 py-10 rounded-md'>
            <select value={level}
              onChange={changeLevel}
              className='default-input w-full'
            >
              <option value={1}>Admin</option>
              <option value={2}>Member</option>
              <option value={3}>Read Only</option>
            </select>
          </div>
        </div>

        <Divider />

        <div className="flex flex-row gap-10 justify-end px-20 py-15">
          <div onClick={onClose}
            className="border border-neutral-700 px-20 py-10 rounded-md cursor-pointer hover:bg-red-200 hover:text-white select-none"
          >
            Cancel
          </div>

          <div onClick={changeLevelClick}
            className="border border-green-500 bg-green-500 text-white px-20 py-10 rounded-md cursor-pointer hover:bg-green-600 select-none"
          >
            Change
          </div>
        </div>
      </div>
    </ModalCom>
  )
}

export const ChangeWorkspace = (props) => {
  const { workspaces, workspace, openState, onClose, onChange } = props
  const [uuid, setUUID] = useState('')

  useEffect(() => {
    if (workspace !== uuid) {
      setUUID(workspace)
    }
  }, [workspace])

  const changeLevel = ({ target }) => {
    setUUID(target.value)
  }

  return (
    <ModalCom openState={openState}
      onClose={onClose}
    >
      <div className="max-w-350 w-screen flex flex-col bg-white rounded-md">
        <div className="flex flex-row px-20 py-15">
          <span className="text-18 font-semibold">
            Change Workspace
          </span>
        </div>

        <Divider />

        <div className='flex flex-col gap-10 px-20 py-15'>
          <div className="flex flex-row">
            Please select workspace
          </div>

          <div className='border border-neutral-700 px-15 py-10 rounded-md'>
            <select value={uuid}
              onChange={changeLevel}
              className='default-input w-full'
            >
              <option value='' disabled>None</option>
              {workspaces.map((workspace, key) => (
                <option value={workspace?.uuid} key={key}>
                  {`${workspace?.title} (${workspace?.uuid})`}
                </option>
              ))}
            </select>
          </div>
        </div>

        <Divider />

        <div className="flex flex-row gap-10 justify-end px-20 py-15">
          <div onClick={onClose}
            className="border border-neutral-700 px-20 py-10 rounded-md cursor-pointer hover:bg-red-200 hover:text-white select-none"
          >
            Cancel
          </div>

          <div onClick={() => { onChange(uuid) }}
            className="border border-green-500 bg-green-500 text-white px-20 py-10 rounded-md cursor-pointer hover:bg-green-600 select-none"
          >
            Change
          </div>
        </div>
      </div>
    </ModalCom>
  )
}