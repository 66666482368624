import { Box } from '@mui/system';

import './aigenerated-image.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import HeroImg from './../../../assets/image/tools/hero-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";
import { createNewProject } from '../uploadHelper';
import { Footer } from '../../../component/home-footer/footer';
import { Header } from '../../../component/home-header/header';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Transition.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Resize Video.png';

export const AIGeneratedImage = () => {
    return (
        <Box className="T_containner">
            <Box className="Header"><Header /></Box>

            <Box className="content-containner">
            <>
            <Box className='section-15'>
                <Box className='div-1232 _1300'>
                    <Box className='breadcrumbs'>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>Videoo</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>AI Tools</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <Box className='breadcrumb-text-block'>AI Image Generator</Box>
                    </Box>
                </Box>
            </Box>

            <Box className='section-9 directory std-hero-section'>
                <Box className='div-1232'>
                    <Box className='hero-grid'>
                        <Box className='hero-grid-text'>
                            <Box className='hero-header-title'>AI Image Generator</Box>
                            <Box className='hero-content-title'>Free online AI genarated images for your videos</Box>

                            <Box onClick={() => {
                                createNewProject("feature-convert-video")
                            }} className='choose-btn'>
                                <Box className='choose-btn-text'>Choose Video</Box>
                                <Box className='choose-btn-img'><img src={UploadImg} alt='uploadImg' /></Box>
                            </Box>
                        </Box>

                        <Box className='hero-img'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/StOapicMq_s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Box>
                    </Box>

                    {/* <Box className="logos-grid">
                        <Box className="usedByLogos-item">
                            <img src={instagramLogo} alt="instagram logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={youtubeLogo} alt="youtube logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={facebookLogo} alt="facebook logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={twitterLogo} alt="twitter logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={tiktokLogo} alt="tiktok logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={whatsappLogo} alt="whatsapp logo" width={100} />
                        </Box>
                        {{usedLogs.map((logo, index) => (
                            <Box className="usedByLogos-item" key={index}>{logo}</Box>
                        ))} }
                    </Box> */}
                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232'>
                    <Box className='div-block-67'>
                        <h2 className="heading-19">AI Generated Images for your Videos Online, Free!</h2>

                        <Box className='text-section-block'>
                            <ul className='text-section-ul'>
                                <li className='py-10'>
                                    AI Generated images are the current trending topic in the so-called AI era. AI generated images are visuals that are created by artificial intelligence
                                    using enhanced machine learning models that are trained on huge datasets of images that are supervised (or unsupervised in some cases) by humans.
                                </li>

                                <li className='py-10'>
                                    These AI generated images are "generally" beyond the limits of imagination where the content seems to be created by an actual human being and produced in 
                                    "generally" in a fraction of a minute.
                                </li>

                                <li className='py-10'>
                                    Currently, there are many AI models that are capable of providing AI generated images, such as Dall-E by OpenAI, Gemini (previously known as Bard) by Google,
                                    Midjourney, Bing Image Creator and many more.
                                </li>

                                <li className='py-10'>
                                   Videoo.io has integrated Dall-E by ChatGPT to provide an interface that will allow the video editors to use AI Generated images for their videos. It is pretty
                                   easy to use, but currently requires the user to sign up for the service. And to be frank, it requires some time to generate those images, so the users should be
                                   a little patient to wait in the range of 15 seconds, for a single image to be generated.
                                </li>

                                <li className='py-10'>
                                    So, you want to create an AI Visual and signed up for Videoo.io and ready to roll. Good to know this, then click the "AI Director" tooltap menu on left hand side of 
                                    the video editor. Then enter the context that you would like the AI model to create an ai-generated visual for you. In settings menu, you can select the orientation;
                                    either Landscape, Portrait or Square. Now, you provided the context for AI-Generated visual, selected image orientation and you are pretty ready. Just click 
                                    "Create AI Visual" button, and your AI-Generated image will be added to timeline and video editor canvas.
                                </li>

                                <li className='py-10'>
                                    Already liked what you achieved? You can proceed with as many ai-generated images as  you like (depending on your subscription allowance where you check pricing page for this).
                                    You can add rest of your media elements such as videos, images, text, emoji, hand-drawn visuals, stock images using Videoo.io along with the ai-generated content you have just created.
                                </li>

                                <li className='py-10'>
                                    If you are satisfied with the visuals, and rest of the content in your video project timeline, now you can select Export properties such as Resolution, output quality and frame per second (fps).
                                    When you are ready, just click the "Export" button, and your video will be ready in a few moments. You can easily download the video content, or share it to the social media platforms
                                    through our share capability using the links provided in the view page.
                                </li>
                            </ul>
                        </Box>

                    </Box>
                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232 wide _3'>
                    <Box className='div-block-70'>
                        <Box className='div-block-69'>
                            <h2 className="heading-20">How to Add AI Genarated Images for your Videos Online using Videoo.io</h2>
                        </Box>

                        <Box className='div-block-68'>
                            <Box className='how-to-div-item'>
                                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Add Your File</h3>
                                <p className="how-to-content">Sign Up for Videoo.io ! Go to Video Editor. Select "AI Director" tooltap on left side.</p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Choose the Context</h3>
                                <p className="how-to-content">Enter the context that you want the AI model to use for generating the AI-visual. Select orientation (Landscape, Portrait, Square)</p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Generate AI-Image for your video</h3>
                                <p className="how-to-content">Click the "Create AI Visual" button to start the generation process. Once the AI Model creates visual for you, it will be added to your video project.</p>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            </>
            </Box>

            <Box className="footer-containner"><Footer /></Box>
        </Box >
        
    )
}
