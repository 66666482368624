import { Box, Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from "react-router-dom";

import './add-transitions-to-video.scss';
import { createNewProject } from './../VideoEditorHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

import { GetProjectLink } from "../../../axios/ApiProvider";

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL;
export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

const VideooEditor_AddTransition_Welcome = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddTransition-1-Welcome.png'
const VideooEditor_AddTransition_Step1 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddTransition-2-AddVideo.png'
const VideooEditor_AddTransition_Step2 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddTransition-3-AddSecondVideo.png'
const VideooEditor_AddTransition_Step3 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddTransition-4-AddTransition.png'
const VideooEditor_AddTransition_Step4 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddTransition-5-TransitionView.png'
const VideooEditor_AddTransition_Step5 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddTransition-6-ChangeDuration.png'
const VideooEditor_AddTransition_Step6 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddTransition-7-Exported.png'
const VideooEditor_AddTransition_Step7 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddTransition-8-ExportMenu.png'

const VideooEditor_AddTransition_Action1 = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Transition.png';
const VideooEditor_AddTransition_Action2 = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File.png';
const VideooEditor_AddTransition_Action3 = BASE_STATIC_STORAGE_URL + '/images/features/Export Transition.png';

const VideooEditor_AddImage_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Your%20File%20Filter.png'
const VideooEditor_AddMusic_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Music%20to%20Video%202.png'
const VideooEditor_AddText_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Text%20to%20Video.png'
const VideooEditor_AddStock_Image = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Stock.png'


export const AddTransitionstoVideo = () => {
    const handleCreateNewProject = async () => {
        let result = await GetProjectLink();
        if (!result || result.state == 'error') window.toastr.error(result.data.message);
        else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
    }

    return (
        <Box className="T_containner">
          <Box className="Header"><Header /></Box>

          <Box className="content-containner">
            <Box className="section-15" sx={{marginTop: '4rem'}}> 
                <Box className="div-1232">
                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={400}>
                        <Grid item xs={12} md={6}>
                            <Card className="p-6">
                                <Box className="div-1232">
                                    <Typography variant="h3" gutterBottom fontWeight={'bold'}>
                                    Add Transitions to Your <br />Videos Online
                                    </Typography>
                                </Box>
                                <Box className="div-1232" sx={{marginBottom: '1rem'}}>
                                    <Typography variant="body1" gutterBottom >
                                      - Add transitions to your videos with VIDEOO’s online video editor. Our platform offers a variety of transition effects that you can apply to your videos with ease.
                                    </Typography>
                                    <Typography variant="body1" gutterBottom >
                                      - Each transition is designed to enhance the visual storytelling of your videos and captivate your audience. Whether you're editing a vlog, tutorial, promotional video, or any other content, our transitions help you create engaging and professional-looking videos that stand out.
                                    </Typography>
                                </Box>
                                <Box className="div-1232" onClick={handleCreateNewProject}>
                                    <Button variant="contained" className="create-now" color="success">Create Now</Button>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} height={'100%'}>
                            <Card className="p-6">
                                {/*<img src={Illustration} className="mx-auto h-full " />*/}
                                <div style={{ width: '100%', paddingTop: '56.25%', position: 'relative' }}>
                                <iframe
                                    src="https://www.youtube.com/embed/cxKVTIZ8Weg"
                                    title="Videoo.io, Online Cloud Based Video Editor"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                ></iframe>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box className='section-15 pt-12'>
              <Box className='div-1232'>
                <Box className='breadcrumbs'>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Videoo</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Video Editor</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Add Image to Video</Box>
                  </a>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" sx={{paddingY: '3rem'}}>
              <Box className="div-1232">
                <Box className="mx-auto text-center" sx={{width: '80%'}}>
                  <Typography variant="h3" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Transitions To Your Video using Online Video editor</Typography>
                </Box>
                <Box sx={{textAlign: 'center', marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom > Easily Add Transitions to your Video with a single click </Typography>
                </Box>
                <Box className="container">
                  <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                    <Grid item md={6} xs={12} >
                      <img width={'100%'} src={VideooEditor_AddTransition_Welcome} />
                    </Grid>
                    <Grid item md={6} xs={12} >
                    <Box sx={{paddingBottom: '0.5rem'}}>
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>With VIDEOO's wide range of transitions, you can make good looking video projects</Typography>
                      </Box>
                      <Box sx={{paddingBottom: '1rem'}}>
                      <Typography variant="body1" gutterBottom>
                        <strong>Wide Range of Transitions:</strong> VIDEOO offers a diverse selection of transitions to choose from, including fades, wipes, slides, and more. Each transition adds a professional touch to your videos, enhancing their visual appeal and engaging your audience.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Real-Time Preview:</strong> Preview each transition in real-time to see how it will look in your video. Experiment with different transitions to find the perfect match for your content and create seamless, polished videos.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Easy Application:</strong> Applying transitions is simple and intuitive. With just a few clicks, you can add transitions between clips, images, and text to create smooth and dynamic video projects.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Customizable Options:</strong> Customize the duration, speed, and style of each transition to suit your video's pacing and tone. VIDEOO gives you full control over how transitions are applied, allowing you to create unique and engaging videos.
                      </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box className="section-9 directory std-hero-section" >
              <Box className="div-1232" >
              <Box className="text-center">
                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>How to Add a Transitions to a Video, a step-by-step Guide</Typography>
                </Box>
                <Box className="text-center" sx={{marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >
                    You can use Videoo.io to enhance your creativity by adding customizied transitions to your videos. Here's how you can do it:
                  </Typography>
                </Box>

                <Box className="section-9 directory std-hero-section" >
                    <Box className="div-1232">
                        <Box className="container">
                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddTransition_Step1} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 1 - Selecting Videos</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        - Signup & Login to Videoo.io, and create either a blank or template project.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Add your video content (or create a Template or Stock Content Project)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Go to Text Section on left side menu. Click on Transitions to see the list of transitions available.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                      <Button to="../signup" component={Link} variant="contained" className="py-10 px-33" color="success">Sign up to Videoo.io</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddTransition_Step2} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 2 - Adding a Second Video</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body1" gutterBottom>
                                        - Once you have added a first video to your project, you can add a second video by using the Upload Menu.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - If these two videos are adjacent to each other, you will see a dash icon between these two videos.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/templates" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Template Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddTransition_Step3} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 3 - Adding a Transition between two videos</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body1" gutterBottom>
                                        - You can add a transition between them by dragging and dropping the transition effect from the transitions library onto the timeline.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - When the transition is added between two videos, the icon between video elements will change.
                                      </Typography>
                                    </Box>
                                    <Box className="div-1232" onClick={handleCreateNewProject}>
                                        <Button variant="contained" className="create-now" color="success">Create a Blank Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddTransition_Step4} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 4 - Viewing Transition in Preview</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                          - In order to check the transition, you can either preview in the left menu or play the video in the preview section.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                          - Just try as many transitions as possible to see which one fits your videos.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/stocks" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Stock Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddTransition_Step5} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 5 - Changing Transition Duration</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - You can also adjust the duration and style of the transition to suit your preferences.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - You can find the transition slider in the Transitions menu.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddTransition_Step6} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 6 - Exporting Transition Project</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Once you have added transitions to your videos, you can export the final project with the transitions included.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - You can export the video in various resolutions including 720, 1080 and 4K.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>
                    </Box>
                </Box>
              </Box>
            </Box>
            <Box className="section-9  std-hero-section">
              <Box className="div-1232">
                <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} >Easy-to Use Add Transitions to Video Features</Typography>
                <Box className="container py-15" sx={{marginTop: '2rem'}}>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Transitions Library
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body1" gutterBottom>
                          VIDEOO’s Transitions Library offers a comprehensive collection of transition effects designed to enhance the flow and visual appeal of your videos. Here’s what you can expect from each transition in the library:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Fades:</strong> Smoothly transition between clips with fade effects that gradually increase or decrease the opacity. Perfect for creating a seamless and professional look.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Wipes:</strong> Add dynamic motion to your transitions with wipe effects that slide one clip out as another slides in. Ideal for adding a touch of style and movement to your videos.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Slides:</strong> Create engaging transitions by sliding clips in and out from various directions. This effect is great for storytelling and maintaining viewer interest.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Zooms:</strong> Apply zoom transitions to make your videos more dynamic and visually appealing. Zoom effects can either zoom in or out between clips, adding depth and emphasis.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Cross Dissolves:</strong> Blend two clips together smoothly with cross dissolve transitions. This classic effect is perfect for maintaining continuity and flow in your videos.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Customizable Duration:</strong> Each transition in the library allows you to customize its duration. Adjust the length of the transition to fit your video’s pace and style.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Real-Time Preview:</strong> Preview transitions in real-time before applying them to your timeline. This feature helps you select the best transition for each scene, ensuring it enhances the overall flow of your video.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Easy Drag-and-Drop:</strong> Adding transitions is as simple as dragging and dropping them onto the timeline between your clips. This user-friendly approach makes it quick and easy to enhance your video with professional transitions.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          With VIDEOO’s Transitions Library, you have access to a wide range of effects that can elevate the quality of your videos. Each transition is designed to be easy to apply and customize, allowing you to create smooth, engaging, and visually stunning video content.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create Video with Transitions</Button>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Social Media Transitions
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body1" gutterBottom>
                          VIDEOO’s Social Media Transitions feature provides a range of dynamic and engaging transition effects specifically designed for social media content. Here’s how it can enhance your videos:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Platform-Specific Styles:</strong> Our Social Media Transitions are tailored to match the aesthetic and style of various social media platforms, such as Instagram, TikTok, Facebook, and YouTube. This ensures your videos look polished and professional, no matter where you share them.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Trend-Inspired Effects:</strong> Stay ahead of the curve with transitions inspired by the latest trends on social media. Whether it’s a flashy swipe, a smooth fade, or a quirky flip, these transitions help your content stay fresh and engaging.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Easy Application:</strong> Applying social media transitions is simple with VIDEOO’s intuitive interface. Just drag and drop your chosen transition onto the timeline between clips to instantly add a professional touch.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Customizable Duration:</strong> Adjust the duration of each transition to match the pace of your video. This flexibility allows you to create seamless transitions that enhance the overall flow of your content.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Real-Time Preview:</strong> Preview your transitions in real-time to see how they will look in your video. This feature helps you select the best transition for each scene, ensuring a smooth and visually appealing result.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Engage Your Audience:</strong> Use social media transitions to capture and retain your audience’s attention. Dynamic transitions can make your content more engaging, helping to increase viewer retention and interaction.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Consistent Branding:</strong> Maintain a consistent visual style across all your videos with branded transitions. This helps reinforce your brand identity and makes your content instantly recognizable.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          With VIDEOO’s Social Media Transitions, enhancing your videos with trendy and platform-specific effects is effortless. These transitions not only improve the visual appeal of your content but also help you create a cohesive and engaging viewing experience for your audience.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create Video with Social Media Transitions</Button>
                      </Box>
                    </Grid>

                    <Box className="container">
                      <Grid container spacing={5}>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AddTransition_Action1}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Your Video</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - To get started, simply upload your video content to VIDEOO's online editor. If you want to use high quality copyright free videos, you can also create with Stock or Template projects to access pre-made content.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AddTransition_Action2}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Transition to Video</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Once you've added your video, you can start adding transitions between clips. VIDEOO offers a wide range of transition effects to choose from, including fades, wipes, slides, and more.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AddTransition_Action3}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Export & Share</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Once you've added transitions to your video, you can export the final project in various resolutions, including 720p, 1080p, and 4K. Share your video on social media or download it for offline viewing.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Box className="py-10">
                        <img width={'100%'} src={VideooEditor_AddTransition_Step1} />
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box className="section-9 directory" >
                <Box className="div-1232 px-18">
                    <Box>
                    <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Other Video Tools</Typography>
                    </Box>
                    <Box className="text-center">
                    <Typography className="text-center" variant="body1" gutterBottom color={'#4D4D4D'}>There are plenty of other features that are avaliable with Videoo.io</Typography>
                    </Box>
                </Box>
                <Box >
                    <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddTransition_Action1} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Music to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-music-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddText_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Text to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-text-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddStock_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Free Stock to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-free-stock-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddImage_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Image to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-image-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                  </Grid>
                </Box>
            </Box>

            <Box className="section-9 directory" sx={{ paddingY: '3rem' }}>
              <Box className="div-1232 px-18">
                <Box className="text-center">
                  <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                    Frequently Asked Questions
                  </Typography>
                </Box>
                <Box className="text-center mx-auto" sx={{ marginBottom: '3rem', width: '60%' }}>
                  <Typography variant="body1" gutterBottom>
                    Welcome to our FAQ section about adding transitions to videos with VIDEOO! Here, we answer the most common questions about using our transition features. Whether you're new to video editing or a seasoned pro, you'll find helpful tips and insights to make the most of our platform.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #1: How do I add transitions to my video using VIDEOO?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    To add transitions to your video in VIDEOO, simply drag and drop the transition effect from the transitions library onto the timeline between two video clips. You can then adjust the duration and style of the transition to suit your preferences.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #2: What types of transitions are available in VIDEOO?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    VIDEOO offers a wide range of transitions, including fades, wipes, slides, zooms, and more. Each transition is designed to enhance the flow of your video and provide a professional touch to your edits.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #3: Can I customize the duration of the transitions?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO allows you to customize the duration of each transition. Simply select the transition on the timeline and adjust the duration by dragging its edges to your desired length.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #4: How can I preview the transitions before applying them?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    VIDEOO provides a real-time preview feature that lets you see how each transition will look in your video before applying it. Simply hover over a transition in the library or place it on the timeline to preview its effect.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #5: Can I apply multiple transitions to a single video?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO allows you to apply different transitions between various clips within a single video. This helps create a dynamic and engaging viewing experience by varying the transition effects throughout your video.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #6: Are there platform-specific transitions available?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    VIDEOO includes a selection of transitions that are optimized for different platforms and content types. Whether you’re creating videos for social media, presentations, or films, you can find transitions that match the style and requirements of your project.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #7: How do I remove a transition if I change my mind?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    If you decide to remove a transition, simply click on the transition in the timeline and press the delete key. This will remove the transition effect and return the video clips to their original state.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #8: Can I save my favorite transitions for future use?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Currently, VIDEOO does not support saving favorite transitions. However, the platform makes it easy to find and apply your preferred transitions quickly whenever you need them.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" >
              <Box className="section-15 py-12 mx-auto">
                <Box className="div-1232">
                  <Box className="mx-auto">
                    <Typography sx={{textAlign: 'center'}} variant="h3" gutterBottom fontWeight={'bolder'}>Make Your Videos Easily</Typography>
                  </Box>
                  <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                    <Button variant="contained" className="py-10 px-33" color="success">Create Now <ArrowRightAltIcon /></Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="footer-containner"><Footer /></Box>
        </Box>
        
    )
}