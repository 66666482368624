export const editorLangItStrs = {
    "welcomNewProject": "Benvenuti al vostro nuovo progetto",
    "pls_add_media": "Si prega di aggiungere media al vostro progetto",
    "add_video": "Aggiungi Video",
    "add_audio": "Aggiungi Audio",
    "add_image": "Aggiungi Immagine",
    "add_webcam": "Cattura Webcam",
    "add_screen": "Cattura Schermo",
    "add_webcam_screen": "Cattura Webcam & Schermo",
    "capture_screen_camera": "Cattura il tuo schermo & camera!",
    "video_captureDevice": "Dispositivo di Cattura Video",
    "resolution": "Risoluzione",
    "audio_captureDevice": "Dispositivo di Cattura Audio",
    "resize_video": "Ridimensiona Video",
    "rotate_video": "Ruota Video",
    "loop_video": "Loop Video",
    "joinmarge_video": "Aggiungi Unisci/Merge Video",
    "cut_video": "Taglia Video",
    "crop_video": "Ritaglia Video",
    "translate_video": "Traduci Video",
    "transcripbe_video": "Trascrivi Video",
    "auto_subtitles": "Sottotitoli Automatici",
    "add_subtitle_video": "Aggiungi Sottotitolo al Video",
    "compress_video": "Comprimi Video",
    "convert_video": "Converti Video",
    "project_not_accessible": "Progetto non accessibile",
    "leave": "Lascia",
    "cancel": "Annulla",
    "okay": "Ok",
    "stop": "Ferma",
    "record": "Registra",
    "ongoing_upload_project_str": "C'è un caricamento in corso su questo progetto.",
    "leaving_page_sure": "Stai lasciando questa pagina, sei sicuro?",
    "uploading_files": "Caricando File",
    "record_screen_and_upload": "Registra il tuo schermo e carica.",
    "show_defaul_sharingMenu": "Mostra elementi menu condivisione predefiniti",
    "prefer_share_browser": "Preferisci condividere una scheda del browser",
    "prefer_share_window": "Preferisci condividere una finestra",
    "prefer_share_entireScreen": "Preferisci condividere l'intero schermo",
    "record_webcam_and_upload": "Registra la tua webcam e carica.",
    "sign_signup_to_uploadVideos": "Effettua l'accesso o registrati per caricare video",
    "sizelimir_or_timelimit": "più grande di 250 MB o più lungo di 5 minuti",
    "screen_capture_preferences": "Preferenze di Cattura Schermo",
    "is_uploading": "Sta Caricando",
    "monthly": "Mensilmente",
    "annually": "Annuale",
    "most_popular": "Più Popolare",
    "export": "Esporta",
    "bitrate": "Bitrate",
    "frame_rate_fps": "Frame Rate (FPS)",
    "produce": "Produce",
    "setup": "Impostazioni",
    "template": "Modello",
    "templates": "Modelli",
    "media": "Media",
    "stock": "Stock",
    "translations": "Traduzioni",
    "subtitles": "Sottotitoli",
    "text_to_speech": "Da Testo a Voce",
    "transitions": "Transizioni",
    "text": "Testo",
    "emojis": "Emoji",
    "filters": "Filtri",
    "draw": "Disegna",
    "edit_subtitle": "Modifica Sottotitolo",
    "add_new_subtitle": "aggiungi nuovo sottotitolo",
    "select_brush_stock_start_draw": "Seleziona un tratto di pennello per iniziare a disegnare",
    "brush_stroke": "Tratto di Pennello",
    "round": "Rotondo",
    "sliced": "Affettato",
    "color": "Colore",
    "opacity": "Opacità",
    "size": "Dimensione",
    "duration": "Durata",
    "add_new_line": "Aggiungi Nuova Linea",
    "all_types": "Tutti i Tipi",
    "load_more": "Carica Altro",
    "upload_video": "Carica Video",
    "upload_audio": "Carica Audio",
    "upload_image": "Carica Immagine",
    "create_webcam_recording": "Crea Registrazione Webcam",
    "create_screen_capture_recording": "Crea Registrazione di Cattura Schermo",
    "create_webcam_screen_recording": "Crea Registrazione Webcam & Schermo",
    "project_asset": "Asset del Progetto",
    "you_want_add_translations": "Come vuoi aggiungere traduzioni?",
    "uploader": "Uploader",
    "auto": "AUTO",
    "auto_translations": "Traduzioni Automatiche",
    "automatically_add_transllations_video": "Aggiungi automaticamente traduzioni al video",
    "wait_moments_subtitles_being": "Attendi qualche momento mentre il sottotitolo viene preparato",
    "select_language_detect": "Seleziona lingua da rilevare",
    "disable_translate": "Disabilita Traduzione",
    "select_language_select": "Seleziona Lingua per tradurre",
    "transition_duration": "Durata Transizione",
    "coming_soon": "Prossimamente",
    "language": "Lingua",
    "speaking_rate_defaul": "Velocità di Parola (default 1)",
    "pitch_defaul": "Tono (default 0)",
    "volume_gain_default": "Guadagno Volume (default 0)",
    "create_text_speech": "Crea Da Testo a Voce",
    "add_text": "Aggiungi Testo",
    "text": "Testo",
    "start": "Inizio",
    "end": "Fine",
    "text_style": "Stile Testo",
    "background_color": "Colore di Sfondo",
    "add_new_text_field": "Aggiungi Nuovo Campo di Testo",
    "how_add_subtitles": "Come vuoi aggiungere sottotitoli",
    "automatically_subtitles_video": "Aggiungi automaticamente sottotitoli al video",
    "text_color": "Colore del Testo",
    "preview_text": "Anteprima Testo",
    "search": "Cerca",
    "orientation": "Orientamento",
    "next": "Prossimo",
    "previous": "Precedente",
    "locale": "Locale",
    "landscape": "Paesaggio",
    "portrait": "Ritratto",
    "square": "Quadrato",
    "time": "Tempo",
    "background": "Sfondo",
    "project_setting": "Impostazioni del Progetto",
    "use_template_project": "Usa come progetto modello",
    "visibility": "Visibilità",
    "category": "Categoria",
    "capture_thumbnail": "Cattura Miniatura",
    "brand_kit": "Kit Brand",
    "video_placeholder": "VideoPlaceholder2.mp4",
    "replace_video": "Sostituisci Video",
    "transformations": "Trasformazioni",
    "fill_canvas": "Riempi la tela",
    "fit_canvas": "Adatta alla tela",
    "round_corners": "Angoli Arrotondati",
    "audio": "Audio",
    "detach_audio": "Stacca Audio",
    "cleanAudio": "PulisciAudio",
    "upgrade_basic_unlock": "Aggiorna a BASIC per sbloccare",
    "remove_background_noise_enhance_audio_qty": "Rimuovi rumore di fondo e migliora qualità audio",
    "color_correction": "Correzione Colore",
    "reset": "Reset",
    "exposure": "Esposizione",
    "brightness": "Luminosità",
    "contract": "Contrasto",
    "saturation": "Saturazione",
    "sharpen": "Nitidezza",
    "blur": "Sfocatura",
    "noise": "Rumore",
    "trending": "DI TENDENZA",
    "custom": "PERSONALIZZATO",
    "portrait": "RITRATTO",
    "landscape": "PAESAGGIO",
    "settings": "Impostazioni",
    "magic_draw": "Disegno Magico",
    "tools": "Strumenti",
    "brush_size": "Dimensione Pennello",
    "save": "Salva",
    "clear": "Pulisci",
    "view_all": "Visualizza Tutto",
    "save_your_project_later": "Salva il tuo progetto per dopo",
    "sign_in": "accedi",
    "sign_up": "registrati",
    "sign_up_home": "Registrati",
    "redo": "RIFAI",
    "undo": "ANNULLA",
    "collaborate": "COLLABORA",
    "enter_email_share_project": "Inserisci email per condividere il progetto",
    "share_project": "Condividi Progetto",
    "create_new_workspace": "Crea nuovo Workspace",
    "audio_noise_remove": "Rimozione Rumore Audio",
    "media_upload": "Caricamento Media",
    "draw_color": "Colore Disegno",
    "tsl_none": "nessuno",
    "tsl_fade": "Dissolvenza",
    "tsl_book_flip": "Sfoglia Libro",
    "tsl_disolve": "Dissolvi",
    "tsl_doorway": "Porta",
    "tsl_blur": "Sfocatura",
    "tsl_push": "Spinta",
    "tsl_slide": "Scivolata",
    "tsl_wipe": "Pulizia",
    "tsl_zoom_in": "Zoom Avanti",
    "tsl_zoom_out": "Zoom Indietro",
    "flt_None": "Nessuno",
    "flt_Darker": "Più Scuro",
    "flt_BlacknWhite": "Bianco e Nero",
    "flt_Daphne": "Dafne",
    "flt_Penelope": "Penelope",
    "flt_Phoebe": "Febe",
    "flt_Thalia": "Talia",
    "flt_Chloe": "Cloe",
    "flt_Hermione": "Erminia",
    "flt_Hera": "Era",
    "flt_Rhea": "Rea",
    "flt_Donna": "Donna",
    "flt_Martina": "Martina",
    "flt_Gabriella": "Gabriella",
    "flt_Elisabetta": "Elisabetta",
    "flt_Isabella": "Isabella",
    "flt_Luna": "Luna",
    "flt_Viola": "Viola",
    "flt_Zita": "Zita",
    "flt_Donata": "Donata",
    "flt_Maddalena": "Maddalena",
    "flt_Tecla": "Tecla",
    "flt_Narcisa": "Narcisa",
    "emj_Smileys": "Faccine",
    "emj_Animals": "Animali",
    "emj_People": "Persone",
    "emj_Jobs": "Lavori",
    "emj_Sports": "Sport",
    "emj_Flags": "Bandiere",
    "emj_Signs": "Segnali",
    "emj_Nature": "Natura",
    "emj_Travel": "Viaggi",
    "emj_Letters": "Lettere",
    "emj_Misc": "Varie",
    "create_empty_project_templates": "Crea un Progetto Vuoto dai Modelli",
    "days_ago": "giorni fa",
    "create_workspace": "Crea Workspace",
    "workspace": "Workspace",
    "delete_workspace": "Elimina Workspace",
    "transfer_owner": "Trasferisci Proprietario",
    "can_create_new_workspace": "Puoi creare un nuovo workspace?",
    "could_delete_workspace": "Potresti eliminare il workspace?",
    "available_subtitles_time": "Tempo Sottotitoli Disponibile",
    "available_storage_space": "Spazio di Archiviazione Disponibile",
    "other_workspaces": "Altri workspace",
    "members": "Membri",
    "add_member": "Aggiungi Membro",
    "remove_member": "Rimuovi Membro",
    "change_member_level": "Cambia Livello Membro",
    "owner": "Proprietario",
    "admin": "Amministratore",
    "member": "Membro",
    "read_only": "Solo Lettura",
    "duplicate": "Duplica",
    "splite": "Dividi",
    "remove": "rimuovi",
    "layer": "Livello",
    "volume": "Volume",
    "disable": "Disabilita",
    "deleterow": "EliminaRiga",
    "fullscreen": "Schermo Intero",
    "editsubtitle": "ModificaSottotitolo",
    "fit": "Adatta",
    "green_screen": "Schermo Verde",
    "profile": "Profilo",
    "pricing": "Prezzi",
    "login": "Accedi",
    "logout": "Esci",
    "get_started_free": "Inizia - è gratis",
    "dashboard": "Dashboard",
    "projects": "Progetti",
    "menu_Features": "Caratteristiche",
    "menu_Tools": "Strumenti",
    "menu_VideoEditor": "Editor Video",
    "menu_AIVideoEditor": "Editor Video AI",
    "menu_AddImgToVideo": "Aggiungi Immagine al Video",
    "menu_AddMusicToVideo": "Aggiungi Musica al Video",
    "menu_AddTextToVideo": "Aggiungi Testo al Video",
    "menu_FilterVideo": "Filtra Video",
    "menu_AddTransitionstoVideo": "Aggiungi Transizioni al Video",
    "menu_AddFreeStockVideos": "Aggiungi Video di Stock Gratuiti",
    "menu_SubtitlesTranscription": "Sottotitoli & Trascrizione",
    "menu_AddSubtitlesToVideo": "Aggiungi Sottotitoli al Video",
    "menu_AudioToText": "Da Audio a Testo",
    "menu_AutoSubtitles": "Sottotitoli Automatici",
    "menu_TranscribeVideo": "Trascrivi Video",
    "menu_TranslateVideo": "Traduci Video",
    "menu_Toolkit": "Toolkit",
    "menu_Crop": "Ritaglia",
    "menu_Cut": "Taglia",
    "menu_JoinMerge": "Unisci/Merge",
    "menu_LoopVideo": "Loop Video",
    "menu_Resize": "Ridimensiona",
    "menu_Rotate": "Ruota",
    "menu_More": "Altro",
    "menu_VideoCompressor": "Compressore Video",
    "menu_VideoConverter": "Convertitore Video",
    "menu_AIGenerated": "Generato dall’IA",
    "menu_AIGeneratedImages": "Immagini generate dall’IA",
    "menu_AIGeneratedVideos": "Video generati dall’IA",
    "menu_AIGeneratedVoices": "Voci generate dall’IA",
    "menu_Blog": "Blog",
    "menu_VIDEOOBlog": "Blog VIDEOO",
    "menu_VIDEOOHowTo": "Come fare VIDEOO",
    "menu_VIDEOOCareers": "Carriere VIDEOO",
    "online_ai_video_editor": "Editor Video Online basato su IA",
    "online_could_video_editor": "Editor Video Online basato su Cloud",
    "home_free_trial_detail": 'Prova gratuita di 7 giorni, cancellazione in qualsiasi momento',
    "home_create_button_detail": "Creazione video con un clic con sottotitoli automatici, traduzione, e altro ancora",
    "create_now": "Pannello di Controllo",
    "home_templates_start": "Esplora una gamma di modelli per iniziare",
    "try_now_required": "Provalo ora, non è richiesto alcun account",
    "online_collaborative_video_editing": "Editing Video Collaborativo Online",
    "online_collaborative_video_editing_detail": "Immergiti nel mondo dell'editing video online basato su cloud con videoo.io. La nostra piattaforma rivoluziona il modo in cui modifichi, offrendo capacità di editing video collaborativo online e uno spazio di lavoro di editing video basato su cloud.",
    "auto_subtitles_translations": "Sottotitoli e Traduzioni Automatici",
    "auto_subtitles_translations_detail": "Arricchisci i tuoi video con la nostra funzionalità di Sottotitoli e Traduzioni Automatici, parte del nostro Software Professionale di Editing Video Online. Progettato per essere uno Strumento di Software di Editing Video per Tutti, videoo.io rende semplice creare contenuti accessibili e accattivanti.",
    "ai_base_text_speech": "Testo in Voce basato su IA",
    "ai_base_text_speech_detail": "Le nostre Voci di Testo in Parola basate su IA sono perfette per i creatori di contenuti, incarnando il concetto di Editing Video Reinventato per Youtubers e Influencer. Con videoo.io, trasforma i tuoi script in voiceover coinvolgenti, aggiungendo un tocco professionale ai tuoi video e semplificando il tuo processo di produzione.",
    "free_user_stock_video": "Video di Stock Gratuiti da Utilizzare",
    "free_user_stock_video_detail": "Accedi a una vasta collezione di Video di Stock Gratuiti da Utilizzare con videoo.io, completando le nostre capacità di Editing Video 4K Online. La nostra piattaforma, essendo un Software di Editing Video 4K per Tutti, assicura che i tuoi progetti si distinguano con metraggio di alta qualità, rendendo l'editing di livello professionale accessibile a tutti gli utenti.",
    "share": "Condividi",
    "unlisted_video": "Video Non Elencato",
    "your_video_about_ready": "Il tuo video sta per essere pronto",
    "hours": "Ore",
    "minutes": "Minuti",
    "seconds": "Secondi",
    "want_free_stuff": "Vuoi roba gratis?",
    "record_screen_webcam_both": "Registra il tuo schermo, webcam, o entrambi",
    "add_subtitle_your_video": "Aggiungi sottotitoli ai tuoi video",
    "create_your_team_workspace": "Crea il tuo Spazio di Lavoro di Squadra",
    "collaborate_videos": "Collabora sui video",
    "generate_transcript": "Genera una trascrizione",
    "singup_get_all_this_more": "Iscriviti (gratis) per ottenere tutto questo e altro ancora!",
    "downloading": "Scaricando",
    "download": "Scarica",
    "reedit_video": "Ri-edita video",
    "remove_watermark": "Rimuovi filigrana",
    "already_have_account": "Hai già un account?",
    "link_copied": "Link Copiato",
    "invitation_list": "Lista Inviti",
    "remove_invitation": "Rimuovi Invito",
    "change_workspace": " Cambia workspace",
    "you_login_manage_your_profile": "Devi accedere per gestire il tuo profilo",
    "you_dont_have_content_here": "Non hai contenuti qui.",
    "name": "Nome",
    "status": "Stato",
    "data_modified": "Data Modificata",
    "all_workspaces": "Tutti gli Workspace",
    "delete_project": "Elimina Progetto",
    "duplicate_project": "Duplica Progetto",
    "draft": "Bozza",
    "edit": "Modifica",
    "surname": "Cognome",
    "email": "Email",
    "plan": "Piano",
    "market_emails": "Email di Marketing",
    "editor_notification": "Notifiche Editor",
    "storage": "Archiviazione",
    "subtitle": "Sottotitolo",
    "delete_account": "Elimina Account",
    "this_action_irreversibe": "Questa azione è irreversibile",
    "current_plan": "PIANO ATTUALE",
    "cancel_subscription": "Annulla Abbonamento",
    "payment_method": "METODI DI PAGAMENTO",
    "expires": "Scade",
    "make_default": "Imposta come Predefinito",
    "delete": "Elimina",
    "add_payment_method": "Aggiungi Metodo di Pagamento",
    "billing_information": "INFORMAZIONI DI FATTURAZIONE",
    "invoice_history": "STORICO FATTURE",
    "payment": "Pagamento",
    "preferences": "Preferenze",
    "save_up_to": "Risparmia Fino a ",
    "your_plan": "Il Tuo Piano",
    "inactive": "Inattivo",
    "contact_sales": "Contatta le Vendite",
    "need_additional_features": "Hai bisogno di funzionalità aggiuntive? Chiedici del nostro piano Enterprise"
}
