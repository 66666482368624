import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { App } from './pages/App/App';
import { SignUp } from './pages/signUp/signUp';
import { SignIn } from './pages/signIn/signIn';

import { Home } from './pages/Home/Home';
import { Tools } from './pages/Tools/tools';
import { SubtitlesTranscription } from './pages/SubtitlesTranscription/subtitlestranscription';
import { PricingPage } from './pages/Pricing/pricing';
import { DashBoard } from './pages/workspaces/workspaces';
// import { VideoEditCotainer } from './pages/Video-Edit/videoEditContainer';
import { ProjectView } from './pages/project-view/projectView';
import { AssetView } from './pages/asset-view/assetView';
import { More } from "./pages/More/more"
import { AIGenerated } from "./pages/AIGenerated/aigenerated"

import { GetRegistration } from './axios/ApiProvider';
import { loginState_Store } from './store/actions/mainAction';
import { VideoEditor } from './pages/VideoEditor/videoeditor';
import { VideooBlog } from './pages/blogs/videooblog/videooblog';

import { VideooHowto } from './pages/blogs/videoohowto/videoohowto';
import { Howto1 } from './pages/blogs/videoohowto/howto1';
import { Howto2 } from './pages/blogs/videoohowto/howto2';

import { Blog1 } from './pages/blogs/videooblog/blog1';
import { Blog2 } from './pages/blogs/videooblog/blog2';
import { Blog3 } from './pages/blogs/videooblog/blog3';
import { Blog4 } from './pages/blogs/videooblog/blog4';
import { Blog5 } from './pages/blogs/videooblog/blog5';
import { Blog6 } from './pages/blogs/videooblog/blog6';
import { Blog7 } from './pages/blogs/videooblog/blog7';
import { Blog8 } from './pages/blogs/videooblog/blog8';
import { Blog9 } from './pages/blogs/videooblog/blog9';
import { Blog10 } from './pages/blogs/videooblog/blog10';
import { Blog11 } from './pages/blogs/videooblog/blog11';
import { Blog12 } from './pages/blogs/videooblog/blog12';
import { Blog13 } from './pages/blogs/videooblog/blog13';
import { Blog14 } from './pages/blogs/videooblog/blog14';
import { Blog15 } from './pages/blogs/videooblog/blog15';
import { Blog16 } from './pages/blogs/videooblog/blog16';

import { Career } from './pages/careers/videoocareer';
import { Career1 } from './pages/careers/career1';
import { Career2 } from './pages/careers/career2';
import { Career3 } from './pages/careers/career3';
import { Career4 } from './pages/careers/career4';
import { Career5 } from './pages/careers/career5';
import { Career6 } from './pages/careers/career6';
import { Career7 } from './pages/careers/career7';
import { Career8 } from './pages/careers/career8';
import { Career9 } from './pages/careers/career9';
import { Career10 } from './pages/careers/career10';

import { NotMatch } from './pages/notMatch';

import { Terms } from './pages/terms'
import { Cookies } from './pages/cookies';
import { Privacy } from './pages/privacy';

import { Security } from './pages/security';
import { Loading } from './component/Loading';
import { useGlobalContext } from './provider';

import { languageCode_Store } from './store/actions/mainAction';
import { languageStrs_Store } from './store/actions/editAction';
import { editorLangEnStrs } from './lang/editor_string_en';
import { editorLangDeStrs } from './lang/editor_string_de';
import { editorLangFrStrs } from './lang/editor_string_fr';
import { editorLangItStrs } from './lang/editor_string_it';
import { editorLangPtStrs } from './lang/editor_string_pt';
import { editorLangTrStrs } from './lang/editor_string_tr';
import { editorLangEsStrs } from './lang/editor_string_es';
import { ConvertVideo } from './pages/More/convert-video/convert-video';
import { CompressVideo } from './pages/More/compress-video/compress-video';
import { CropVideo } from './pages/Tools/crop-video/crop-video';
import { CutVideo } from './pages/Tools/cut-video/cut-video';
import { JoinMergeVideo } from './pages/Tools/join-merge-video/join-merge';
import { LoopVideo } from './pages/Tools/loop-video/loop-video';
import { ResizeVideo } from './pages/Tools/resize-video/resize-video';
import { RotateVideo } from './pages/Tools/rotate-video/rotate-video';
import { TranscribeVideo } from './pages/SubtitlesTranscription/transcribe-video/transcribe-video';
import { TranslateVideo } from './pages/SubtitlesTranscription/translate-video/translate-video';
import { AudioToText } from './pages/SubtitlesTranscription/audio-to-text/audio-to-text';
import { AutoSubtitles } from './pages/SubtitlesTranscription/auto-subtitles/auto-subtitles';
import { AIVideoEditor } from './pages/VideoEditor/ai-video-editor/ai-video-editor';
import { AddSubtitles } from './pages/SubtitlesTranscription/add-subtitles/add-subtitles';
import { AddImageToVideo } from './pages/VideoEditor/add-image-to-video/add-image-to-video';
import { AddMusicToVideo } from './pages/VideoEditor/add-music-to-video/add-music-to-video';
import { AddTextToVideo } from './pages/VideoEditor/add-text-to-video/add-text-to-video';
import { FilterToVideo } from './pages/VideoEditor/filter-to-video/filter-to-video';
import { AddTransitionstoVideo } from './pages/VideoEditor/add-transitions-to-video/add-transitions-to-video';
import { AddFreeStockVideo } from './pages/VideoEditor/add-free-stock-video/add-free-stock-video';
import { AIGeneratedVideoSummary } from './pages/AIGenerated/aigenerated-summary/aigenerated-summary';
import { AIGeneratedVideo } from './pages/AIGenerated/aigenerated-video/aigenerated-video';
import { AIGeneratedImage } from './pages/AIGenerated/aigenerated-image/aigenerated-image';
import { AIGeneratedVoice } from './pages/AIGenerated/aigenerated-voice/aigenerated-voice';

const BASE_FRONTEND_EDIT_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL;

const langPathCodes = [
	'en-US', 'de-DE', 'tr-TR', 'es-ES', 'it-IT', 'fr-FR', 'pt-BR',
  ];

export const Router = () => {
	const dispatch_ = useDispatch();
	const [globalState, { dispatch }] = useGlobalContext()
	const isLoading = useSelector((store) => store.maindata.loading);
	const dataLoaded = useSelector(store => store.webgldata.dataLoaded)
	const languageCode = useSelector(store => store.maindata.languageCode);
	const handleLoaded = () => {
		console.log('dataLoaded')
		
		window.dataLayer.push({
			event: 'windowLoaded',
		});

		if(localStorage.getItem('userLocale')){
			console.log('user miss logic', localStorage.getItem('userLocale'));
			setLangWithUser(localStorage.getItem('userLocale'));	
		}else{
			console.log('user MOOMOMO', window.navigator);
			setLangWithUser(window.navigator.language);
		}
		const routerName = window.location.pathname.split('/');
		const existLangCode = langPathCodes.find(langPItem => langPItem == routerName[1]);
		
		console.log('routerPath', window.location, window.scrollY, routerName);
		if(routerName[1] !== 'edit'){
			
			setAxiosAuthToken();
		}
		if(routerName[1] == 'edit'){
			window.location = `${BASE_FRONTEND_EDIT_URL}/${routerName[2]}`;
		}

		
	}
	const [basePath, setBasePath] = useState('')

	useEffect(() => {
		window.addEventListener('load', handleLoaded);

		return () => window.removeEventListener('load', handleLoaded);
	}, []);


	useEffect(()=>{
		
		const path = window.location.pathname.split(basePath, '');
		console.log('ssss',basePath, window.location, globalState, path, languageCode);
		switch(languageCode){
			case 'English':
				setBasePath('/');
				// window.location = '/en-US'+path;
				break;
			case 'Deutsch':
				setBasePath('/de-DE');
				// window.location = '/de-DE'+path;
				break;
			case 'Türkçe':
				setBasePath('/tr-TR');
				// window.location = '/tr-TR'+path;
				break;
			case 'Espanol':
				setBasePath('/es-ES');
				// window.location = '/es-ES'+path;
				break;
			case 'Italiano':
				setBasePath('/it-IT');
				// window.location = '/it-IT'+path;
				break;
			case 'Français':
				setBasePath('/fr-FR');
				// window.location = '/fr-FR'+path;
				break;
			case 'Portugues':
				setBasePath('/pt-BR');
				// window.location = '/pt-BR'+path;
				break;
			default:
				setBasePath('/');
				// window.location = '/en'+path;
			  	break;
		  }
		//   window.location = basePath+'/'+path;
	}, [languageCode])

	const [cookies, setCookie, removeCookie] = useCookies(['csrftoken']);

	const setAxiosAuthToken = async () => {
		let expires = new Date();
		localStorage.setItem('user_data', null);
		let resultState = await GetRegistration();
		
		let signed = resultState.state === 'success' && resultState.data['state'].localeCompare('loggedin') === 0;

		if (!!signed) {
			dispatch({ type: 'userData', payload: resultState.data })
      		// setLangWithUser(resultState.data.locale)
			  
			dispatch_(loginState_Store(true))
			expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
			setCookie('csrftoken', resultState.data.token, { path: '/', expires });
			localStorage.setItem('userLocale', resultState.data.locale);
			localStorage.setItem('user_data', JSON.stringify(resultState.data));
		} else {
			removeCookie('csrftoken', { path: '/', expires })
			
		};
	};

	const setLangWithUser = (langCode) => {
		const langStamp = langCode.split('-')[0];
		console.log('s', langStamp)
		switch(langStamp){
			case 'en':
				dispatch(languageStrs_Store(editorLangEnStrs));
				dispatch(languageCode_Store('English'));
				break;
			case 'de':
				dispatch(languageStrs_Store(editorLangDeStrs));
				dispatch(languageCode_Store('Deutsch'));
				break;
			case 'tr':
				dispatch(languageStrs_Store(editorLangTrStrs));
				dispatch(languageCode_Store('Türkçe'));
				break;
			case 'es':
				dispatch(languageStrs_Store(editorLangEsStrs));
				dispatch(languageCode_Store('Espanol'));
				break;
			case 'it':
				dispatch(languageStrs_Store(editorLangItStrs));
				dispatch(languageCode_Store('Italiano'));
				break;
			case 'fr':
				console.log('sdsd', langStamp);
				dispatch(languageStrs_Store(editorLangFrStrs));
				dispatch(languageCode_Store('Français'));
				break;
			case 'pt':
				dispatch(languageStrs_Store(editorLangPtStrs));
				dispatch(languageCode_Store('Portugues'));
				break;
			default:
			  dispatch(languageStrs_Store(editorLangEnStrs));
			  dispatch(languageCode_Store('English'));
			  break;
		}
	}

	return (
		<BrowserRouter basename={basePath}>
			<Routes >
				<Route path='*' element={<NotMatch />}/>
				<Route path="/signup" element={<SignUp />} />
				<Route path="/signin" element={<SignIn />} />

				<Route path="/video-editor" element={<VideoEditor />} />
				<Route path="/add-subtitles-to-video" element={<AddSubtitles />} />
				<Route path="/add-image-to-video" element={<AddImageToVideo />} />
				<Route path="/add-music-to-video" element={<AddMusicToVideo />} />
				<Route path="/add-text-to-video" element={<AddTextToVideo />} />
				<Route path="/video-filters" element={<FilterToVideo />} />
				<Route path="/add-transition-to-video" element={<AddTransitionstoVideo />} />
				<Route path="/add-free-stock-to-video" element={<AddFreeStockVideo />} />
				
				<Route path="/subtitlestranscription/*" element={<SubtitlesTranscription />} />
			
				<Route path="/tools/*" element={<Tools />} />
				<Route path="/video-converter" element={<ConvertVideo />} />
				<Route path="/video-compressor" element={<CompressVideo />} />
				<Route path="/crop-video" element={<CropVideo />} />
				<Route path="/video-trimmer" element={<CutVideo />} />
				<Route path="/merge-videos" element={<JoinMergeVideo />} />
				<Route path="/loop-video" element={<LoopVideo />} />
				<Route path="/video-resizer" element={<ResizeVideo />} />
				<Route path="/rotate-video" element={<RotateVideo />} />
				<Route path="/transcribe-video-to-text" element={<TranscribeVideo />} />
				<Route path="/video-translator" element={<TranslateVideo />} />
				<Route path="/audio-to-text" element={<AudioToText />} />
				<Route path="/subtitle-generator" element={<AutoSubtitles />} />
				
				<Route path="/more/*" element={<More />} />

				<Route path="/ai-tools/*" element={<AIGenerated />} />
				<Route path="/ai-summary-generator" element={<AIGeneratedVideoSummary />} />
				<Route path="/ai-video-generator" element={<AIGeneratedVideo />} />
				<Route path="/ai-image-generator" element={<AIGeneratedImage />} />
				<Route path="/ai-voice-generator" element={<AIGeneratedVoice />} />

				<Route path="/pricing" element={<PricingPage />} />
				{/* <Route path="/workspaces/*" element={<DashBoard />} /> */}
				<Route path="/dashboard/*" element={<DashBoard />} />
				{/* <Route path="/edit/:projectUUID" element={<VideoEditCotainer />} /> */}
				<Route path="/view/:projectUUID/:uuid" element={<ProjectView />} />
				{/* <Route path="/view/:projectUUID/:uuid" element={<AssetView />} /> */}
				<Route path="/asset/view/:uuid" element={<AssetView />} />
				<Route path="/asset/view/:uuid/:projectUUID" element={<AssetView />} />

				<Route path="/videooblog" element={<VideooBlog />} />
				<Route path="gm/videooblog" element={<VideooBlog />} />

				<Route path="/videoohowto" element={<VideooHowto />} />
				<Route path="/videoohowto/step-by-step-guide-to-using-ai-based-auto-subtitles-on-videooio" element={<Howto1 />} />
				<Route path="/videoohowto/step-by-step-guide-to-using-ai-based-auto-subtitles-on-videooio" element={<Howto2 />} />

				<Route path="/videooblog/top-online-video-editors-features-pros-cons-pricing" element={<Blog1 />} />
				<Route path="/videooblog/how-did-we-develop-videoo-io-cloud-based-video-editor" element={<Blog2 />} />
				<Route path="/videooblog/cloud-based-vs-consumer-device-video-editors" element={<Blog3 />} />
				<Route path="/videooblog/creating-engaging-social-media-content-guide-videoo-io" element={<Blog4 />} />
				<Route path="/videooblog/online-cloud-video-editors-and-cloud-storage-solution" element={<Blog5 />} />
				<Route path="/videooblog/engaging-different-demographics-on-social-media-guide-videoo-io" element={<Blog6 />} />
				<Route path="/videooblog/how-to-add-auto-subtitles-videoo-io-video-editor" element={<Blog7 />} />
				<Route path="/videooblog/auto-translated-subtitles-videos-videoo-io-editor" element={<Blog8 />} />
				<Route path="/videooblog/cloud-based-video-editors-impact-on-social-media" element={<Blog9 />} />
				<Route path="/videooblog/cloud-based-video-editors-impact-seo" element={<Blog10 />} />
				<Route path="/videooblog/using-cloud-video-editors-increase-social-media-followers" element={<Blog11 />} />
				<Route path="/videooblog/using-videoo-io-increase-social-media-followers" element={<Blog12 />} />
				<Route path="/videooblog/videoo-io-cloud-based-video-editor-every-browser-os" element={<Blog13 />} />
				<Route path="/videooblog/videoo-io-versatile-cloud-based-editor-multiple-platforms" element={<Blog14 />} />
				<Route path="/videooblog/videoo-io-online-cloud-video-editor-development-journey" element={<Blog15 />} />
				<Route path="/videooblog/top-20-cloud-based-online-video-editors" element={<Blog16 />} />

				<Route path="/careers/frontend-software-engineer-videoo" element={<Career1 />} />
				<Route path="/careers/graduate-digital-designer-videoo" element={<Career2 />} />
				<Route path="/careers/cpp-software-developer-opengl-videoo" element={<Career3 />} />
				<Route path="/careers/cloud-devops-engineer-videoo" element={<Career4 />} />
				<Route path="/careers/cloud-solution-architect-videoo" element={<Career5 />} />
				<Route path="/careers/software-engineer-in-test-videoo" element={<Career6 />} />
				<Route path="/careers/product-owner-videoo" element={<Career7 />} />
				<Route path="/careers/chief-financial-officer-cfo-videoo" element={<Career8 />} />
				<Route path="/careers/backend-software-engineer-videoo" element={<Career9 />} />
				<Route path="/careers/video-producer-online-editor-videoo" element={<Career10 />} />

				<Route path="/terms" element={<Terms />} />
				<Route path="/cookies" element={<Cookies />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/security" element={<Security />} />
				<Route path="/videoocareers" element={<Career />} />

				<Route path="/app" element={<App />} />
				<Route path='/signout' element={<App />}/>
				<Route path="/" element={<Home />} />
				
			</Routes>

			<Loading show={globalState.loading} opacity={0.7} />

			{(isLoading || !dataLoaded) && (
				<div className='projects-loading'>
					<ReactLoading type={'spinningBubbles'} color='rgb(53 126 221)' height={100} width={100} />
				</div>
			)}
		</BrowserRouter>
	)
}