import './blogs/videooblog/videooblog.scss'
import { Layouts } from '../component/layouts/layouts'

export const Security = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Security Statement
          </div>

          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              At Videoo.io, we take the security of your data and information seriously. We have implemented robust measures to ensure the protection and integrity of our online cloud-based video editor. This Security Statement outlines our approach to security and the steps we take to safeguard your data.

              <br /><br />

              <span className='font-semibold'>Data Protection:</span> We employ industry-standard security measures to protect your data from unauthorized access, disclosure, or alteration. This includes encryption of data transmission, secure storage, and access controls.

              <br /><br />

              <span className='font-semibold'>Secure Infrastructure:</span> Our infrastructure is hosted on reputable and secure cloud platforms that adhere to stringent security standards. We regularly update and patch our systems to protect against vulnerabilities.

              <br /><br />

              <span className='font-semibold'>User Authentication:</span> We use secure user authentication mechanisms to ensure that only authorized individuals can access and use Videoo.io. This may include passwords, multi-factor authentication, and account verification processes.

              <br /><br />

              <span className='font-semibold'>Data Privacy:</span> We respect your privacy and adhere to applicable data protection laws. We collect and process your personal information in accordance with our Privacy Policy, and we do not sell or share your information with third parties without your consent.

              <br /><br />

              <span className='font-semibold'>Regular Audits:</span> We conduct regular security audits and assessments to identify and address any potential vulnerabilities or risks. This includes internal reviews as well as third-party audits by independent security experts.

              <br /><br />

              <span className='font-semibold'>Employee Training:</span> Our employees undergo security training to ensure they understand their roles and responsibilities in protecting your data. We enforce strict confidentiality obligations for all our employees.

              <br /><br />

              <span className='font-semibold'>Incident Response:</span> In the event of a security incident, we have established procedures to promptly respond, investigate, and mitigate the impact. We will notify you in accordance with applicable laws and regulations if there is a breach that may affect your data.

              <br /><br />

              <span className='font-semibold'>Continuous Improvement:</span> We continuously monitor and improve our security practices to stay abreast of emerging threats and maintain a high level of security for Videoo.io. We engage in ongoing risk assessments and implement appropriate security measures accordingly.

              <br /><br />

              <span className='font-semibold'>Contact Us:</span> If you have any questions or concerns about the security of Videoo.io or if you believe there has been a security incident, please contact us at security@videoo.io.

            </div>

          </div>
        </div>
      </div>
    </Layouts>
    )
}