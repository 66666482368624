import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { EmptyCom } from '../EmptyCom';
import { Box, Typography, Grid, Button, Card, FormControlLabel, Switch, TextField, Stack } from "@mui/material";
import { getApikeysConfig,  saveApikeysConfig } from '../../../../axios/ApiProvider'

import "./apikeys.scss"

export const ApiKeys = () => {
  
  const [adobeKey, setAdobeKey] = useState('');
  const [youtubeKey, setYoutubeKey] = useState('');
  const [twitterKey, setTwitterKey] = useState('');
  const [linkedinKey, setLinkedinKey] = useState('');
  const [instagramKey, setInstagramKey] = useState('');
  const [tiktokKey, setTiktokKey] = useState('');
  const [facebookKey, setFacebookKey] = useState('');

  useEffect(()=>{
    loadApiKeys();
  }, [])

  const loadApiKeys = async () => {
    const apiKeyRes = await getApikeysConfig();
    console.log(apiKeyRes);
    // if(apiKeyRes.state != 'success') return;

    setAdobeKey(apiKeyRes.data?.config.adobe_stock_access_token);
    setYoutubeKey(apiKeyRes.data?.config.youtube_api_key);
    setTwitterKey(apiKeyRes.data?.config.twitter_api_key);
    setLinkedinKey(apiKeyRes.data?.config.linkedin_api_key);
    setInstagramKey(apiKeyRes.data?.config.instagram_api_key);
    setTiktokKey(apiKeyRes.data?.config.tiktok_api_key);
    setFacebookKey(apiKeyRes.data?.config.facebook_api_key)

  }

  const SaveApiKeys = async () => {
    
    const apiKeyPayload = {
      adobe: adobeKey,
      youtube: youtubeKey,
      facebook: facebookKey,
      twitter: twitterKey,
      instagram: instagramKey,
      tiktok: tiktokKey,
      linkedin: linkedinKey
    }
    const saveApiRes = await saveApikeysConfig(apiKeyPayload);
    console.log(saveApiRes);
  }

  return (
    <Box className="custom-apikeys-wrapper">
      <Box className="custom-apikeys-toolbar-section">
        <Box className="toolbar-Breadcrumbs-contain">
          <Link to={`#`}>Api Keys</Link>
        </Box>
      </Box>
      <Box p={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth id="outlined-basic" label="Adobe" variant="outlined" 
              value={adobeKey} onChange={(e) => {setAdobeKey(e.target.value)}}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="outlined-basic" label="Youtube" variant="outlined" 
              value={youtubeKey} onChange={(e) => {setYoutubeKey(e.target.value)}}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="outlined-basic" label="Twitter" variant="outlined" 
              value={twitterKey} onChange={(e) => {setTwitterKey(e.target.value)}}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="outlined-basic" label="LinkedIn" variant="outlined"
              value={linkedinKey} onChange={(e) => {setLinkedinKey(e.target.value)}}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="outlined-basic" label="Instagram" variant="outlined" 
              value={instagramKey} onChange={(e) => { setInstagramKey(e.target.value) }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="outlined-basic" label="Tiktok" variant="outlined"
            value={tiktokKey} onChange={(e) => {setTiktokKey(e.target.value)}}
             />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="outlined-basic" label="Facebook" variant="outlined" value={facebookKey} onChange={(e) => {setFacebookKey(e.target.value)}} />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={() => SaveApiKeys()}>Save</Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}