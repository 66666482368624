import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Box, Typography, Grid, Card, FormControlLabel, Switch, TextField } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Swal from 'sweetalert2';

import {membershipPlan} from "../../dashboard.config"
import { deleteAccount,  GetEmailMarketing } from '../../../../axios/ApiProvider'

import { EmptyCom } from '../EmptyCom';

import "./profile.scss"

export const Profile = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const loginState = useSelector((store) => store.maindata.loginState)
  const [userData, setUserData] = useState({})
  const [mailMarket, setMailMarket] = useState(false);
  const [mailNotify, setMailNotify] = useState(false);
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') {
      tempData = JSON.parse(item)
    }

    setUserData(tempData)
    console.log('saved', tempData);
  }, [loginState])

  const handleClickDeleteAccount = async () => {
    Swal.fire({
      position: "center",
      icon: "success",
        text: "This action is not applicable for the time being. Please contact the support team via info@videoo.io for further assistance.",
      showConfirmButton: true,
      confirmButtonText: "Okay",
    }).then((result) => {
    });


    // const result = await deleteAccount(userData.uuid)
    // if (result.state !== 'success') {
    //   return window.toastr.error(result.data.message)
    // } else {
    //   localStorage.clear();
    //   window.location.href = "/";
    // }
  }

  useEffect(() => {
    (async() => {
      var marketData = await GetEmailMarketing();
      setMailMarket(marketData.data.marketing_email);
      setMailNotify(marketData.data.notification_email);
    })()
  }, [])

  const onChangeMailReference = () => {
    console.log(mailMarket, mailNotify);
  }
  const onClickMailMarket = async () => {
    //setMailMarket(!mailMarket);
    var marketData = await GetEmailMarketing(!mailMarket, mailNotify);
      setMailMarket(marketData.data.marketing_email);
      setMailNotify(marketData.data.notification_email);
  }
  const onClickMailNotify = async () => {
    //setMailNotify(!mailNotify);
    var marketData = await GetEmailMarketing(mailMarket, !mailNotify);
      setMailMarket(marketData.data.marketing_email);
      setMailNotify(marketData.data.notification_email);
  }

  return (
    <Box className="custom-profile-wrapper">
      <Box className="custom-profile-toolbar-section">
        <Box className="toolbar-Breadcrumbs-contain">
          <Link to={`#`}>{languageStrs.profile}</Link>
        </Box>
      </Box>
      <Box p={6}>
        {!userData.state && <>
          <EmptyCom />
        </>}
        {!!userData.state && 
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item lg={4} md={6} xs={12}>
                <Typography variant='h5' textAlign="center" lineHeight="1.5" className="profile-label">{languageStrs.name}</Typography>
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <input className="profile-textfield" value={userData.firstname} disabled />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item lg={4} md={6} xs={12}>
                <Typography variant='h5' textAlign="center" >{languageStrs.surname}</Typography>
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <input className="profile-textfield" value={userData.lastname} disabled />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item lg={4} md={6} xs={12}>
                <Typography variant='h5' textAlign="center">{languageStrs.email}</Typography>
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <input className="profile-textfield" value={userData.email} disabled />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item lg={4} md={6} xs={12}>
                <Typography variant='h5' textAlign="center">{languageStrs.plan}</Typography>
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <input className="profile-textfield" value={membershipPlan[userData.subscription] || "Free"} disabled />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item md={6} xs={12}></Grid>
              <Grid item md={6} xs={12}>
                <FormControlLabel className='ml-3'control={<Switch checked={mailMarket} onClick={() => onClickMailMarket()}/>} label={languageStrs.market_emails} />
                <FormControlLabel className='ml-3'control={<Switch checked={mailNotify} onClick={() => onClickMailNotify()}/>} label={languageStrs.editor_notification} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Card >
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {languageStrs.storage} <FormControlLabel className='ml-3'control={<Switch checked={userData.storage_usage_state} readOnly/>} label="" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {userData.storage_usage_size}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6} xs={12}>
                <Card >
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
                      <span style={{display: 'flex'}}>
                        {languageStrs.subtitle} <FormControlLabel className='ml-3'control={<Switch checked={userData?.subtitle_state || false} readOnly/>} label="" />
                      </span> 
                      <TextField
                        label="Remaining (mins)"
                        id="outlined-size-small"
                        size="small"
                        readOnly
                        value={userData?.subtitle_remaining}
                      />
                    </Typography>
                    
                    <Typography variant="body2" color="text.secondary">
                      {userData?.subtitle_time}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={12} xs={12}>
                <Card >
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
                      <span style={{display: 'flex'}}>
                        {languageStrs.text_to_speech} <FormControlLabel className='ml-3'control={<Switch checked={userData?.texttospeech_state || false} readOnly/>} label="" />
                      </span> 
                      <TextField
                        label="Remaining (characters)"
                        id="outlined-size-small"
                        size="small"
                        readOnly
                        value={userData?.texttospeech_remaining}
                      />
                    </Typography>
                    
                    <Typography variant="body2" color="text.secondary">
                      {userData?.texttospeech_status}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Grid container>
              <Grid item lg={4} md={3} xs={1}></Grid>
              <Grid item lg={4} md={6} xs={10}>
                <Box className="profile-delete-account" onClick={handleClickDeleteAccount}>
                  <Typography variant='h5' textAlign="center">{languageStrs.delete_account}</Typography>
                  <Typography variant='h6' textAlign="center">({languageStrs.this_action_irreversibe})</Typography>
                </Box>
              </Grid>
              <Grid item lg={4}md={3} xs={1}></Grid>
            </Grid>
          </Grid>
        </Grid>}
      </Box>

    </Box>
  )
}