import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box, Menu, Divider, MenuItem, Button } from "@mui/material"
import axios from 'axios';
import { FlagIcon } from "react-flag-kit";
import { CircleFlag } from 'react-circle-flags'
import './header.scss';
import logoimg from '../../assets/image/Logo.png';
import { MenuIemsContainner } from './common/menuItems';
import { MobileMenu } from './common/mobileMenu';
import { BASE_BACKEND_URL, setUserlanguage } from "../../axios/ApiProvider"
import { tips } from '../../util'
import { Helmet } from "react-helmet";
import {languageCode_Store} from '../../store/actions/mainAction';
import { languageStrs_Store } from '../../store/actions/editAction';
import { editorLangEnStrs } from '../../lang/editor_string_en';
import { editorLangDeStrs } from '../../lang/editor_string_de';
import { editorLangFrStrs } from '../../lang/editor_string_fr';
import { editorLangItStrs } from '../../lang/editor_string_it';
import { editorLangPtStrs } from '../../lang/editor_string_pt';
import { editorLangTrStrs } from '../../lang/editor_string_tr';
import { editorLangEsStrs } from '../../lang/editor_string_es';
import LanguageIcon from '@mui/icons-material/Language';

const pageTitleAndDetail = [
	{
		pagelink: '/',
		title: 'Videoo | All in One AI Video Editor, Create Video Free',
		detail: `Videoo is an AI video editor with text to video, avatars, auto-subtitles, voice translations and more. Make the best videos easily and free. Try now!`
	},
	{
		pagelink: '/more/compress-video',
		title: `Video Compression & Conversion Software Online - Videoo.io`,
		detail: `We are your solution for effortless video compression! Use Videoo.io, a user-friendly online editor, to quickly reduce file sizes while maintaining quality.`
	},
	{
		pagelink: '/subtitlestranscription/add-subtitles',
		title: `Automatic Video Subtitle Generator Software Online - Videoo.io`,
		detail: `Compress your videos seamlessly with Videoo.io, a cloud-based online video editor. No need for expensive software just upload your video, customize.`
	},
	{
		pagelink: '/subtitlestranscription/translate-video',
		title: `Multilingual Video Editing Platform | Video Editor with Auto-Translate Software - Videoo.io`,
		detail: `Video Editing Software is designed for everyone, offering a seamless, easy to use experience with multi-language support and auto-translate features.`
	},
	{
		pagelink: '/videoeditor/filter-to-video',
		title: `Video Color Correction Filter Tool Online - Videoo.io`,
		detail: `If you want captivating videos, enhance them with Videoo.io! Easily add filters, customize intensity, and create professional, engaging content.`
	},
	{
		pagelink: '/videooblog',
		title: `Checkout Our Videoo Blog Guide | VIDEOO`,
		detail: `Checkout our comprehensive Video Editing Guide on VIDEOO. Learn expert tips and tricks for enhancing your video content. Start editing and optimizing now.`
	},
	{
		pagelink: '/videooblog/how-did-we-develop-videoo-io-cloud-based-video-editor',
		title: `How did we develop Videoo.io, the cloud based video editor | VIDEOO`,
		detail: `Acquire professional tips and techniques to enhance your video content. Begin your journey in editing and optimizing your videos today.`
	},
	{
		pagelink: '/videooblog/creating-engaging-social-media-content-guide-videoo-io',
		title: `Creating Engaging Social Media Content with Videoo.io: A Guide | VIDEOO`,
		detail: `Craft compelling social media content effortlessly with Videoo.io. Dive into our guide for expert tips on creating engaging videos.`
	},
	{
		pagelink: '/videooblog/top-online-video-editors-features-pros-cons-pricing',
		title: `Top Online Video Editors: Features, Pros and Cons, and Pricing | VIDEOO`,
		detail: `Top online video editors with VIDEOO for features, pros, cons, and pricing. Make an informed choice to enhance your video projects.`
	},
	{
		pagelink: '/videooblog/cloud-based-vs-consumer-device-video-editors',
		title: `Cloud-Based Online Video Editors vs Consumer Device Video Editors | VIDEOO`,
		detail: `Check out the strengths of cloud-based online video editors versus consumer device video editors with VIDEOO. Find the positives to pick the perfect solution.`
	},
	{
		pagelink: '/pricing',
		title: `Checkout Our Price of Video Editing Software Service | VIDEOO`,
		detail: `See how affordable Videoo's video editing software service is with clear pricing options. Tailor your choices to fit your budget and kickstart your video.`
	},
	{
		pagelink: '/videooblog/cloud-based-video-editors-impact-on-social-media',
		title: `Cloud-Based Video Editors and Their Impact on Social Media | VIDEOO`,
		detail: `Cloud-Based Video Editors, available on VIDEOO, are shaping the landscape of social media. Learn about the transformative impact they bring to content creation.`
	},
	{
		pagelink: '/videooblog/engaging-different-demographics-on-social-media-guide-videoo-io',
		title: `A Guide to Using Videoo.io's Online Video Editor for Tailored Content Creation | VIDEOO`,
		detail: `Personalized content creation with the help of Videoo.io's Online Video Editor. This guide takes you step-by-step through the platform's features.`
	},
	{
		pagelink: '/videooblog/online-cloud-video-editors-and-cloud-storage-solution',
		title: `Relationship Between Online Cloud-Based Video Editors and Cloud Storage | VIDEOO`,
		detail: `Learn how these two components work hand in hand, providing convenience and efficiency for your video editing and storage needs.`
	},
	{
		pagelink: '/videooblog/auto-translated-subtitles-videos-videoo-io-editor',
		title: `How to Add Auto-Translated Subtitles to Your Videos Using Videoo.io's | VIDEOO`,
		detail: `Learn how these accessible tools have revolutionized video creation, allowing individuals and businesses to share engaging content effortlessly.`
	},
	{
		pagelink: '/videooblog/videoo-io-cloud-based-video-editor-every-browser-os',
		title: `Cloud-Based Online Video Editor That Works with Every Browser And Operating System | VIDEOO`,
		detail: `Cloud-based online video editor that seamlessly operates across every browser and operating system. Enjoy seamless and universal video editing.`
	},
	{
		pagelink: '/videooblog/using-videoo-io-increase-social-media-followers',
		title: `Using Videoo.io to Increase Social Media Followers | VIDEOO`,
		detail: `Learn how to leverage its features and capabilities to attract and increase your followers on various platforms to enhance your social media presence.`
	},
	{
		pagelink: '/subtitlestranscription/auto-subtitles',
		title: `Automatic Video Subtitle Generator Software Online - Videoo.io`,
		detail: `Compress your videos seamlessly with Videoo.io, a cloud-based online video editor. No need for expensive software just upload your video, customize.`
	},
	{
		pagelink: '/video-editor',
		title: 'Online Video Editor, Video Maker, AI Video Editing | Videoo',
		detail: `Free AI video editor with text to video, auto-subtitles, voice translations, AI images, voices and more. Make, edit and share your videoas easily.`
	},
	{
		pagelink: '/video-editor/add-image-to-video',
		title: 'Add Image to Video, Overlay Logo, Picture & Watermarks | Videoo',
		detail: `Easily add images to video with Videoo's easy drag-and-drop tool. Bulk upload images and create videos from your photos. Try now!`
	},
	{
		pagelink: '/video-editor/add-music-to-video',
		title: 'Add Audio to Video - Add Music, Songs and Sound Effects | Videoo',
		detail: `Add music or audio, music or sound effect to video online for free without even downloading any program to your device. Try now!`
	},
	{
		pagelink: '/video-editor/add-text-to-video',
		title: 'Add Text to Video Online, Put Text on a Video | Videoo',
		detail: `Easily add text to video in just a few clicks. Use Videoo's text tool and type or paste your text. You can customize the font, color or size. Try now!`
	},
	{
		pagelink: '/video-editor/video-filters',
		title: 'Video Filters, Add Filters to Your Videos Online | Videoo',
		detail: `Use Videoo to add filters to your videos! Choose from more than 10 filters including Black & White, Vintage and Sepia. Download and share videos easily. Try now!`
	},
	{
		pagelink: '/video-editor/add-transition-to-video',
		title: 'Add Transition Effect to Your Videos Online | Videoo',
		detail: `Add transition effects in between video clips to create a stunning effect with Videoo. Try now!`
	},
	{
		pagelink: '/video-editor/add-free-stock-to-video',
		title: 'Add Free Stock to Vİdeo Online | Videoo',
		detail: `Easily add free stock images and videos to your video with Videoo's easy drag-and-drop tool. Try now!`
	},
	{
		pagelink: '/video-editor/add-subtitles-to-video',
		title: 'Add Subtitles to Video, Accurate Subtitle | Videoo',
		detail: `Automatically add subtitles to your video. Upload your video and generate 95% accurate subtitles. Videoo will auto-generate subs for you. Try now!`
	},
	{
		pagelink: '/video-tools',
		title: 'All Video Editing Tools You Need | Videoo',
		detail: ``
	},
	{
		pagelink: '/video-tools/video-converter',
		title: 'Video Converter, Convert Video to MP4, AVI, and More | Videoo',
		detail: ``
	},
	{
		pagelink: '/video-tools/video-compressor',
		title: 'Videoo Compressor, Compress Video - No Loss in Quality | Videoo',
		detail: `Easily compress your videos with Videoo's video compressor tool that helps you to reduce the size of your video with marginal change in video quality. Try now!`
	},
	{
		pagelink: '/video-tools/video-resizer',
		title: 'Video Resizer, Resize Video Online | Videoo',
		detail: `Use free video resizer to change video resolution or/and video aspect ratio. Resize videos free for social media, email or business. Try now!`
	},
	{
		pagelink: '/video-tools/crop-video',
		title: 'Crop Video Online, Video Cropper | Videoo',
		detail: `Use Videoo's online video cropper. Crop and trim MP4 or WebM files, up to 200MB. Upload video and select the area you want to crop`
	},
	{
		pagelink: '/video-tools/video-trimmer',
		title: 'Video Trimmer, Cut Video Online | Videoo',
		detail: `Trim a video for free and online with Video's easy-to-use video cutter tool hassle-free. Cut your videos easily, try now!`
	},
	{
		pagelink: '/video-tools/loop-video',
		title: 'Loop and Repeat Video Online | Videoo',
		detail: `Loop your video to repeat it over and over again or create GIF with endless video loops at Videoo. Try now!`
	},
	{
		pagelink: '/video-tools/rotate-video',
		title: 'Video Rotator, Rotate Video Online for Free | Videoo',
		detail: `Rotate a video online with Videoo's free video rotator. Upload your video and just rotate your video however you need. Try now!`
	},
	{
		pagelink: '/video-tools/transcribe-video-to-text',
		title: 'Transcribe Video to Text | Videoo',
		detail: `Convert your videos to text online using Videoo's video transcription tool. Transcribe videos with AI at lightning-fast speed, try now!`
	},
	{
		pagelink: '/video-tools/video-translator',
		title: 'AI Video Translator, Translate Videos Online | Videoo',
		detail: `Instantly translate videos online up to 50 languages using Videoo's diverse portfolio of AI voices. Try now!`
	},
	{
		pagelink: '/video-tools/merge-videos',
		title: 'Merge Videos Online, Combine Video Clips | Videoo',
		detail: `Upload and merge videos online for free! Combine multiple video clips in a couple of clicks with Videoo's easy-to-use drag and drop tool.`
	},
	{
		pagelink: '/video-tools/audio-to-text',
		title: 'Transcribe Audio to Text, AI Transcription | Videoo',
		detail: `Videoo's audio to text transcription tool uses speech recognition to automatically convert audio and video files to text with AI.`
	},
	{
		pagelink: '/video-tools/subtitle-generator',
		title: 'AI Powered Subtitle Generator Online | Videoo',
		detail: `Videoo's generator app that auto generate subtitles/captions for movies, home videos, tiktoks, reels, youtube shorts & more. Upload, transcribe & download.`
	},
	{
		pagelink: '/ai-tools',
		title: 'AI Tools for Your Videos | Videoo',
		detail: `Try Videoo's AI toolkit to boost your video editing process. Find the best images, voices or extra videos for your videos.`
	},
	{
		pagelink: '/ai-video-summarizer',
		title: 'AI Video Summarizer: Turn Video to Video Summary | Videoo',
		detail: `Create a Video Summary from your videos using only text directives easily using Videoo.io's AI Summary Tool. Try now for free.`
	},
	{
		pagelink: '/ai-image-generator',
		title: 'AI Image Generator: Turn Text to Images | Videoo',
		detail: `Generate an image from text in seconds with Videoo's AI image generator and use in your videos easily. Try now for free.`
	},
	{
		pagelink: '/ai-video-generator',
		title: 'AI Video Generator: Turn Text to Video | Videoo',
		detail: `Generate video from text in seconds with Videoo's AI video generator and use in your videos easily. Try now for free.`
	},
	{
		pagelink: '/ai-voice-generator',
		title: 'AI Voice Generator: Turn Text to Voice | Videoo',
		detail: `Generate voice from text in seconds with Videoo's AI voice generator and use in your videos easily. Try now for free.`
	}
]

const REACT_APP_BASE_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_URL;

const langPathCodes = [
  'en-US', 'de-DE', 'tr-TR', 'es-ES', 'it-IT', 'fr-FR', 'pt-BR',
];

export const Header = ({ pagename }) => {
	const languageStrs = useSelector((store) => store.editdata.languageStrs)
	const loginState = useSelector((store) => store.maindata.loginState);
	const pageLocation = window.location;
	
	const tmpTitleData = pageTitleAndDetail.find(item => item.pagelink == pageLocation.pathname)
	const titleData =tmpTitleData ? tmpTitleData : pageTitleAndDetail[0];
	
	const [userData, setUserData] = useState({})
	useEffect(() => {
		let tempData = {}
		let item = localStorage.getItem('user_data')
		if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)
		setUserData(tempData)
	}, [loginState])

	const toHome = e => {
		e.preventDefault();
		window.location.href = "/";
	}

	return (
		pagename === 'pricing' ? <>
			<Box className="logoImg">
				<Link to={'../'}>
					<img src={logoimg} alt='logoimg' />
				</Link>
			</Box>

			<Box className="menu-containner">
				<Box className="menu-left">
					<MenuIemsContainner />

					<Link to='../pricing'>
						<Box className="pricing-btn">{languageStrs.pricing}</Box>
					</Link>
				</Box>


				<Box className="menu-right">
					<Box className="d-flex language-btn">
					  <LangChangeSelector />
					</Box>
					{!loginState ? <>
						<Link to='/signin' className="login-containner">
							<Box className="login-btn">{languageStrs.login}</Box>
						</Link>

						<Link to='/signup' className="signUp-containner">
							<Box className="signUp-btn">{languageStrs.get_started_free}</Box>
						</Link>
					</> : (
						<UserAvatar userData={userData} />
						// <Link to='/signout' className="signOut-containner">
						// 	<Box className="signOut-btn">Logout</Box>
						// </Link>
					)}
				</Box>
			</Box>

			<Box className="mobile-container">
				<UserAvatar userData={userData} />
				<MobileMenu pagename={pagename} />
			</Box>
		</> : <>
		<Helmet>
			<title>{titleData.title}</title>
			<meta name="description"
    		content={titleData.detail} />
			<link rel="canonical" href={pageLocation.href} />
		
		</Helmet>
			<Box className="mobile-container"><MobileMenu /></Box>

			<Box className="logoImg">
				<Link to={'..'} onClick={toHome}>
					<img src={logoimg} alt='logoimg' />
				</Link>
			</Box>

			<Box className="menu-containner">
				<Box className="menu-left">
					<MenuIemsContainner />
				</Box>
				
				<Box className="menu-right">
          <Box className="d-flex language-btn">
            <LangChangeSelector />
          </Box>
					<Link to='../pricing'>
						<Box className="pricing-btn">{languageStrs.pricing}</Box>
					</Link>

					{!loginState ? <>
						<Link to='/signin' className="login-containner">
							<Box className="login-btn">{languageStrs.login}</Box>
						</Link>

						<Link to='/signup' className="signUp-containner">
							<Box className="signUp-btn">{languageStrs.sign_up_home}</Box>
						</Link>
					</> : (
						<UserAvatar userData={userData} />
						// <Link to='/signout' className="signOut-containner">
						// 	<Box className="signOut-btn">Logout</Box>
						// </Link>
					)}
				</Box>
			</Box>
		</>
	)
}
const LangChangeSelector = () => {
	
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [isLanglist, setIsLanglist] = useState(null);
	const languageCode = useSelector((store) => store.maindata.languageCode);
	const handleLangSelected = (langStr) => {
		// navigate("/tr-TR");
	  	dispatch(languageCode_Store(langStr));
	  	setIsLanglist(null);
      let urlPathname = location.pathname;
    langPathCodes.map(codeItem => {
      urlPathname = urlPathname.replaceAll('/'+codeItem+'/', '/');
    })
    console.log(langStr, 'ssssssssssss', urlPathname)
      switch(langStr){
        case 'English':
          navigate("/../"+urlPathname);
          break;
        case 'Deutsch':
          navigate("/../de-DE"+urlPathname);
          // navigate(0);
          break;
        case 'Türkçe':
          navigate("/../tr-TR"+urlPathname);
          break;
        case 'Espanol':
          navigate("/../es-ES"+urlPathname);
          break;
        case 'Italiano':
          navigate("/../it-IT"+urlPathname);
          break;
        case 'Français':
          navigate("/../fr-FR"+urlPathname);
          break;
        case 'Portugues':
          navigate("/../pt-BR"+urlPathname);
          break;
        default:
          break;
      }
	}
  useEffect(() => {
	const routerName = window.location.pathname.split('/');
	const existLangCode = langPathCodes.find(langPItem => langPItem == routerName[1]);
	if (existLangCode) {
		switch (existLangCode) {
			case 'en-US':
				dispatch(languageCode_Store('English'))
				break;
			case 'de-DE':
				dispatch(languageCode_Store('Deutsch'))
				break;
			case 'tr-TR':
				dispatch(languageCode_Store('Türkçe'))
				break;
			case 'es-ES':
				dispatch(languageCode_Store('Espanol'))
				break;
			case 'it-IT':
				dispatch(languageCode_Store('Italiano'))
				break;
			case 'fr-FR':
				dispatch(languageCode_Store('Français'))
				break;
			case 'pt-BR':
				dispatch(languageCode_Store('Portugues'))
				break;
			default:
				dispatch(languageCode_Store('English'))
				break;
		}
	}else{
		dispatch(languageCode_Store('English'))
	}
    // if(localStorage.getItem('userLocale')){
    //   console.log('user miss logic', localStorage.getItem('userLocale'));
    //   setLangWithUser(localStorage.getItem('userLocale'));	
    // }else{
    //   console.log('user MOOMOMO', window.navigator);
    //   setLangWithUser(window.navigator.language);
    // }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])
  
  const setLangWithUser = (langCode) => {
	const langStamp = langCode.split('-')[0];
	console.log('s', langStamp)
	switch(langStamp){
		case 'en':
			dispatch(languageStrs_Store(editorLangEnStrs));
			dispatch(languageCode_Store('English'));
			break;
		case 'de':
			dispatch(languageStrs_Store(editorLangDeStrs));
			dispatch(languageCode_Store('Deutsch'));
			break;
		case 'tr':
			dispatch(languageStrs_Store(editorLangTrStrs));
			dispatch(languageCode_Store('Türkçe'));
			break;
		case 'es':
			dispatch(languageStrs_Store(editorLangEsStrs));
			dispatch(languageCode_Store('Espanol'));
			break;
		case 'it':
			dispatch(languageStrs_Store(editorLangItStrs));
			dispatch(languageCode_Store('Italiano'));
			break;
		case 'fr':
			console.log('sdsd', langStamp);
			dispatch(languageStrs_Store(editorLangFrStrs));
			dispatch(languageCode_Store('Français'));
			break;
		case 'pt':
			dispatch(languageStrs_Store(editorLangPtStrs));
			dispatch(languageCode_Store('Portugues'));
			break;
		default:
		  dispatch(languageStrs_Store(editorLangEnStrs));
		  dispatch(languageCode_Store('English'));
		  break;
	}
}

	useEffect(() => {
		
	  switch(languageCode){
		case 'English':
			dispatch(languageStrs_Store(editorLangEnStrs));
			setUserlanguage('en');
			break;
		case 'Deutsch':
			dispatch(languageStrs_Store(editorLangDeStrs));
			setUserlanguage('de');
			break;
		case 'Türkçe':
			dispatch(languageStrs_Store(editorLangTrStrs));
			setUserlanguage('tr');
			break;
		case 'Espanol':
			dispatch(languageStrs_Store(editorLangEsStrs));
			setUserlanguage('es');
			break;
		case 'Italiano':
			dispatch(languageStrs_Store(editorLangItStrs));
			setUserlanguage('it');
			break;
		case 'Français':
			dispatch(languageStrs_Store(editorLangFrStrs));
			setUserlanguage('fr');
			break;
		case 'Portugues':
			dispatch(languageStrs_Store(editorLangPtStrs));
			setUserlanguage('pt');
			break;
		default:
		
		  dispatch(languageStrs_Store(editorLangEnStrs));
		//   setUserlanguage('en');
		  break;
	  }
	  
	  console.log('myloca', languageCode, location);
	}, [languageCode])
  
  const renderLanguageBtn = () => {
    switch(languageCode){
      case 'English':
        return  <CircleFlag className="mr-2" countryCode="us" height={25} width={25} />;
        break;
      case 'Deutsch':
        return <CircleFlag className="mr-2" countryCode="de" height={25} width={25} />;
        break;
      case 'Türkçe':
        return <CircleFlag className="mr-2" countryCode="tr" height={25} width={25} />;
        break;
      case 'Espanol':
        return <CircleFlag className="mr-2" countryCode="es" height={25} width={25} />;
        break;
      case 'Italiano':
        return <CircleFlag className="mr-2" countryCode="it" height={25} width={25} />;
        break;
      case 'Français':
        return <CircleFlag className="mr-2" countryCode="fr" height={25} width={25} />;
        break;
      case 'Portugues':
        return <CircleFlag className="mr-2" countryCode="pt" height={25} width={25} />;
        break;
    default:
      return  <CircleFlag className="mr-2" countryCode="us" height={25} width={25} />;
        break;
      }
  }

	return (
	  <>
		<Button
		  id="demo-positioned-button"
		  onClick={(e) => { setIsLanglist(e.currentTarget) }}
		>
		  {renderLanguageBtn()}
		</Button>
		<Menu
		  id="basic-menu"
		  anchorEl={isLanglist}
		  open={Boolean(isLanglist)}
		  onClose={() => { setIsLanglist(null) }}
		  MenuListProps={{
			'aria-labelledby': 'basic-button',
		  }}
		>
		  <MenuItem onClick={() => handleLangSelected('English')}>
		  	<CircleFlag className="mr-2" countryCode="us" height={25} width={25} /> English
		  </MenuItem>
		  <MenuItem onClick={() => handleLangSelected('Türkçe')}>
			  <CircleFlag className="mr-2" countryCode="tr" height={25} width={25} /> Türkçe
		  </MenuItem>
		  <MenuItem onClick={() => handleLangSelected('Français')}>
        <CircleFlag className="mr-2" countryCode="fr" height={25} width={25} /> Français
      </MenuItem>
		  <MenuItem onClick={() => handleLangSelected('Deutsch')}>
        <CircleFlag className="mr-2" countryCode="de" height={25} width={25} /> Deutsch
      </MenuItem>
		  <MenuItem onClick={() => handleLangSelected('Espanol')}>
        <CircleFlag className="mr-2" countryCode="es" height={25} width={25} /> Espanol
      </MenuItem>
		  <MenuItem onClick={() => handleLangSelected('Italiano')}>
        <CircleFlag className="mr-2" countryCode="it" height={25} width={25} /> Italiano
      </MenuItem>
		  <MenuItem onClick={() => handleLangSelected('Portugues')}>
        <CircleFlag className="mr-2" countryCode="pt" height={25} width={25} /> Portugues
      </MenuItem>
		</Menu>
	  </>
	)
  }
export const UserAvatar = ({ userData, page }) => {
	const languageStrs = useSelector((store) => store.editdata.languageStrs)
	const [planMenuEl, setPlanMenuEl] = useState(null)

	let firName = userData?.firstname || ''
	let lastname = userData?.lastname || ''

	const handleSignOut = () => {
		setPlanMenuEl(null)
		localStorage.clear();
		axios({
			method: 'get',
			url: `${BASE_BACKEND_URL}/registration/logout/`,
			withCredentials: true,
		}).then(res => {
			window.location.href = '/';
		})
			.catch((err) => {
				tips('error', "Log out failure. Please try again.")
			});
	}

	return (
		<>
			{!!userData?.picture && (
				<img alt=''
					src={userData?.picture}
					className="w-40 h-40 rounded-md cursor-pointer"
					onClick={(e) => { setPlanMenuEl(e.currentTarget) }}
				/>
			)}

			{!userData?.picture && (
				<div onClick={(e) => { setPlanMenuEl(e.currentTarget) }}
					className="w-40 h-40 flex justify-center items-center bg-[#b66eff] rounded-full cursor-pointer"
				>
					<span className="text-15 text-white font-extrabold">
						{firName.slice(0, 1) + lastname.slice(0, 1)}
					</span>
				</div>
			)}
			
			<Menu
				anchorEl={planMenuEl}
				open={Boolean(planMenuEl)}
				onClose={() => { setPlanMenuEl(null) }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
				transformOrigin={{ vertical: 'top', horizontal: 'right', }}
				PaperProps={{ sx: { mt: 1 } }}
				className="default-menu"
			>
				<div className="py-5 border-1 border-[#140505] bg-white rounded-lg">
					<div className="flex flex-col gap-5 items-center justify-center">
						<span className="flex flex-col gap-5 px-10 items-center justify-center cursor-pointer">
							<span className="text-15 font-semibold leading-none">
								{firName + ' ' + lastname}
							</span>

							<span className="text-10 font-semibold leading-none hidden md:block">
								{userData?.email}
							</span>
						</span>
						
						<Divider className="w-full" />

						{page !== 'dashboard' && (
							<Link to='../../dashboard/projects'
								onClick={() => { setPlanMenuEl(null) }}
								className="px-10 text-15 font-semibold cursor-pointer"
							>{languageStrs.dashboard}</Link>
						)}
						<Link to='../../dashboard/profile'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer"
						>{languageStrs.profile}</Link>
						<Link to='../../dashboard/projects'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer"
						>{languageStrs.projects}</Link>

						<Link to='../../dashboard/templates'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer"
						>{languageStrs.templates}</Link>

						<Link to='../../dashboard/workspace	'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer"
						>{languageStrs.workspace}</Link>
						<Link to='../../dashboard/apikeys'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer"
						>{languageStrs.projects}</Link>
						<Link to='../../pricing'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer md:hidden"
						>{languageStrs.pricing}</Link>

						<Link to='../../signout'
							onClick={handleSignOut}
							className="px-10 text-15 font-semibold cursor-pointer"
						>{languageStrs.logout}</Link>
					</div>
				</div>
			</Menu>
		</>
	)
}