import { Box } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";

import './aigenerated.scss';
import { Header } from "../../component/home-header/header";
import { Footer } from "../../component/home-footer/footer";
import {AIGeneratedVideoSummary} from "./aigenerated-summary/aigenerated-summary"
import {AIGeneratedImage} from "./aigenerated-image/aigenerated-image"
import {AIGeneratedVideo} from "./aigenerated-video/aigenerated-video"
import {AIGeneratedVoice} from "./aigenerated-voice/aigenerated-voice";
export const AIGenerated = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
        <Routes>
          <Route path="/ai-summary-generator" element={<AIGeneratedVideoSummary />} />
          <Route path="/ai-video-generator" element={<AIGeneratedVideo />} />
          <Route path="/ai-image-generator" element={<AIGeneratedImage />} />
          <Route path="/ai-voice-generator" element={<AIGeneratedVoice />} />
          <Route path="/" element={<Navigate replace to="/ai-video-generator" />} />
        </Routes>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
  )
}